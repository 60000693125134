import { Component, EventEmitter } from "@angular/core";

@Component({
    selector:'p-notificator',
    templateUrl:'./notificator.component.html',
    styleUrls:['./notificator.component.scss']
})
export class NotificatorPartial{
    public stack:Notif[] = [];

    private static pusher = new EventEmitter<Notification>();

    constructor(){
        NotificatorPartial.pusher.subscribe(res => {
            let n:Notif = {
                gone: true,
                ...res
            }
            this.stack.push(n);
            setTimeout(() => { n.gone = false; }, 20);
            if(n.timeout){
                setTimeout(() => {
                    n.gone = true;
                    setTimeout(() => { this.stack.splice(this.stack.indexOf(n), 1); }, 400);
                }, res.timeout);
            }
        });
    }

    public dismiss(n:Notif){
        n.gone = true;
        setTimeout(() => { this.stack.splice(this.stack.indexOf(n), 1); }, 400);
    }

    public static push(notif:Notification){
        this.pusher.emit(notif);
    }
}

export interface Notification{
    type:'info'|'success'|'error'|'warning',
    message:string,
    details?:string,
    timeout?:number,
    dismissable?:boolean
}
interface Notif extends Notification{
    gone:boolean;
}