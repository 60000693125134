import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-heading1',
  templateUrl: './heading1.component.html',
  styleUrls: ['./heading1.component.scss']
})
export class Heading1Component implements OnInit {

  @Input() headingTitle: string = '';
  @Input() headingClass: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
