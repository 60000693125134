import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector:'c-form-wysiwyg',
    templateUrl:'./form-wysiwyg.component.html',
    styleUrls:['./form-wysiwyg.component.scss']
})
export class FormWYSIWYGComponent {
    @Input() public model:string = '';
    @Output() public modelChange = new EventEmitter<string>();

    constructor(){}
}