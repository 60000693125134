<div>
    <div class='controls'>
        <app-button *ngxPermissionsOnly="['full:posts', 'create:posts']" text="Create New" appOpenInNewTab="/posts/new"></app-button>
        <app-form-input [(model)]="query.filters!.search" (modelChange)="searcherInput()" placeholder="Search..."
            [isLabel]="false"></app-form-input>
    </div>
    <div class='table_wraper'>
        <c-table [columns]="columnDef" [data]="posts" [loading]="loading" [total]="filtered" [query]="query"
            (changed)="fetchData()"></c-table>
    </div>
</div>