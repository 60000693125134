import { Injectable } from '@angular/core';
import { EpEncryptionService } from './ep-encryption.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CodedResponseModel } from '../model/CodedResponseModel';
import { apiUrls } from '../settings/settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private userRole = '';

  private globalVariableSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public globalVariable$: Observable<any> =
    this.globalVariableSubject.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private epEncryptionService: EpEncryptionService,
    private permissionService: NgxPermissionsService
  ) {
    this.setUserRole();
  }

  setGlobalVariable(value: any): void {
    this.globalVariableSubject.next(value);
  }

  private setUserRole() {
    this.userRole = this.getUserRole();
    let self = this;
    this.setGlobalVariable(this.userRole);
    setTimeout(() => {
      self.setUserRole();
    }, 1000);
  }

  public login(email: string, password: string) {
    return this.http.post<CodedResponseModel>(
      apiUrls.apiUrl + 'login',
      this.epEncryptionService.sendPayload({
        email: email,
        password: password,
      })
    );
  }
  public register(email: string, password: string) {
    return this.http.post<CodedResponseModel>(
      apiUrls.apiUrl + 'register',
      this.epEncryptionService.sendPayload({
        email: email,
        password: password,
      })
    );
  }
  public logout() {
    localStorage.removeItem('token');
  }

  public refreshToken() {
    return this.http.get<CodedResponseModel>(apiUrls.apiUrl + 'refreshToken'); //.subscribe(
  }

  public isTokenExpired() {
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem('access_token');
    if (!token) return true;
    return jwtHelper.isTokenExpired(token);
  }

  public isLoggedIn() {
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem('access_token');
    if (!token) return false;
    let isExpired = jwtHelper.isTokenExpired(token);
    return !isExpired;
  }
  public userHasRole(role: string) {
    return this.userRole == role;
  }
  public getUserRole() {
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem('access_token');
    if (!token) return '';
    let allRole = jwtHelper.decodeToken(token).user.role;
    if (allRole[0]) return allRole[0].name;
    return 'Guest';
  }

  public getUserPermissions() {
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem('access_token');
    if (!token) return [];
    let role = jwtHelper.decodeToken(token).user.role;
    if (role)
      return (role[0]?.permissions ?? [{ name: 'blocked' }]).map(
        (item: any) => item.name
      );
  }

  public getCurrentUser() {
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem('access_token');
    if (!token) return '';
    let user = jwtHelper.decodeToken(token).user;
    return user;
  }

  public forgotPassword(email: string) {
    return this.http.post(
      `${apiUrls.apiUrl}forgotPassword`,
      this.epEncryptionService.sendPayload({ email: email })
    );
  }
  public resetPassword(token: string, password: string, passConfirm: string) {
    return this.http.post(
      `${apiUrls.apiUrl}resetPassword`,
      this.epEncryptionService.sendPayload({
        token: token,
        password: password,
        passConfirm: passConfirm,
      })
    );
  }
  public hasPermissions(permissions: string[]) {
    let checked = false;
    const _permissions = this.permissionService.getPermissions();
    permissions.forEach((permission) => {
      checked = checked || Boolean(_permissions?.[permission]);
      return true;
    });
    return checked;
  }
}
