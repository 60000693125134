<ng-template #recursiveTemplate let-data>
    <div>
        <div class='grid grid-cols-8 hover:bg-[#00000022]'>
            <div [style]="'margin-left:'+data.depth*40+'px;'"
                [ngClass]="{'font-bold':data.depth===0,'font-semibold':data.depth===1}"
                class="font-Inter text-base col-span-3">{{
                data.functionality }}
            </div>
            <div>
                <input type="checkbox" [(ngModel)]="permissionObj['Admin'][data.permission].checked"
                    (ngModelChange)="handleChangePermission($event,'Admin',data.permission)" />
            </div>
            <div>
                <input type="checkbox" [(ngModel)]="permissionObj['Accounting'][data.permission].checked"
                    (ngModelChange)="handleChangePermission($event,'Accounting',data.permission)" />
            </div>
            <div>
                <input type="checkbox" [(ngModel)]="permissionObj['Operations'][data.permission].checked"
                    (ngModelChange)="handleChangePermission($event,'Operations',data.permission)" />
            </div>
            <div>
                <input type="checkbox" [(ngModel)]="permissionObj['Compliance'][data.permission].checked"
                    (ngModelChange)="handleChangePermission($event,'Compliance',data.permission)" />
            </div>
            <div>
                <input type="checkbox" [(ngModel)]="permissionObj['Command'][data.permission].checked"
                    (ngModelChange)="handleChangePermission($event,'Command',data.permission)" />
            </div>
        </div>
        <div *ngFor="let child of data.children">
            <ng-container *ngTemplateOutlet="recursiveTemplate; context: { $implicit: child }"></ng-container>
        </div>
    </div>
</ng-template>

<div class='w-full flex justify-center p-5'>
    <div *ngIf="!loading" class='box-border flex flex-col justify-center w-[1100px] max-w-[1100px]'>
        <div>
            <div class='grid grid-cols-8'>
                <div class="font-Inter text-xl font-bold text-center col-span-3">
                    Web Application Functionality
                </div>
                <div>
                    Admin
                </div>
                <div>
                    Accounting
                </div>
                <div>
                    Operations
                </div>
                <div>
                    Compliance
                </div>
                <div>
                    Command
                </div>
            </div>
        </div>
        <ng-container *ngFor="let obj of WEB_APPLICATION_FUNCTIONALITY">
            <ng-container *ngTemplateOutlet="recursiveTemplate; context: { $implicit: obj }"></ng-container>
        </ng-container>
        <div class='w-full flex justify-end items-center'>
            <div class='w-[195px] flex items-center gap-2 mt-2'>
                <button class='custom-blue-solid-btn' (click)="store()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
<app-loader-v2 [loading]="loading"></app-loader-v2>