<div class="link_btn_wrap" [class.active]="active">
    <ng-container *ngIf="!isLink">
        <button type="button" [ngClass]="classes" [class.red]="closeBtn" (click)="slideToggle()">
            <svg-icon [src]="icon" [class.stroke]="iconStroke" *ngIf="!closeBtn && iconPosition === 'left' && isIcon"></svg-icon>
            <span class="text">{{ text }}</span>
            <span class="arrow" *ngIf="isDropdown && isArrow"></span>
            <svg-icon class="close_icon" [src]="icon" *ngIf="closeBtn"></svg-icon>
            <svg-icon class="right" [class.stroke]="iconStroke" [src]="icon" *ngIf="!closeBtn && iconPosition === 'right' && isIcon"></svg-icon>
        </button>
    </ng-container>
    <ng-container *ngIf="isLink">
        <a [appOpenInNewTab]="link" [ngClass]="classes" [class.red]="closeBtn" (click)="slideToggle()">
            <svg-icon [src]="icon" [class.stroke]="iconStroke" *ngIf="!closeBtn && iconPosition === 'left'"></svg-icon>
            <span class="text">{{ text }}</span>
            <span class="arrow" *ngIf="isDropdown && isArrow"></span>
            <svg-icon class="close_icon" [src]="icon" *ngIf="closeBtn"></svg-icon>
            <svg-icon class="right" [class.stroke]="iconStroke" [src]="icon" *ngIf="!closeBtn && iconPosition === 'right'"></svg-icon>
        </a>
    </ng-container>
    <div class="select_list" *ngIf="isDropdown && list.length" [ngClass]="dropDownPosition" [@expandCollapse]="active ? 'expanded' : 'collapsed'">
        <ul>
            <li *ngFor="let item of list;" (click)="selectItem(item)">
                {{ item?.name }}
            </li>
        </ul>
    </div>
</div>
