import { Component, EventEmitter } from "@angular/core";

@Component({
    selector:'app-loader',
    templateUrl:'./loader.component.html',
    styleUrls:['./loader.component.scss']
})
export class LoaderPartial{
    public exist:boolean = false;
    public display:boolean = false;

    private static emitter = new EventEmitter<boolean>();

    constructor(){
        LoaderPartial.emitter.subscribe(res => {
            if(res && !this.display){
                this.exist = true;
                this.display = true;
            } else if(!res && this.display){
                this.display = false;
                setTimeout(() => { this.exist = false; }, 200);
            }
        })
    }

    public static show(v:boolean){
        this.emitter.emit(v);
    }
}