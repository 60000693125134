import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from 'src/app/core/table/table.component';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { MappingDataType } from 'src/app/model/custm/Setting';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { SettingsAPI } from 'src/app/services/custm/settings.service';

@Component({
  selector: 'app-edit-departments',
  templateUrl: './edit-departments.component.html',
  styleUrls: ['./edit-departments.component.scss'],
})
export class EditDepartmentsComponent implements OnInit {
  public loading: boolean = false;

  public departments: MappingDataType[] = [];

  public searchCooldown: any;
  public modalStatus: string = 'NONE';
  public currentValue: string = '';
  public selected: MappingDataType = { id: 0, name: '' };

  public columnDef: TableColumn[] = [
    { label: 'ID', slug: 'id', sortable: false, filterType: 'number' },
    {
      label: 'Department',
      slug: 'name',
      sortable: false,
      accessor: (i: MappingDataType) => i.name,
    },
    {
      label: 'Actions',
      slug: 'actions',
      dataType: 'actions',
      actions: [
        {
          label: 'Edit',
          action: (i: MappingDataType) => {
            this.currentValue = i.name;
            this.selected = i;
            this.modalStatus = 'EDIT_FORM';
          },
        },
        { label: 'Delete', action: (i: MappingDataType) => this.del(i) },
      ],
    },
  ];

  public query: IndexQuery = {
    page: 1,
    perPage: 15,
    filters: {
      search: '',
    },
  };

  public total: number = 0;
  public filtered: number = 0;

  constructor(private settingsApi: SettingsAPI, private dialog: MatDialog) {
    this.fetchData();
  }

  ngOnInit(): void {}

  public fetchData() {
    this.loading = true;
    this.settingsApi.getDepartments(this.query).subscribe((r) => {
      let res = CodedResponseModel.decode(r);

      this.departments = res.data;

      this.filtered = res.filtered;
      this.total = res.total;

      this.loading = false;
    });
  }

  public del(u: MappingDataType) {
    let d = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Are you sure you want to delete the department '${u.name}'?`,
        button1: 'Delete',
        button2: 'Cancel',
      },
    });
    d.afterClosed().subscribe((c) => {
      if (c) {
        this.settingsApi.delete(u.id, 'departments').subscribe(
          (r) => {
            NotificatorPartial.push({
              type: 'info',
              message: `${u.name} has been deleted`,
              timeout: 3000,
            });
            this.fetchData();
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: 'An error has occurred',
              details: err.error.message,
              timeout: 3000,
            });
          }
        );
      }
    });
  }

  public searcherInput() {
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.query.page = 1;
      this.fetchData();
    }, 500);
  }

  public openCreateForm() {
    this.currentValue = '';
    this.modalStatus = 'CREATE_FORM';
  }
  public store() {
    if (!this.currentValue || !this.currentValue.trim().length)
      return NotificatorPartial.push({
        type: 'error',
        message: 'Department Name is mandatory',
        timeout: 3000,
      });
    this.loading = true;
    if (this.modalStatus === 'CREATE_FORM') {
      this.settingsApi
        .store({ name: this.currentValue }, 'departments')
        .subscribe(
          (res) => {
            this.loading = false;
            this.fetchData();
            this.modalStatus = 'NONE';
            NotificatorPartial.push({
              type: 'success',
              message: `${this.currentValue} has been created`,
              timeout: 3000,
            });
          },
          (err) => {
            this.loading = false;
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
              timeout: 3000,
            });
          }
        );
    } else {
      this.settingsApi
        .edit({ name: this.currentValue }, this.selected.id, 'departments')
        .subscribe(
          (res) => {
            this.loading = false;
            this.modalStatus = 'NONE';
            this.fetchData();
            NotificatorPartial.push({
              type: 'success',
              message: `${this.selected.name} has been updated`,
              timeout: 3000,
            });
          },
          (err) => {
            this.loading = false;
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
              timeout: 3000,
            });
          }
        );
    }
  }
}
