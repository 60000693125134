import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {EpEncryptionService} from "../ep-encryption.service";
import {ShiftState} from "../../model/custm/ShiftStatus";
import {CodedResponseModel} from "../../model/CodedResponseModel";
import {apiUrls} from "../../settings/settings";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ShiftStatusesAPI {
    constructor(
        private http: HttpClient,
        private crypto: EpEncryptionService
    ) {}

    public index(offset: number, status?: ShiftState): Observable<CodedResponseModel>{
        return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}statuses?offset=${offset}${status!=undefined ? `&status=${status}` : ''}`);
    }
}