import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-heading2',
  templateUrl: './heading2.component.html',
  styleUrls: ['./heading2.component.scss']
})
export class Heading2Component implements OnInit {

  @Input() headingTitle: string = '';
  @Input() headingClass: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
