import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { buildParams, IndexQuery } from "src/app/model/IndexQuery";
import { apiUrls } from "src/app/settings/settings";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root',
})
export class LogsAPI {
    constructor(
        private http:HttpClient,
        private crypto:EpEncryptionService,
    ){}

    public index(query:IndexQuery){
        return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}checklists${buildParams(query)}`);
    }
    public delete(id:number){
        return this.http.delete<CodedResponseModel>(`${apiUrls.apiUrl}checklists/${id}`);
    }

    public resolveIncident(id:number, cancel:boolean){
        return this.http.post<CodedResponseModel>(`${apiUrls.apiUrl}checklists/${id}/resolve`, this.crypto.sendPayload({ cancel: cancel }))
    }
}