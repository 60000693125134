<div class='table-container scroll-thin-style h-full overflow-scroll '>
    <table>
        <thead>
            <th *ngFor="let col of columns; let i = index" [ngSwitch]="col.colType">
                <ng-container *ngSwitchCase="'checkbox'">
                    <input [ngClass]="{'non-touchable':col.touchable&&!col.touchable()}" type="checkbox"
                        [(ngModel)]="globalCheck" (ngModelChange)="handleGlobalCheck()">
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span>{{col.label}}</span>
                </ng-container>
            </th>
        </thead>
        <tbody>
            <tr *ngFor="let row of data;
            let i = index">
                <td *ngFor="let col of columns;
                let j = index" [ngSwitch]="col.colType" aria-disabled='true'>

                    <ng-container *ngSwitchCase="'checkbox'">
                        <input [ngClass]="{'non-touchable':col.touchable&&!col.touchable()}" type="checkbox"
                            [(ngModel)]="row.checked">
                    </ng-container>

                    <ng-container *ngSwitchCase="'_input'">
                        <input [ngClass]="{'non-touchable':col.touchable&&!col.touchable()}" [class]='col.customClass||""' currencyMask [options]="col.options"
                            [(ngModel)]="row[col.slug]" />
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <span>{{cellData(row,col)}}</span>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>