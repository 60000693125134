import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EpEncryptionService } from '../ep-encryption.service';
import { IndexQuery, buildParams } from 'src/app/model/IndexQuery';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { apiUrls } from 'src/app/settings/settings';
import { Client } from 'src/app/model/custm/Client';

@Injectable({
  providedIn: 'root',
})
export class ClientAPIService {
  constructor(
    private http: HttpClient,
    private epEncryptionService: EpEncryptionService
  ) {}

  public index(query: IndexQuery): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}client${buildParams(query)}`
    );
  }

  public show(id: number): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}client/${id}`);
  }

  public edit(data: any, id: number): Observable<CodedResponseModel> {
    const headers = new HttpHeaders();
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}client/${id}`,
      data,
      { headers: headers}
    );
  }
  public delete(id: number): Observable<CodedResponseModel> {
    return this.http.delete<CodedResponseModel>(
      `${apiUrls.apiUrl}client/${id}`
    );
  }
  public create(data: Client): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}client`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  public getStates(department?: number) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}clients/state?department=${department}`
    );
  }

  public getDepartments() {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}clients/departments`
    );
  }

  public getVehicles(state?: number, department?: number) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}clients/vehicles?state=${state}&department=${department}`
    );
  }
}
