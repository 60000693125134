<div>
    <div class='controls'>
        <app-button text="Create New" (buttonClickEvent)="openCreateForm()"></app-button>
        <app-form-input [(model)]="query.filters!.search" (modelChange)="searcherInput()" placeholder="Search..."
            [isLabel]="false"></app-form-input>
    </div>
    <div class='table_wraper'>
        <c-table [columns]="columnDef" [data]="roles" [query]="query" [total]="filtered"
            (changed)="fetchData()"></c-table>
    </div>
</div>
<app-modal-body *ngIf="modalStatus!=='NONE'">
    <ng-container [ngTemplateOutlet]="create_form"
        *ngIf="modalStatus==='CREATE_FORM'||modalStatus==='EDIT_FORM'"></ng-container>
</app-modal-body>
<app-loader-v2 [loading]="loading"></app-loader-v2>

<ng-template #create_form>
    <div class='w-[400px] flex flex-col items-center box-border relative gap-y-8'>
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="modalStatus='NONE'">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='w-full flex flex-col gap-y-4'>
            <span class='text-base font-bold font-Inter'>
                {{modalStatus==='CREATE_FORM'?
                'Create New Role':'Edit '+selected.name
                }}
            </span>
            <app-form-input [(model)]="currentValue" placeholder="" [isLabel]="false"></app-form-input>
        </div>
        <div class='w-full flex items-center gap-2'>
            <button class='custom-blue-solid-btn' (click)="store()">
                Save
            </button>
            <button class='custom-outlined-btn' (click)="modalStatus='NONE'">Cancel</button>
        </div>
    </div>
</ng-template>