import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { buildParams, IndexQuery } from 'src/app/model/IndexQuery';
import { apiUrls } from 'src/app/settings/settings';
import { EpEncryptionService } from '../ep-encryption.service';

interface SummaryExportQuery {
  departments: string[];
  dateFrom: string;
  dateTo: string;
}

@Injectable({
  providedIn: 'root',
})
export class SummaryAPI {
  constructor(private http: HttpClient, private crypto: EpEncryptionService) {}

  public index(query: IndexQuery) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}summaries${buildParams(query)}`
    );
  }
  public clients() {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}summaries/clients`
    );
  }

  public getLocationsAndDepartments() {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}summaries/location-department`
    );
  }

  public getSummaries(query: IndexQuery) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}summaries${buildParams(query)}`
    );
  }

  public getSummariesData(
    query: IndexQuery,
    startDate: string,
    endDate: string,
    state: string[],
    department: string[],
    orderArr: string[],
    toExport = false
  ) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}summaries${buildParams(query)}`,
      this.crypto.sendPayload({
        startDate,
        endDate,
        state,
        department,
        orderArr,
        toExport,
      })
    );
  }

  public getExportData(data: SummaryExportQuery) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}summaries/export-data`,
      this.crypto.sendPayload(data)
    );
  }

  public export(query: SummaryExportQuery) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}summaries/export`,
      this.crypto.sendPayload(query)
    );
  }
}
