<!-- <div>
    <input name="email" type="email" [(ngModel)] = "email">
    <input type="password" name="password" [(ngModel)] = "password">
    <button (click)="login()">LOGIN</button>
</div>

<a routerLink="/forgotPassword">Forgot password?</a> -->
<div class='login-container'>
    <div class='form'>
        <app-heading1 headingTitle="Log in"></app-heading1>
        <app-form-input [(model)]="email" labelText="Email"></app-form-input>
        <app-form-input [(model)]="password" type="password" labelText="Password"></app-form-input>
        <div class='err-msg' *ngIf="errMsg">{{errMsg}}</div>
        <div class='text-xs font-Inter font-bold italic flex justify-center items-center '>
            <a routerLink="/forgotPassword">Forgot password?</a>
        </div>
        <app-button text="Login" (buttonClickEvent)="login()"></app-button>
    </div>
</div>