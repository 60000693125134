<div class="cnt">
    <c-select [multi]="true" [options]="departments" placeholder="Any" label="Departments" [loading]="clientsLoading"
        [(model)]="selectedDepartments"></c-select>
    <app-form-input labelText="From" type="datetime-local" [(model)]="dateFrom"></app-form-input>
    <app-form-input labelText="To" type="datetime-local" [(model)]="dateTo"></app-form-input>
    <div class='nodata' *ngIf="isNodata">*There does not exist data for selected range.</div>
    <div class='nodata' *ngIf="dateRangeInvalid">*Date range selection is incorrect.</div>
    <div class='modal-buttons'>
        <app-button text="Export to CSV" (buttonClickEvent)="submit()"></app-button>
        <app-button text="Close" (buttonClickEvent)="closeDialog()"></app-button>
    </div>
</div>
<div class='loading_background' *ngIf="loading">
    <div class='loading_wrap'>
        <div class='loading_loader'>
            <img src="/assets/icons/loading.png" alt="loading-icon" />
        </div>
        <span>Loading</span>
    </div>
</div>