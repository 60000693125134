import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from 'src/app/core/table/table.component';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { JsonDataComponent } from 'src/app/modals/json-data/json-data.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import {
  Checklist,
  ChecklistResponse,
  ChecklistType,
} from 'src/app/model/custm/Checklist';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { LoaderPartial } from 'src/app/partials/loader/loader.component';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { LogsAPI } from 'src/app/services/custm/logs.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit {
  public loading: boolean = false;
  public logs: Checklist[] = [];

  public searchCooldown: any;

  public columnDef: TableColumn[] = [
    { label: 'ID', slug: 'id', sortable: false, filterType: 'number' },
    {
      label: 'User',
      slug: 'user',
      sortable: false,
      accessor: (i: Checklist) => i.user?.fullName,
    },
    {
      label: 'Event',
      slug: 'type',
      sortable: false,
      accessor: (i: Checklist) => i.event,
    },
    {
      label: 'Donesafe IDs',
      slug: 'category',
      sortable: false,
      accessor: (i: Checklist) => `${i.moduleId ?? '-'}, ${i.subformId ?? '-'}`,
    },
    {
      label: 'Timestamp',
      slug: 'day',
      sortable: false,
      accessor: (i: Checklist) =>
        this.datePipe.transform(i.createdAt, 'dd MMMM yyyy HH:mm'),
    },
    {
      label: 'Data',
      slug: 'data',
      sortable: false,
      dataType: 'actions',
      actions: [
        {
          label: 'Show Data',
          action: (i: Checklist) => {
            this.dialog.open(JsonDataComponent, { data: i.data });
          },
          condition: (i: Checklist) =>
            i.data &&
            this.authService.hasPermissions(['full:logs', 'view:logs']),
        },
        {
          label: 'Revert',
          action: (i: Checklist) => {
            this.revert(i);
          },
          condition: (i: Checklist) =>
            [ChecklistType.Cancelled, ChecklistType.Begun].includes(i.type) &&
            (this.authService.hasPermissions(['full:logs', 'revert:logs']) ||
              (this.authService.hasPermissions(['revert:begun']) &&
                i.type === ChecklistType.Begun) ||
              (this.authService.hasPermissions(['revert:cancelled']) &&
                i.type === ChecklistType.Cancelled)),
        },
        {
          label: 'Cancel Shift',
          action: (i: Checklist) => {
            this.resolveIncident(i.id, true);
          },
          condition: (i: Checklist) => {
            let satisfied = i.type === ChecklistType.Incident;
            if (satisfied) {
              satisfied = satisfied && this.checkPermission(i.data.type);
            }
            return satisfied;
          },
        },
        {
          label: 'Clear Incident',
          action: (i: Checklist) => {
            this.resolveIncident(i.id, false);
          },
          condition: (i: Checklist) => {
            let satisfied = i.type === ChecklistType.Incident;
            if (satisfied) {
              satisfied = satisfied && this.checkPermission(i.data.type);
            }
            return satisfied;
          },
        },
      ],
    },
  ];

  public query: IndexQuery = {
    page: 1,
    perPage: 15,
    sortBy: 'created_at',
    sortDir: 'desc',
    filters: {
      search: '',
    },
  };

  public total: number = 0;
  public filtered: number = 0;

  constructor(
    private logsApi: LogsAPI,
    private datePipe: DatePipe,
    private authService: LoginService,
    private dialog: MatDialog
  ) {
    this.fetchData();
  }

  ngOnInit(): void {}

  public fetchData() {
    this.loading = true;
    this.logsApi.index(this.query).subscribe((r) => {
      let res = CodedResponseModel.decode(r);

      this.logs = res.data.map((l: ChecklistResponse) => Checklist.create(l));

      this.filtered = res.filtered;
      this.total = res.total;

      this.loading = false;
    });
  }

  public searcherInput() {
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.fetchData();
    }, 500);
  }

  public revert(check: Checklist) {
    let d = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: 'Are you sure you want to revert this event?',
      },
    });
    d.afterClosed().subscribe((r) => {
      if (r) {
        this.logsApi.delete(check.id).subscribe(
          (r) => {
            NotificatorPartial.push({
              message: 'Event reverted',
              type: 'success',
              timeout: 3000,
            });
            this.fetchData();
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
            });
          }
        );
      }
    });
  }

  public resolveIncident(id: number, cancel: boolean) {
    let d = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Doing this will ${
          cancel
            ? "cancel the user's shift for the rest of the day"
            : 'allow the user to continue with their shift'
        }. Are you sure?`,
      },
    });
    d.afterClosed().subscribe((r) => {
      if (r) {
        this.logsApi.resolveIncident(id, cancel).subscribe(
          (r) => {
            NotificatorPartial.push({
              type: 'success',
              message: cancel ? 'Shift cancelled' : 'Incident cleared',
              timeout: 3000,
            });
            this.fetchData();
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
            });
          }
        );
      }
    });
  }

  public checkPermission(type: string) {
    switch (type) {
      case 'injury':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-injury:logs',
        ]);
      case 'nearmiss':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-near-miss:logs',
        ]);
      case 'propertydamage':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-property-damage:logs',
        ]);
      case 'Non conformance ':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-non-conformance:logs',
        ]);
      case 'Environmental ':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-environmental:logs',
        ]);
      case 'Critical Impact ':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-critical-impact:logs',
        ]);
      case 'Workplace Hazard ':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-workplace-hazard:logs',
        ]);
      case 'Reporting Property Damage ':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-reporting-property-damage:logs',
        ]);
      case 'Hazardous Practice ':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-hazardous-practice:logs',
        ]);
      case 'Incident ':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-other-incident:logs',
        ]);
      case 'theft':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-theft:logs',
        ]);
      case 'fraudulent activity':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'incident-fraudulent-activity:logs',
        ]);
      case 'Opportunity for Improvement ':
        return this.authService.hasPermissions([
          'full:logs',
          'incident:logs',
          'opportunity-for-improvement:logs',
        ]);
      default:
        return this.authService.hasPermissions(['full:logs', 'incident:logs']);
    }
  }
}
