import { User, user_interface } from '../User';

export enum PostCategory {
  Updates,
  Tips,
  Links,
}

export interface MessagePostResponse {
  id: number;
  user_id: number;
  title: string;
  content: string;
  category: PostCategory;
  created_at: string;
  user?: Partial<user_interface>;
}

export class MessagePost {
  public id: number = 0;
  public userId: number = 0;
  public title: string = '';
  public content: string = '';
  public category: PostCategory = PostCategory.Updates;
  public createdAt: Date = new Date();

  public user?: User;

  public map(data: MessagePostResponse) {
    this.id = data.id;
    this.userId = data.user_id;
    this.title = data.title;
    this.content = data.content;
    this.category = data.category;
    this.createdAt = new Date(data.created_at);

    this.user = data.user ? User.mapUser(data.user) : undefined;

    return this;
  }

  public responsify(): MessagePostResponse {
    return {
      id: this.id,
      user_id: this.userId,
      title: this.title,
      content: this.content,
      category: this.category,
      created_at: this.createdAt.toISOString(),

      user: this.user?.toResponse(),
    };
  }

  public static create(data: MessagePostResponse) {
    let r = new this();
    return r.map(data);
  }

  public get excerpt() {
    return (
      this.content.replace(/<[^>]*>/g, '').substring(0, 100) +
      (this.content.length > 100 ? '...' : '')
    );
  }

  public get postCategory() {
    switch (this.category) {
      case PostCategory.Links:
        return 'Links';
      case PostCategory.Tips:
        return 'Tips';
      case PostCategory.Updates:
        return 'Updates';
    }
  }
}
