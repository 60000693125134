<div>
    <div class='controls'>
        <app-button *ngxPermissionsOnly="['full:clients', 'create:clients']" text="Create New"
            appOpenInNewTab="/clients/new"></app-button>
        <app-form-input [(model)]="query.filters!.search" (modelChange)="searcherInput()" placeholder="Search..."
            [isLabel]="false"></app-form-input>
    </div>
    <div class='table_wraper'>
        
        <c-table [columns]="columnDef" [data]="clients" [loading]="loading" [query]="query" [total]="filtered"
            (changed)="fetchData()"></c-table>
    </div>
</div>