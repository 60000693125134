<div class='form-container'>
    <app-heading1 [headingTitle]="editMode?'Edit Post':'Add Post'"></app-heading1>

    <app-form-input [isDisabled]="true" *ngIf="editMode" labelText="Post ID" [model]="post.id"></app-form-input>
    <app-form-input [(model)]="post.title" labelText="Post Title"></app-form-input>

    <c-form-wysiwyg [(model)]="post.content"></c-form-wysiwyg>

    <c-select [options]="categories" label="Category" [(model)]="post.category"></c-select>

    <app-button (buttonClickEvent)="save()" text="Save"></app-button>
    <app-link-button routerLink="/posts" [isIcon]="false" text="Back"></app-link-button>
</div>