import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CommentsType,
  SelectedItemType,
  ShiftSummaryType,
} from 'src/app/model/custm/Timesheet';

@Component({
  selector: 'app-popupv2',
  templateUrl: './popupv2.component.html',
  styleUrls: ['./popupv2.component.scss'],
})
export class Popupv2Component implements OnInit {
  @Input() currentPopup: string = '';
  @Input() popupdata!: ShiftSummaryType['data'] &
    CommentsType &
    SelectedItemType[];

  @Input() validator: CallableFunction | undefined;
  @Output() popupdataChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public CancelUpdates() {
    this.popupdataChange.emit(false);
  }
  public ReviewedComments() {
    this.popupdataChange.emit('Reviewed');
  }
  public SaveUpdates() {
    if (this.validator) if (!this.validator(this.popupdata)) return;

    this.popupdataChange.emit(this.popupdata);
  }
  public handleClick(i: number) {
    if (this.popupdata[i].isSelected) return;
    this.popupdataChange.emit(this.popupdata[i].item);
  }
  public handleDelete() {
    this.popupdataChange.emit('Delete');
  }
}
