import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SelectOption } from 'src/app/core/select/select.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { DepartmentType } from 'src/app/model/custm/ProofOfDelivery';
import { SummaryAPI } from 'src/app/services/custm/summaries.service';
import { MatDialogRef } from '@angular/material/dialog';
import { apiUrls } from 'src/app/settings/settings';
import { exportAsExcel } from 'src/app/export-excel/Export2Excel';
import { ShiftSummary } from 'src/app/model/custm/ShiftSummary';

@Component({
  selector: 'app-summary-export',
  templateUrl: './summary-export.component.html',
  styleUrls: ['./summary-export.component.scss'],
})
export class SummaryExportComponent implements OnInit {
  @Output() response: EventEmitter<any> = new EventEmitter();
  public departments: SelectOption[] = [];
  public clientsLoading: boolean = true;
  public departmentData: DepartmentType[] = [];

  public selectedDepartments: string[] = [];
  public dateFrom: string = '';
  public dateTo: string = '';
  public loading: boolean = false;
  public isNodata: boolean = false;
  public dateRangeInvalid: boolean = false;

  public defaultColumnWidths = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 35 },
    { wch: 15 },
    { wch: 35 },
    { wch: 35 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 35 },
  ];

  constructor(
    private summaryApi: SummaryAPI,
    private cdref: ChangeDetectorRef,
    private dialogRef: MatDialogRef<SummaryExportComponent>
  ) {
    this.summaryApi.getLocationsAndDepartments().subscribe((res: any) => {
      const { data } = res;
      const departments = (data?.departments ?? []) as DepartmentType[];

      const sortedDepartments = _.sortBy(departments, (obj) =>
        obj.client.toLowerCase()
      );

      this.departments = sortedDepartments.map((item) => ({
        label: `${item?.client ?? ''} - ${item?.depot ?? ''}`,
        value: `${(item?.client ?? '').toLowerCase()}_${(
          item?.depot ?? ''
        ).toLowerCase()}`,
      }));

      this.clientsLoading = false;
      this.cdref.detectChanges();
    });
  }

  ngOnInit(): void {}

  public submit() {
    if (!this.dateFrom || !this.dateTo) {
      this.dateRangeInvalid = true;
      return;
    }
    const dateFrom = new Date(this.dateFrom).getTime();
    const dateTo = new Date(this.dateTo).getTime();

    if (dateTo < dateFrom) {
      this.dateRangeInvalid = true;
      return;
    }
    this.loading = true;
    this.isNodata = false;
    this.dateRangeInvalid = false;

    const utcFromDate = moment(this.dateFrom)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const utcToDate = moment(this.dateTo).utc().format('YYYY-MM-DD HH:mm:ss');

    const departments = this.departments
      .filter((item) => this.selectedDepartments.includes(item.value))
      .map((item) => item.label);
    this.summaryApi
      .getExportData({
        departments,
        dateFrom: String(utcFromDate),
        dateTo: String(utcToDate),
      })
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);
        const exportData = [
          { v: 'Date' },
          { v: 'Driver' },
          { v: 'Location' },
          { v: 'Department' },
          { v: 'Role' },
          { v: 'Clock In Time' },
          { v: 'Clock Out Time' },
          { v: 'Stops Loaded' },
          { v: 'Pick Ups' },
          { v: 'Cards Left' },
          { v: 'Check Address' },
          { v: 'Rejections' },
          { v: 'Skipped' },
          { v: 'Attempted Deliveries' },
          { v: 'Summary Photo' },
        ];
        const data = res.data.map((s: any) => ShiftSummary.create(s));
        this.loading = false;
        if (!((data?.length ?? 0) > 0)) {
          this.isNodata = true;
          return true;
        }
        const tempData = data.map((item: ShiftSummary) => [
          {
            v: String(item?.createdAt ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(`${item.user?.name ?? ''} ${item.user?.lname ?? ''}`),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.location ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.department ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.role ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.clockIn ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.clockOut ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.data?.stops_loaded ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.pickUp ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.data?.cards_left ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.data?.check_address ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.data?.rejections ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.data?.skipped ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.attemptedDeliveries),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item?.shiftPhoto ?? ''),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
        ]);
        // Export data to xls file.
        exportAsExcel(
          [exportData, ...tempData],
          this.defaultColumnWidths,
          'Shift Summaries'
        );
        this.closeDialog();
        return;
        // let a = document.createElement('a');
        // a.href = `${apiUrls.tmpUrl}${res}`;
        // a.click();

        // console.log(`${apiUrls.tmpUrl}${res}`);
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
