import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

export interface SelectOption {
  label: string;
  value: any;
}

@Component({
  selector: 'c-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  animations: [
    trigger('open', [
      state(
        'true',
        style({
          height: '*',
        })
      ),
      state(
        'false',
        style({
          height: '0',
        })
      ),
      transition('true <=> false', [animate('400ms 0ms ease')]),
    ]),
  ],
})
export class SelectComponent implements OnChanges {
  @Input() public label: string = '';
  @Input() public options: SelectOption[] = [];
  @Input() public selected?: SelectOption | SelectOption[];
  @Input() public multi: boolean = false;
  @Input() public model: any;
  @Input() public placeholder: string = '';
  @Input() public loading: boolean = false;
  @Output() public modelChange = new EventEmitter<any>();

  public showOptions: boolean = false;
  public isOpen: boolean = false;

  public sId: string = '';

  private clickSub?: Subscription;

  constructor() {
    this.sId = (Math.random() + 1).toString(36).substring(7);
  }

  ngOnChanges() {
    if (this.model !== undefined) {
      if (this.multi) {
        this.selected = this.options.filter((o) =>
          this.model.includes(o.value)
        );
      } else {
        for (let o of this.options)
          if (o.value == this.model) this.selected = o;
      }
    }
  }

  ngOnInit() {
    if (this.multi) this.selected = [];
  }

  private open() {
    this.showOptions = true;
    setTimeout(() => {
      this.isOpen = true;

      let selector = document.getElementById(`sel-${this.sId}`),
        options = document.getElementById(`opt-${this.sId}`);
      options!.style.width = `${selector?.getBoundingClientRect().width}px`;
    }, 20);

    this.clickSub = fromEvent(window, 'click').subscribe((e: any) => {
    //   if ((e?.path?.length ?? 0) > 0)
    //     for (let i of e.path)
    //       console.log("🤮🤧😱🤣👌🤞 ~ file: select.component.ts:96 ~ SelectComponent ~ this.clickSub=fromEvent ~ e.path:", e.path)
    //       if ([`sel-${this.sId}`, `opt-${this.sId}`].includes(i.id)) return;
    //   this.close();
    });
  }
  private close() {
    this.isOpen = false;
    setTimeout(() => {
      this.showOptions = false;
    }, 400);
    this.clickSub?.unsubscribe();
  }
  public toggleOpen() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }
  public selectOption(option: SelectOption) {
    if (this.multi) {
      let has = (<SelectOption[]>this.selected).indexOf(option);
      console.log(has);
      if (has != -1) (<SelectOption[]>this.selected).splice(has, 1);
      else (<SelectOption[]>this.selected).push(option);

      this.model = (<SelectOption[]>this.selected).map((o) => o.value);
    } else {
      this.selected = option;
      this.close();
      this.model = option.value;
    }
    this.modelChange.emit(this.model);
  }

  public get selLabel() {
    if (this.multi)
      return (
        (this.selected as SelectOption[])?.map((o) => o.label)?.join(', ') ||
        this.placeholder
      );
    else return (this.selected as SelectOption)?.label ?? this.placeholder;
  }
}
