import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-overlay',
  templateUrl: './custom-overlay.component.html',
  styleUrls: ['./custom-overlay.component.scss'],
})
export class CustomOverlayComponent implements OnInit {
  @Input() public isVisible: boolean = false;
  @Input() public selected: number = -1;
  @Output() response: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleClick() {
    this.isVisible = false;
    this.response.emit(this.selected);
  }
}
