export interface IndexQuery{
    sortBy?:string,
    sortDir?:'asc'|'desc',
    page?:number,
    perPage?:number,
    filters?:{ [key:string]:string },
}

export const buildParams = (query:IndexQuery) => {
    let params = [];
    if(query.page) params.push(`page=${query.page}`);
    if(query.perPage) params.push(`perPage=${query.perPage}`);
    if(query.sortBy) params.push(`sortBy=${query.sortBy}`);
    if(query.sortDir) params.push(`sortDir=${query.sortDir}`);
    for(let f in query.filters)
        params.push(`${f}=${query.filters[f]}`);

    return `?${params.join('&')}`;
}