<div class='form-container'>
    <app-heading1 [headingTitle]="editMode?'Edit Depot':'Add Depot'"></app-heading1>

    <app-form-input [isDisabled]="true" *ngIf="editMode" labelText="Depot ID" [model]="depot.id"></app-form-input>
    <app-form-input [(model)]="depot.name" labelText="Depot Name"></app-form-input>
    <app-form-input [(model)]="depot.street" labelText="Street Address"></app-form-input>
    <app-form-input [(model)]="depot.suburb" labelText="Suburb"></app-form-input>
    <app-form-input [(model)]="depot.lat" type="number" labelText="Latitude"></app-form-input>
    <app-form-input [(model)]="depot.lng" type="number" labelText="Longitude"></app-form-input>

    <app-button (buttonClickEvent)="save()" text="Save"></app-button>
    <app-link-button routerLink="/depots" [isIcon]="false" text="Back"></app-link-button>
</div>