import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { TableColumnV2 } from 'src/app/core/table-v2/table-v2.component';
import { TableColumn } from 'src/app/core/table/table.component';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { IndexQuery } from 'src/app/model/IndexQuery';
import {
  CheckedStatusType,
  LDRType,
  LoadingRatesType,
  MappingDataType,
  PayratesType,
  loading_charges,
} from 'src/app/model/custm/Setting';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { SettingsAPI } from 'src/app/services/custm/settings.service';

@Component({
  selector: 'app-edit-locations',
  templateUrl: './edit-locations.component.html',
  styleUrls: ['./edit-locations.component.scss'],
})
export class EditLocationsComponent implements OnInit {
  public loading: boolean = false;

  public locations: MappingDataType[] = [];
  public departments: (MappingDataType & CheckedStatusType)[] = [];
  public roles: (MappingDataType & CheckedStatusType)[] = [];
  public _roles: (MappingDataType & CheckedStatusType)[] = [];

  public mappings: LDRType[] = [];
  public mappingsObject: { [key: number]: { [key: number]: boolean } } = {};

  public payrates: PayratesType[] = [];
  public _payrates: PayratesType[] = [];

  public loading_rates: LoadingRatesType[] = [];

  public searchCooldown: any;
  public modalStatus: string = 'NONE';

  public locationForm!: FormGroup;
  public currentValue: string = '';
  public additionalFormTitle: 'Department' | 'Role' = 'Department';

  public expandStatus: { step2: boolean; step3: boolean } = {
    step2: false,
    step3: false,
  };

  public selected: MappingDataType = {
    id: 0,
    name: '',
    departments: [],
    vehicles: [],
  };

  public quickSearch: string = '';

  public searchedDepartment: MappingDataType = { id: 0, name: '' };

  public step = 0;

  public setStep(index: number) {
    this.step = index;
  }

  public columnDef: TableColumn[] = [
    { label: 'ID', slug: 'id', sortable: false, filterType: 'number' },
    {
      label: 'Location',
      slug: 'name',
      sortable: false,
      accessor: (i: MappingDataType) => i.name,
    },
    {
      label: 'Actions',
      slug: 'actions',
      dataType: 'actions',
      actions: [
        {
          label: 'Edit',
          action: (i: MappingDataType) => {
            this.setStep(0);
            this.selected = i;
            this.locationForm.get('name')?.setValue(i.name);
            this.locationForm.get('email')?.setValue(i?.email ?? '');
            if (i.loading_rates?.length) this.loading_rates = i.loading_rates;
            this.loading = true;
            const loadings = { a: true, b: true };
            this.departments.forEach((item: MappingDataType, itemIndex) => {
              this.departments[itemIndex].checked = (
                this.selected.departments ?? []
              ).includes(item.id);
            });

            this.roles.forEach((item: MappingDataType, itemIndex) => {
              this.roles[itemIndex].checked = (
                this.selected.vehicles ?? []
              ).includes(item.id);
            });

            this.settingsApi
              .fetchMappingData(this.selected.id)
              .subscribe((r) => {
                let res = CodedResponseModel.decode(r);
                loadings.a = false;
                this.loading = loadings.a || loadings.b;
                this.mappings = res.data;
              });

            this.settingsApi.fetchPayrates(this.selected.id).subscribe((r) => {
              let res = CodedResponseModel.decode(r);
              loadings.b = false;
              this.loading = loadings.a || loadings.b;
              this._payrates = res.data ?? [];
            });
            this.modalStatus = 'EDIT_FORM';
          },
        },
        { label: 'Delete', action: (i: MappingDataType) => this.del(i) },
      ],
    },
  ];

  public formTblColumnDef1: TableColumnV2[] = [
    { label: 'checked', slug: 'checked', colType: 'checkbox' },
    {
      label: 'Department Name',
      slug: 'name',
      accessor: (i: MappingDataType) => i.name,
    },
  ];
  public formTblColumnDef2: TableColumnV2[] = [
    { label: 'checked', slug: 'checked', colType: 'checkbox' },
    {
      label: 'Role Name',
      slug: 'name',
      accessor: (i: MappingDataType) => i.name,
    },
  ];

  public formTblColumnDef3: TableColumnV2[] = [
    { label: 'Role', slug: 'role', accessor: (i: PayratesType) => i.name },
    {
      label: 'DEDI Rate',
      slug: 'dedi_rate',
      colType: '_input',
      options: { prefix: '$', suffix: '', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full !text-left',
      accessor: (i: PayratesType) => i.dedi_rate,
    },
    {
      label: 'Fuel Levy',
      slug: 'fuel_levy',
      colType: '_input',
      options: { prefix: '', suffix: '%', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full !text-left',
      accessor: (i: PayratesType) => i.fuel_levy,
    },
    {
      label: 'FLEXI Delivery Rate',
      slug: 'flexi_delivery',
      colType: '_input',
      options: { prefix: '$', suffix: '', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full !text-left',
      accessor: (i: PayratesType) => i.flexi_delivery,
    },
    {
      label: 'FLEXI Pick Up Rate',
      slug: 'flexi_pickup',
      colType: '_input',
      options: { prefix: '$', suffix: '', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full !text-left',
      accessor: (i: PayratesType) => i.flexi_pickup,
    },
  ];

  public formTblColumnDef4: TableColumnV2[] = [
    {
      label: 'Loading Charge',
      slug: 'loading_charge',
      accessor: (i: LoadingRatesType) => i.loading_charge,
    },
    {
      label: 'Percentage',
      slug: 'percentage',
      colType: '_input',
      options: { prefix: '', suffix: '%', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full !text-left',
      accessor: (i: LoadingRatesType) => i.percentage,
    },
    {
      label: 'Fixed Rate',
      slug: 'fixed_rate',
      colType: '_input',
      options: { prefix: '$', suffix: '', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full !text-left',
      accessor: (i: LoadingRatesType) => i.fixed_rate,
    },
  ];

  public query: IndexQuery = {
    page: 1,
    perPage: 15,
    filters: {
      search: '',
    },
  };

  public total: number = 0;
  public filtered: number = 0;

  constructor(
    private settingsApi: SettingsAPI,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    this.fetchData();
    this.initializeForm();
  }

  ngOnInit(): void {}

  public fetchData() {
    this.loading = true;
    this.settingsApi.getLocations(this.query).subscribe((r) => {
      let res = CodedResponseModel.decode(r);

      this.locations = res.data;

      this.filtered = res.filtered;
      this.total = res.total;

      this.fetchAllDepartmentsRoles();
    });
  }

  public del(u: MappingDataType) {
    let d = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Are you sure you want to delete the location '${u.name}'?`,
        button1: 'Delete',
        button2: 'Cancel',
      },
    });
    d.afterClosed().subscribe((c) => {
      if (c) {
        this.settingsApi.delete(u.id, 'locations').subscribe(
          (r) => {
            NotificatorPartial.push({
              type: 'info',
              message: `${u.name} has been deleted`,
              timeout: 3000,
            });
            this.fetchData();
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: 'An error has occurred',
              details: err.error.message,
              timeout: 3000,
            });
          }
        );
      }
    });
  }

  initializeForm() {
    this.locationForm = this.formBuilder.group({
      name: ['', [Validators.required, this.noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  public searcherInput() {
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.query.page = 1;
      this.fetchData();
    }, 500);
  }

  public openCreateForm() {
    this.setStep(0);
    this.departments.forEach((item: MappingDataType, itemIndex) => {
      this.departments[itemIndex].checked = false;
    });

    this.roles.forEach((item: MappingDataType, itemIndex) => {
      this.roles[itemIndex].checked = false;
    });

    this.mappings = [];
    this._payrates = [];

    this.modalStatus = 'CREATE_FORM';
  }
  public store() {
    const formValues = this.locationForm.value;
    if (!this.locationForm.valid) {
      this.handleExpandStep0();
      return this.markControlsAsTouched();
    }
    this.storeDepartmentRoleMappings();
    this.loading = true;

    const departments4Location = this.departments.flatMap((department) => {
      if (department.checked) {
        return [department.id];
      }
      return [];
    });

    const roles4Location = this.roles.flatMap((role) => {
      if (role.checked) {
        return [role.id];
      }
      return [];
    });

    const departmentRoleMappings = Object.keys(this.mappingsObject)
      .map((departmentKey: string) => {
        const mappings: {
          department_id: number;
          vehicle_id: number;
        }[] = [];

        Object.keys(this.mappingsObject[Number(departmentKey)]).forEach(
          (roleKey: string) => {
            if (this.mappingsObject[Number(departmentKey)][Number(roleKey)])
              mappings.push({
                department_id: Number(departmentKey),
                vehicle_id: Number(roleKey),
              });
            return true;
          }
        );
        return mappings;
      })
      .flat();

    if (this.modalStatus === 'CREATE_FORM') {
      this.settingsApi
        .store(
          {
            location: {
              ...formValues,
              departments: departments4Location,
              vehicles: roles4Location,
              loading_rates: this.loading_rates,
            },
            departmentRoleMappings,
            payrates: this.payrates,
          },
          'locations'
        )
        .subscribe(
          (res) => {
            this.loading = false;
            this.fetchData();
            NotificatorPartial.push({
              type: 'success',
              message: `${formValues.name} has been created`,
              timeout: 3000,
            });
            this.closeForm();
          },
          (err) => {
            this.loading = false;
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
              timeout: 3000,
            });
          }
        );
    } else {
      this.settingsApi
        .edit(
          {
            location: {
              ...formValues,
              departments: departments4Location,
              vehicles: roles4Location,
              loading_rates: this.loading_rates,
            },
            departmentRoleMappings,
            payrates: this.payrates,
          },
          this.selected.id,
          'locations'
        )
        .subscribe(
          (res) => {
            this.loading = false;
            this.fetchData();
            NotificatorPartial.push({
              type: 'success',
              message: `${formValues.name} has been updated`,
              timeout: 3000,
            });
            this.closeForm();
          },
          (err) => {
            this.loading = false;
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
              timeout: 3000,
            });
          }
        );
    }
  }

  markControlsAsTouched(): void {
    Object.values(this.locationForm.controls).forEach((control) => {
      control.markAsTouched();
    });
  }
  isInvalidControl(controlName: string): boolean {
    const control = this.locationForm.get(controlName) as FormControl;
    return control.invalid && (control.dirty || control.touched);
  }
  public fetchAllDepartmentsRoles() {
    let loading = { a: true, b: true };
    this.settingsApi
      .getDepartments({
        page: 1,
        perPage: 1000,
        filters: {
          search: '',
        },
      })
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);
        this.departments = res.data.map((item: any) => ({
          ...item,
          checked: this.selected.departments?.includes(item.name),
        }));
        loading.a = false;
        this.loading = loading.a || loading.b;
      });

    this.settingsApi
      .getRoles({
        page: 1,
        perPage: 1000,
        filters: {
          search: '',
        },
      })
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);
        this.roles = res.data.map((item: any) => ({
          ...item,
          checked: this.selected.vehicles?.includes(item.name),
        }));
        loading.b = false;
        this.loading = loading.a || loading.b;
      });
  }
  public handleExpandStep0() {
    this.storeDepartmentRoleMappings();
    this.setStep(0);
  }

  public handleExpandStep1() {
    this.storeDepartmentRoleMappings();
    this.setStep(1);
  }

  public handleExpandStep2() {
    const departments4Location = this.departments.filter(
      (department) => department.checked
    );

    this.setStep(2);

    if (
      !departments4Location
        .map((department4Location) => department4Location.id)
        .includes(this.searchedDepartment.id)
    )
      this.searchedDepartment = { id: 0, name: '' };

    this._roles = _.sortBy(this.roles, (obj) => obj.id).flatMap((role) => {
      if (!role.checked) return [];
      return [
        {
          ...role,
          checked: this.searchedDepartment.id
            ? this.mappingsObject?.[this.searchedDepartment.id]?.[role.id] ??
              false
            : false,
        },
      ];
    });

    if (this.expandStatus.step2) return;
    const roles4Location = this.roles.flatMap((role) => {
      if (role.checked) {
        return [role.id];
      }
      return [];
    });

    departments4Location.forEach((department) => {
      const corrRoles = this.mappings.flatMap((mapping) => {
        if (mapping.department_id === department.id) {
          return [mapping.vehicle_id];
        }
        return [];
      });

      let roles4Department!: { [key: number]: boolean };
      roles4Location.forEach((role4Location) => {
        roles4Department = {
          ...roles4Department,
          [role4Location]: corrRoles.includes(role4Location),
        };
      });

      this.mappingsObject = {
        ...this.mappingsObject,
        [department.id]: roles4Department,
      };
    });
    this.expandStatus.step2 = true;
  }

  public handleExpandStep3() {
    this.storeDepartmentRoleMappings();
    if (this.expandStatus.step3) this._payrates = _.cloneDeep(this.payrates);
    const roles4Location = this.roles.flatMap((role) => {
      if (role.checked) {
        return [role];
      }
      return [];
    });

    this.payrates = roles4Location.map((role4Location) => {
      const corrPayrate = this._payrates.find(
        (payrate) => payrate.vehicle_id === role4Location.id
      );
      if (corrPayrate)
        return {
          ...corrPayrate,
          name: role4Location.name,
        };
      return {
        name: role4Location.name,
        vehicle_id: role4Location.id,
        dedi_rate: 0,
        fuel_levy: 0,
        flexi_delivery: 0,
        flexi_pickup: 0,
      };
    });
    this.expandStatus.step3 = true;
    this.setStep(3);
  }

  public handleExpandStep4() {
    this.setStep(4);
    if (!this.loading_rates.length) this.setDefaultLoadingCharge();
  }

  public filterDepartments() {
    return this.departments.filter((department) => department.checked);
  }

  public handleSelectionChange(event: any) {
    this.storeDepartmentRoleMappings();
    const department = this.departments.find(
      (item) => item.name === event.option.value
    ) as MappingDataType;

    this.searchedDepartment = { ...department };

    this._roles.forEach((role, roleIndex) => {
      if (!(this.mappingsObject?.[department.id] ?? false))
        this.mappingsObject = { ...this.mappingsObject, [department.id]: {} };
      this._roles[roleIndex].checked =
        this.mappingsObject[department.id][role.id];
      return true;
    });
  }

  public storeDepartmentRoleMappings() {
    if (this.searchedDepartment.id) {
      this._roles.forEach((role) => {
        if (!(this.mappingsObject?.[this.searchedDepartment.id] ?? false))
          this.mappingsObject = {
            ...this.mappingsObject,
            [this.searchedDepartment.id]: {},
          };
        this.mappingsObject[this.searchedDepartment.id][role.id] = role.checked;
        return true;
      });
    }
  }

  public closeForm() {
    this.expandStatus = { step2: false, step3: false };
    this.selected = {
      id: 0,
      name: '',
      departments: [],
      vehicles: [],
    };
    this.initializeForm();
    this.searchedDepartment = { id: 0, name: '' };
    this.mappingsObject = {};
    this.payrates = [];
    this.modalStatus = 'NONE';
    this.loading_rates = [];
    this.departments = _.sortBy(this.departments, (obj) => obj.id);
    this.roles = _.sortBy(this.roles, (obj) => obj.id);
  }

  public handleSort(type: 'departments' | 'roles' | '_roles') {
    switch (type) {
      case 'departments':
        this.departments = _.sortBy(this.departments, (obj) =>
          _.toLower(obj.name)
        );
        break;
      case 'roles':
        this.roles = _.sortBy(this.roles, (obj) => _.toLower(obj.name));
        break;
      case '_roles':
        this._roles = _.sortBy(this._roles, (obj) => _.toLower(obj.name));
        break;
      default:
        break;
    }
  }

  public filterChecked(value: (MappingDataType & CheckedStatusType)[]) {
    return value.filter((item) => item.checked).length;
  }
  public addDepartment() {
    this.additionalFormTitle = 'Department';
    this.openAdditionalCreationForm();
  }
  public addRole() {
    this.additionalFormTitle = 'Role';
    this.openAdditionalCreationForm();
  }
  public openAdditionalCreationForm() {
    this.currentValue = '';
    this.modalStatus = 'ADDITIONAL_CREATE_FORM';
  }

  public setDefaultLoadingCharge() {
    this.loading_rates = loading_charges.map((loading_charge) => ({
      loading_charge,
      percentage: 0,
      fixed_rate: 0,
    }));
  }

  public _store() {
    if (!this.currentValue || !this.currentValue.trim().length)
      return NotificatorPartial.push({
        type: 'error',
        message: `${this.additionalFormTitle} Name is mandatory`,
        timeout: 3000,
      });
    this.loading = true;
    const type =
      this.additionalFormTitle === 'Department' ? 'departments' : 'roles';

    this.settingsApi.store({ name: this.currentValue }, type).subscribe(
      (r) => {
        this.loading = false;
        this.closeModal();
        const data = CodedResponseModel.decode(r);

        if (type === 'departments') {
          this.departments.push({ ...data, checked: false });
        }
        if (type === 'roles') {
          this.roles.push({ ...data, checked: false });
        }
        NotificatorPartial.push({
          type: 'success',
          message: `${this.currentValue} has been created`,
          timeout: 3000,
        });
      },
      (err) => {
        this.loading = false;
        this.closeModal();
        NotificatorPartial.push({
          type: 'error',
          message: err.error.message,
          timeout: 3000,
        });
      }
    );
  }
  public closeModal() {
    this.modalStatus = this.selected.id ? 'EDIT_FORM' : 'CREATE_FORM';
  }
}
