<div class='w-full flex lg:flex-row flex-col justify-center gap-5'>
    <div class='w-[600px]'>
        <app-heading1 [headingTitle]="editMode?'Edit User':'Add User'"></app-heading1>

        <app-form-input [isDisabled]="true" *ngIf="editMode" labelText="User ID" [model]="user.id"></app-form-input>
        <app-form-input [(model)]="user.name" labelText="First Name"></app-form-input>
        <app-form-input [(model)]="user.lname" labelText="Last Name"></app-form-input>
        <app-form-input [(model)]="user.email" labelText="Email Address"></app-form-input>

        <hr>
        <app-form-input [(model)]="user.phone" labelText="Phone"></app-form-input>
        <app-form-input [(model)]="user.bankAcc" labelText="Bank Account"></app-form-input>
        <app-form-input [(model)]="user.bsb" labelText="BSB"></app-form-input>

        <hr>
        <app-form-input [(model)]="user.humanforceId" labelText="Humanforce ID"></app-form-input>
        <app-form-input [(model)]="user.donesafe_id" labelText="Donesafe ID"></app-form-input>
        <app-form-input [(model)]="user.route4meId" labelText="Route4me ID"></app-form-input>

        <hr>
        <app-form-input [model]="user.referralCode" labelText="Referral Code" [isDisabled]="true"></app-form-input>

        <hr>
        <div class='w-full py-2'>
            <label class="font-bold">Notes: </label>
            <textarea rows="5"
                class='w-full border border-[#D0D5DD] border-solid text-base font-Inter px-[14px] py-[10px] rounded-lg outline-none scroll-thin-style'
                [(ngModel)]='user.notes' style="margin-top: 10px"></textarea>
        </div>

        <hr>
        <div *ngxPermissionsOnly="['Admin']" class='w-full flex justify-between items-center py-2'>
            <label>
                <input type="radio" name="role" value="Admin" [(ngModel)]="user.user_role"> Administrator
            </label>
            <label>
                <input type="radio" name="role" value="Accounting" [(ngModel)]="user.user_role"> Accounting
            </label>
            <label>
                <input type="radio" name="role" value="Operations" [(ngModel)]="user.user_role"> Operations
            </label>
            <label>
                <input type="radio" name="role" value="Compliance" [(ngModel)]="user.user_role"> Compliance
            </label>
            <label>
                <input type="radio" name="role" value="Command" [(ngModel)]="user.user_role"> Command
            </label>
            <label>
                <input type="radio" name="role" value="Guest" [(ngModel)]="user.user_role"> Guest
            </label>
        </div>
        <div class='w-full flex justify-start items-center gap-2 py-2'>
            <label class='w-full flex items-center justify-start gap-2'>
                <input type="checkbox" name="beta_user" [(ngModel)]="user.beta_user">
                <span class='text-[#101828] text-base font-normal leading-6'>Beta User</span>
            </label>
            <label class='w-full flex items-center justify-start gap-2'>
                <input type="checkbox" name="beta_visible" [(ngModel)]="user.beta_visible">
                <span class='text-[#101828] text-base font-normal leading-6'>Beta Transactions Visibility</span>
            </label>
        </div>
        <app-button (buttonClickEvent)="save()" text="Save"></app-button>
        <app-link-button routerLink="/users" [isIcon]="false" text="Back"></app-link-button>
    </div>
    <div class='min-w-[700px] mt-14 flex flex-col gap-5'>
        <div [ngClass]="{'non-touchable':!authService.hasPermissions(['full:users', 'edit:users','edit-mapping:users'])}"
            class='w-full flex gap-5'>
            <div class='h-[300px] max-h-[300px] w-[50%] p-5 flex flex-col items-start bg-[#f1f5fc]'>
                <span class='text-base font-bold text-[#143682]'>Locations</span>
                <div class='w-full flex flex-col flex-1 overflow-x-hidden overflow-y-auto scroll-thin-style'>
                    <div *ngFor="
              let location of locations;
              let i = index
            ">
                        <mat-checkbox color="primary" [checked]="location.checked"
                            (change)="setCheckedStatus('location', $event.checked, location)">
                            {{ location.name }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class='h-[300px] max-h-[300px] w-fit min-w-[50%] p-5 flex flex-col items-start bg-[#f1f5fc]'>
                <span class='text-base font-bold text-[#143682]'>Departments</span>
                <div class='w-full flex flex-col flex-1 overflow-x-hidden overflow-y-auto scroll-thin-style'>
                    <div *ngFor="
              let department of filteredDepartments;
              let i = index
            ">
                        <mat-checkbox color="primary" [checked]="department.checked"
                            (change)="setCheckedStatus('department',$event.checked, department)">
                            {{ department.name }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class='w-[700px] p-5 flex flex-col items-start bg-[#f1f5fc]'>
            <span class='text-base font-bold text-[#143682]'>Roles</span>
            <div class='w-full h-[300px] max-h-[300px]'>
                <c-table-v2 [columns]="formTblColumnDef3" [data]="filteredPayrates"></c-table-v2>
            </div>
        </div>
    </div>
</div>