<form [formGroup]="inventoryCodeSetForm" (ngSubmit)="save()">
    <div class='grid grid-cols-2 p-5 gap-2'>
        <div class='flex flex-col gap-2'>
            <span class='text-[#101828] text-base leading-6 font-bold'>Roles</span>
            <div formArrayName="vehicles" *ngFor="let control of vehiclesFormArray.controls; let i=index">
                <div [formGroupName]="i" class='grid grid-cols-5 gap-2'>
                    <label class='whitespace-nowrap col-span-2'>{{ control.get('name')?.value }}</label>
                    <input [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(vehiclesFormArray, i,'inventory_code'),
                    'border-[#D0D5DD]':!isControlInValidInGroup(vehiclesFormArray, i,'inventory_code')}"
                        class='col-span-3 border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                        type="text" [formControlName]="'inventory_code'">
                </div>
            </div>
        </div>
        <div class='flex flex-col gap-2'>
            <span class='text-[#101828] text-base leading-6 font-bold'>Loading Charges</span>
            <div formArrayName="loading_charges" *ngFor="let control of loadingChargesFormArray.controls; let i=index">
                <div [formGroupName]="i" class='grid grid-cols-5 gap-2'>
                    <label class='whitespace-nowrap col-span-2'>{{ control.get('name')?.value }}</label>
                    <input [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(loadingChargesFormArray, i,'inventory_code'),
                    'border-[#D0D5DD]':!isControlInValidInGroup(loadingChargesFormArray, i,'inventory_code')}"
                        class='col-span-3 border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                        type="text" [formControlName]="'inventory_code'">
                </div>
            </div>
        </div>
        <div></div>
        <div>
            <button class='custom-blue-solid-btn' type='submit'>
                Save
            </button>
        </div>
    </div>
</form>