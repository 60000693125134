<div class='w-full shift-offer-height max-h-screen flex flex-col'>
    <div class="offers_drawer" [ngClass]="{
        drawer_appear: isExpanded.drawer,
        drawer_disappear: !isExpanded.drawer
      }">
        <div class="offers_drawer_content">
            <div class="offers_drawer_header">
                <div>
                    <span>Filters</span>
                    <span (click)="handleVisivility('drawer', false)">
                        <img src="/assets/icons/x-close.svg" alt="search-icon" /></span>
                </div>
                <span>Select filters to refine data.</span>
            </div>
            <div class="offers_drawer_body scroll-thin-style">
                <div class="offers_states scroll-thin-style">
                    <div class="offers_state_header" (click)="handleVisivility('state')">
                        <ng-container *ngIf="isExpanded.state">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.state">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>State</span>
                    </div>
                    <div class="offers_state_content" *ngIf="isExpanded.state">
                        <div *ngFor="
                  let state of state_department_data.state;
                  let i = index
                ">
                            <mat-checkbox color="primary" [checked]="isChecked(state.isChecked)"
                                (change)="setFilters($event.checked, 'state', state)">
                                {{ state.state }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="offers_states scroll-thin-style">
                    <div class="offers_state_header" (click)="handleVisivility('department')">
                        <ng-container *ngIf="isExpanded.department">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.department">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>Department</span>
                    </div>
                    <div class="department_search" *ngIf="isExpanded.department">
                        <div class="rounded_input_wrap">
                            <img src="/assets/icons/search.svg" alt="search-icon" />
                            <input class="none_border_outline" [(ngModel)]="departmentSearch"
                                (ngModelChange)="searchFilterOption($event,'department')"
                                style="border: none; outline: none" placeholder="Quick search" />
                        </div>
                    </div>
                    <div class="offers_state_content offers_departments scroll-thin-style"
                        *ngIf="isExpanded.department">
                        <div *ngFor="
                  let department of filterDepartments(
                    state_department_data.department
                  );
                  let i = index
                " class="department_loop">
                            <mat-checkbox color="primary" [checked]="isChecked(department.isChecked)"
                                (change)="setFilters($event.checked, 'department', department)">
                                {{ department.department }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="offers_states scroll-thin-style">
                    <div class="offers_state_header" (click)="handleVisivility('vehicle')">
                        <ng-container *ngIf="isExpanded.vehicle">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.vehicle">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>Vehicle</span>
                    </div>
                    <div class="department_search" *ngIf="isExpanded.vehicle">
                        <div class="rounded_input_wrap">
                            <img src="/assets/icons/search.svg" alt="search-icon" />
                            <input class="none_border_outline" [(ngModel)]="vehicleSearch"
                                (ngModelChange)="searchFilterOption($event,'vehicle')"
                                style="border: none; outline: none" placeholder="Quick search" />
                        </div>
                    </div>
                    <div class="offers_state_content offers_departments scroll-thin-style" *ngIf="isExpanded.vehicle">
                        <div *ngFor="
                  let vehicle of filterVehicles(
                    state_department_data.vehicle
                  );
                  let i = index
                " class="department_loop">
                            <mat-checkbox color="primary" [checked]="isChecked(vehicle.isChecked)"
                                (change)="setFilters($event.checked, 'vehicle', vehicle)">
                                {{ vehicle.vehicle }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="offers_drawer_footer">
                <span (click)="handleClearAllChecked()">Clear Filters</span>
                <div>
                    <button class="filter_cancel" (click)="handleVisivility('drawer', false)">
                        Cancel
                    </button>
                    <button class="filter_apply" (click)="handleFilterApply()">
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="header_wrap">
        <div class="flex_between">
            <button class="rounded_blue enabled_color"><span class='whitespace_nowrap'>DEDI Shift Offers</span></button>
            <div class='assignment_status_wrapper'>
                <div class='assignment_status'>
                    <button class='assignment_status_btn'
                        [ngClass]="{'assignment_status_active':assignmentStatus==='ASSIGNED','assignment_status_inactive':assignmentStatus==='UNASSIGNED'}"
                        (click)="handleAssignmentStatus('ASSIGNED')">Assigned</button>
                    <button class='assignment_status_btn'
                        [ngClass]="{'assignment_status_inactive':assignmentStatus==='ASSIGNED','assignment_status_active':assignmentStatus==='UNASSIGNED'}"
                        (click)="handleAssignmentStatus('UNASSIGNED')">Unassigned</button>
                </div>
                <button class='create_offer' (click)="handleCreate()">
                    <span>Create</span>
                    <img src="/assets/icons/plus.svg" alt="plus-icon" />
                </button>
            </div>
        </div>
        <div class="search_filter_wrap">
            <div class="align_center">
                <div class="rounded_input_wrap">
                    <img src="/assets/icons/search.svg" alt="search-icon" />
                    <input class="none_border_outline" [ngModel]="query.filters!.search"
                        (ngModelChange)="searcherInput($event)" style="border: none; outline: none"
                        placeholder="Search" />
                </div>
                <div class="offers_filters" type="button" mat-button (click)="handleVisivility('drawer')">
                    <img src="/assets/icons/filter-lines.svg" alt="filter-lines-icon" />
                    <span>Filters</span>
                </div>
            </div>
            <div class="search_option_wrap">
                <div class="date_range_selection">
                    <div class="date_from_selection">
                        <div class="hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="fromDate"
                                    [min]="assignmentStatus==='ASSIGNED'?0:minDate"
                                    (ngModelChange)="handleFromDateChange($event)" />
                                <mat-datepicker #picker [ngClass]="'my-theme-primary'"><mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>
                                            Apply
                                        </button>
                                    </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <span class="date_selection_text whitespace_nowrap">From:&nbsp;
                            <span class="date_selection_date">{{
                                getFormattedTime(fromDate)
                                }}</span>
                        </span>
                        <button (click)="picker.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                    <div class="date_from_selection">
                        <div class="hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker1" [(ngModel)]="toDate"
                                    [min]="assignmentStatus==='ASSIGNED'?0:minDate"
                                    (ngModelChange)="handleToDateChange($event)" />
                                <mat-datepicker #picker1><mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>
                                            Apply
                                        </button>
                                    </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <span class="date_selection_text whitespace_nowrap">To:&nbsp;
                            <span class="date_selection_date">{{
                                getFormattedTime(toDate)
                                }}</span>
                        </span>
                        <button (click)="picker1.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                </div>
                <div class="filtered_option_remove" *ngIf="
                  filteredData.state.length > 0 ||
                  filteredData.department.length > 0||
                  query.filters!.search
                ">
                    <span (click)="handleClearAllFilters()">Clear Filters</span>
                </div>
            </div>
        </div>
    </div>
    <div class='w-full box-border px-[105px] py-5 custom-height'>
        <div class="w-full h-full flex flex-col gap-y-2 justify-center items-center font-Inter"
            *ngIf="!(data.length > 0)">
            <img src="/assets/icons/Illustration.svg" alt="Illustration-icon" />
            <span class='text-base font-Inter font-medium'>No Data Found</span>
            <div class="w-full flex justify-center gap-x-2">
                <button class="custom-outlined-btn !w-fit" (click)="fetchData()">Try again</button>
                <button class="custom-solid-btn !w-fit" (click)="clearSearch()">
                    Clear Search
                </button>
            </div>
        </div>
        <div class='w-full h-full flex flex-col border border-solid border-[#EAECF0] rounded-xl overflow-hidden shadow-lg'
            [ngClass]="{'hidden':data.length<1}">
            <!-- Table header -->
            <div
                class='w-full flex bg-[#F9FAFB] border border-solid border-[#EAECF0] text-xs font-medium text-[#475467]'>
                <div class='w-[12%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                    <span>Date</span>
                    <span class='hover:cursor-pointer' (click)="handleSort('date')">
                        <img src='/assets/icons/offers/chevron-vertical.svg' />
                    </span>
                </div>
                <div class='w-[13%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                    <span>Start Time</span>
                    <span class='hover:cursor-pointer' (click)="handleSort('start_time')">
                        <img src='/assets/icons/offers/chevron-vertical.svg' />
                    </span>
                </div>
                <div class='w-[12%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                    <span>State</span>
                    <span class='hover:cursor-pointer' (click)="handleSort('state')">
                        <img src='/assets/icons/offers/chevron-vertical.svg' />
                    </span>
                </div>
                <div class='w-[22%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                    <span>Department</span>
                    <span class='hover:cursor-pointer' (click)="handleSort('department')">
                        <img src='/assets/icons/offers/chevron-vertical.svg' />
                    </span>
                </div>
                <div class='w-[15%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                    <span>Vehicle(s)</span>
                    <span class='hover:cursor-pointer' (click)="handleSort('vehicles')">
                        <img src='/assets/icons/offers/chevron-vertical.svg' />
                    </span>
                </div>
                <div class='w-[11%] flex justify-start items-center gap-2 box-border px-6 py-3'
                    *ngIf="assignmentStatus==='ASSIGNED'">
                    <span>Clocked?</span>
                    <!-- <img src='/assets/icons/offers/chevron-vertical.svg' /> -->
                </div>
                <div class='w-[15%] flex justify-start items-center gap-2 box-border px-6 py-3'
                    *ngIf="assignmentStatus==='ASSIGNED'">
                    <span>Assigned To</span>
                    <span class='hover:cursor-pointer' (click)="handleSort('assignedTo')">
                        <img src='/assets/icons/offers/chevron-vertical.svg' />
                    </span>
                </div>
                <div class='w-[26%] flex justify-start items-center gap-2 box-border px-6 py-3'
                    *ngIf="assignmentStatus==='UNASSIGNED'">
                    <span>Assign To</span>
                    <span class='hover:cursor-pointer' (click)="handleSort('assignTo')">
                        <img src='/assets/icons/offers/chevron-vertical.svg' />
                    </span>
                </div>
            </div>
            <div id='scroll-container' class='w-full h-full overflow-y-scroll overflow-x-hidden  scroll-thin-style'
                (scroll)='onScroll($event)'>
                <div id='scroll-content'
                    class='w-full flex bg-white border border-solid border-[#EAECF0] text-sm font-normal text-[#475467] scroll-thin-style'
                    *ngFor="let offer of data; let i=index">
                    <div class='w-[12%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                        <span>{{offer.date}}</span>
                    </div>
                    <div class='w-[13%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                        <span>{{offer.start_time}}</span>
                    </div>
                    <div class='w-[12%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                        <span>{{offer.state}}</span>
                    </div>
                    <div class='w-[22%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                        <span>{{offer.department}}</span>
                    </div>
                    <div class='w-[15%] flex justify-start items-center gap-2 box-border px-6 py-3'>
                        <span>{{assignmentStatus==='ASSIGNED'?offer.allocated_vehicle:offer.vehicles}}</span>
                    </div>
                    <div class='w-[11%] flex justify-start items-center gap-2 box-border px-6 py-3'
                        *ngIf="assignmentStatus==='ASSIGNED'">
                        <img src="/assets/icons/offers/clock.svg" alt="clock" *ngIf="offer.clocked_in" />
                    </div>
                    <div class='w-[15%] flex justify-start items-center gap-2 box-border px-6 py-3'
                        *ngIf="assignmentStatus==='ASSIGNED'">
                        <span>{{offer.assignee}}</span>
                        <span class='hover:cursor-pointer' (click)="handleDeleteOffer(offer)" *ngIf="!offer.clocked_in">
                            <img src="/assets/icons/offers/trash.svg" alt="trash" />
                        </span>
                    </div>
                    <div class='w-[26%] flex justify-start items-center gap-2 gap-x-4 box-border px-6 py-3 relative'
                        *ngIf="assignmentStatus==='UNASSIGNED'">
                        <span
                            class='w-6 h-5 rounded-full border-2 border-solid border-[#FE9595] bg-[#D64444] text-white flex justify-center items-center'>{{offer.interested_drivers.length}}</span>
                        <span class='hover:cursor-pointer' (click)="handleDeleteOffer(offer)">
                            <img src="/assets/icons/offers/trash.svg" alt="trash" />
                        </span>
                        <span (click)="handleClickDropdown($event,offer,offer.interested_drivers.length)"
                            class='hover:cursor-pointer'>
                            <img src="/assets/icons/offers/chevron-vertical.svg" alt="checvron vertical" />
                        </span>
                    </div>
                </div>

                <!-- <ng-container *ngIf="offer.interested_drivers.length>0&&selected===i"> -->
                <div #dropdown
                    class='absolute left-11 w-[340px] max-h-[250px] border border-solid border-[#EAECF0] rounded-lg bg-white z-[500]'
                    style='display: none;'>
                    <div class='w-full flex'>
                        <div class='h-5 w-[50%] max-w-[50%] flex justify-center items-center font-bold text-[#2a6af6]'>
                            Driver</div>
                        <div class='h-5 w-[50%] max-w-[50%] flex justify-center items-center font-bold text-[#2a6af6]'>
                            Vehicle</div>
                    </div>
                    <div class='w-full max-h-[230px] overflow-x-hidden overflow-y-auto scroll-thin-style'>
                        <div (click)="handleSelectAssignee(option)"
                            *ngFor="let option of selectedOffer.interested_drivers"
                            class='w-full flex p-[10px] items-center font-Inter text-base font-medium text-[#101828] hover:cursor-pointer'>
                            <div class='w-[50%] max-w-[50%] gap-x-2 flex justify-start items-center'>
                                <img src="/assets/icons/offers/user.svg" alt="user" />
                                <span>{{ option.name }} {{ option.lname}}</span>
                            </div>
                            <div class='w-[50%] max-w-[50%] flex justify-start items-center'>
                                {{option.vehicle}}
                            </div>
                        </div>
                    </div>
                </div>
                <div #overlay class='custom_overlay_background' style='display: none;'
                    (click)="handleClickDropdown($event,'none',-1)">

                </div>
                <!-- </ng-container> -->
            </div>

        </div>
    </div>
</div>
<app-modal-body *ngIf="modalStatus!=='NONE'">
    <ng-container [ngTemplateOutlet]="creation_template" *ngIf="modalStatus==='OPEN_CREATION'"></ng-container>
    <ng-container [ngTemplateOutlet]="confirm_template"
        *ngIf="modalStatus==='ASSIGN_CONFIRM'||modalStatus==='DELETE_CONFIRM'"></ng-container>
    <ng-container [ngTemplateOutlet]="assign_past_confirm_template"
        *ngIf="modalStatus==='ASSIGN_PAST_CONFIRM'"></ng-container>
    <ng-container [ngTemplateOutlet]="notif_template" *ngIf="modalStatus==='ASSIGN_FAIL_NOTIF'"></ng-container>
    <ng-container [ngTemplateOutlet]="notif_template" *ngIf="modalStatus==='DELETED_NOTIF'"></ng-container>
</app-modal-body>
<ng-template #confirm_template>
    <div class='w-[400px] flex flex-col items-center box-border relative gap-y-8'>
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='w-full flex flex-col gap-y-4'>
            <div class='w-full flex justify-center items-center'>
                <img src="/assets/icons/offers/success.svg" alt="success-icon" *ngIf="modalStatus==='ASSIGN_CONFIRM'" />
                <img src="/assets/icons/offers/warning.svg" alt="success-icon" *ngIf="modalStatus==='DELETE_CONFIRM'" />
            </div>
            <div class='w-full flex flex-col gap-y-[10px]'>
                <div class='w-full flex justify-center text-center font-Inter text-lg font-semibold leading-7'>
                    <ng-container *ngIf="modalStatus==='ASSIGN_CONFIRM'">
                        Assign the job to {{assigneeDetail.assignee.name}} {{assigneeDetail.assignee.lname}}?
                    </ng-container>
                    <ng-container *ngIf="modalStatus==='DELETE_CONFIRM'">
                        Are you sure you want to delete this offer?
                    </ng-container>
                </div>
                <div class='w-full text-center text-sm text-[#475467] font-Inter font-normal'
                    *ngIf="modalStatus==='DELETE_CONFIRM'">This will delete this offer permanently. You cannot undo this
                    action.</div>
                <div class='w-full flex flex-col items-start box-border py-[10px] gap-y-2 rounded-[10px]
                bg-[#F2F5FB] px-[14px]'>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>
                            Date:
                        </span>
                        {{assigneeDetail.date}}</span>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Start Time:
                        </span>
                        {{assigneeDetail.start_time}}</span>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>State:
                        </span>
                        {{assigneeDetail.state}}</span>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Department:
                        </span>
                        {{assigneeDetail.department}}</span>
                    <span class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Vehicle:
                        </span>
                        {{modalStatus==='DELETE_CONFIRM'?assignmentStatus==='ASSIGNED'?assigneeDetail.allocated_vehicle:
                        assigneeDetail.vehicles:assigneeDetail.assignee.vehicle}}</span>
                    <span *ngIf="assigneeDetail.attachment" class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Attached Picture:
                        </span>
                        <div class='rounded overflow-hidden max-w-[100px] max-h-[100px] relative'>
                            <a [href]="assigneeDetail.attachment" target='_blank'> <img
                                    [src]="assigneeDetail.attachment" alt="Image Preview"
                                    class='object-cover w-[100px] h-[100px] max-w-[100px] max-h-[100px]'></a>
                        </div>
                    </span>
                    <span *ngIf="assigneeDetail.job_comments"
                        class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Job comments for driver:
                        </span>
                        <p [innerHTML]="assigneeDetail.job_comments"></p>
                    </span>
                    <span *ngIf="assigneeDetail.charging_comments"
                        class='w-fit font-Inter text-[#475467] text-sm font-normal'>
                        <span class='font-semibold'>Charging Comments:
                        </span>
                        {{assigneeDetail.charging_comments}}</span>
                </div>
            </div>
        </div>
        <div class='w-full flex items-center gap-2'>
            <button class='custom-outlined-btn' (click)="closeModal()">Cancel</button>
            <button class='custom-solid-btn' (click)="handleAssignConfirm()"
                *ngIf="modalStatus==='ASSIGN_CONFIRM'">Confirm</button>
            <button
                class='w-full flex box-border px-[18px] py-[10px] justify-center items-center rounded-lg bg-[#CD4244] font-Inter text-base font-semibold outline-none border border-solid border-[#CD4244] text-white'
                (click)="handleDeleteConfirm()" *ngIf="modalStatus==='DELETE_CONFIRM'">Delete</button>
        </div>
    </div>
</ng-template>
<ng-template #creation_template>
    <form [formGroup]="offerCreationForm" #formRef="ngForm" (ngSubmit)="store()"
        class='w-[400px] flex flex-col items-center box-border relative gap-y-8'>
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='w-full flex justify-start items-center font-Inter text-lg text-[#101828] font-semibold'>
            New Job-Shift Offer
        </div>
        <div
            class='grid grid-cols-12 gap-x-4 gap-y-5 overflow-x-hidden overflow-y-auto scroll-thin-style box-border pr-2'>
            <div class='col-span-6'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Date</span>
                    </div>
                    <div class="w-full relative">
                        <div class="_hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="offerDate" [min]="minDate"
                                    (ngModelChange)="handleOfferDateChange($event)"
                                    [ngModelOptions]="{standalone: true}" />
                                <mat-datepicker #picker [ngClass]="'my-theme-primary'"><mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>
                                            Apply
                                        </button>
                                    </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <input (click)="picker.open()" placeholder='DD/MM/YYYY'
                            class='w-full h-10 overflow-hidden flex px-[14px] py-[10px] border border-solid border-[#D0D5DD] rounded-lg outline-none'
                            formControlName="date" readonly />
                    </div>
                </div>
            </div>
            <div class='col-span-6'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Start Time</span>
                    </div>
                    <div
                        class='w-full h-10 max-h-10 flex px-[14px] py-[10px] border border-solid border-[#D0D5DD] rounded-lg '>
                        <ngx-timepicker-field [defaultTime]="'11:00 am'" [controlOnly]="true" [(ngModel)]="selectedTime"
                            (ngModelChange)="handleStartTimeChange($event)"
                            [ngModelOptions]="{standalone: true}"></ngx-timepicker-field>
                        <input class='hidden' formControlName="start_time" />
                    </div>
                    <span class='text-[12px] font-Inter text-red-500'
                        *ngIf="offerCreationForm.hasError('pastTime')">*Start time is not available.</span>
                </div>
            </div>
            <div class='col-span-12'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>State</span>
                    </div>

                    <input placeholder='VIC' [ngClass]="{disabled:true}"
                        class='w-full h-10 overflow-hidden flex px-[14px] py-[10px] border border-solid  rounded-lg outline-none text-base font-Inter'
                        formControlName="state" [ngClass]="{
                              'border-[#ee4d4d]':formRef.submitted &&offerCreationForm.get('state')?.hasError('required'),
                              'border-[#D0D5DD]':!(formRef.submitted &&offerCreationForm.get('state')?.hasError('required'))
                            }" [matAutocomplete]="stateAuto" />
                    <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="handleSelectedState($event)">
                        <mat-option *ngFor="let state of states$ | async" [value]="state">
                            {{state}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class='col-span-12'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Department</span>
                    </div>
                    <div class='w-full flex relative'>
                        <img src="/assets/icons/search.svg" alt="search-icon" class='absolute top-[10px] left-[10px]' />
                        <input
                            class='w-full h-10 overflow-hidden flex justify-start items-center pl-9 pr-[14px] py-[10px] gap-x-2 border border-solid rounded-lg outline-none text-base font-Inter'
                            placeholder='Search' [matAutocomplete]="departmentAuto" formControlName="department"
                            [ngClass]="{
                                  'border-[#ee4d4d]':formRef.submitted &&offerCreationForm.get('department')?.hasError('required'),
                                  'border-[#D0D5DD]':!(formRef.submitted &&offerCreationForm.get('department')?.hasError('required'))
                                }" />
                        <mat-autocomplete #departmentAuto="matAutocomplete">
                            <mat-option
                                *ngFor="let street of departments$ | async | searchFilter:offerCreationForm.controls.department.value"
                                [value]="street">
                                {{street}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
            </div>
            <div class='col-span-12'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Vehicle(s)</span>
                    </div>

                    <app-multiselect-autocomplete class='w-full' [filteredValues]="vehicles$"
                        [selectedValues]="selectedVehicles" [valid]="multiSelectValid"
                        (selectedEvent)="handleSelectedEvent($event)"></app-multiselect-autocomplete>
                    <!-- <div class='w-full flex relative'>
                        <img src="/assets/icons/search.svg" alt="search-icon" class='absolute top-[10px] left-[10px]' />
                        <input
                            class='w-full h-10 overflow-hidden flex justify-start items-center pl-9 pr-[14px] py-[10px] gap-x-2 border border-solid rounded-lg outline-none text-base font-Inter'
                            placeholder='Search' [matAutocomplete]="vehicleAuto" formControlName="vehicle" [ngClass]="{
                                    'border-[#ee4d4d]':formRef.submitted &&offerCreationForm.get('vehicle')?.hasError('required'),
                                    'border-[#D0D5DD]':!(formRef.submitted &&offerCreationForm.get('vehicle')?.hasError('required'))
                                  }" />
                        <mat-autocomplete #vehicleAuto="matAutocomplete">
                            <mat-option
                                *ngFor="let street of vehicles$ | async|searchFilter:offerCreationForm.controls.vehicle.value"
                                [value]="street">
                                {{street}}
                            </mat-option>
                        </mat-autocomplete>
                    </div> -->
                </div>
            </div>

            <div class='col-span-12'>
                <input class='hidden' #fInput type="file" (change)="onFileSelected($event)" accept=".jpeg, .jpg, .png">
                <div class='w-full flex gap-x-2' *ngIf="imagePreviewUrl">
                    <div class='rounded overflow-hidden max-w-[100px] max-h-[100px] relative'>
                        <img [src]="imagePreviewUrl" alt="Image Preview"
                            class='object-cover w-[100px] h-[100px] max-w-[100px] max-h-[100px]'>
                        <div class='w-fit h-fit absolute top-[35px] left-[35px]' *ngIf="attachment.progress!==100">
                            <mat-progress-spinner [diameter]="30" [strokeWidth]="3"
                                [value]="attachment.progress"></mat-progress-spinner>
                        </div>
                        <div *ngIf="attachment.progress===100"
                            class='w-fit h-fit absolute top-[-12px] right-[-12px] hover:cursor-pointer'
                            (click)='handleClearAttachment()'>
                            <img src="/assets/icons/offers/cancel.svg" alt="close-icon" />
                        </div>
                    </div>
                    <div class='flex flex-1' *ngIf="!attachment.error">
                        <div class='flex flex-1 flex-col gap-2 font-Inter'>
                            <span class='text-base font-bold text-[#2A6AF6]'>{{attachment.name}}</span>
                            <span class='text-sm font-Lexend whitespace-nowrap'>
                                Upload progress:&nbsp;{{attachment.progress}}% </span>
                        </div>
                    </div>
                    <div class='flex flex-1' *ngIf="attachment.error">

                    </div>
                </div>
                <div (click)="fInput.click()" *ngIf="!imagePreviewUrl"
                    class='w-full flex p-2 rounded-lg border border-solid border-[#2a6af5] bg-[#2a6af522] justify-center items-center box-border font-bold font-Inter hover:cursor-pointer'>
                    Upload a Picture
                </div>
            </div>
            <div class='col-span-12'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#636569] font-Inter text-sm font-medium'>Job comments for driver</span>
                    </div>
                    <div class='w-full border border-[#D0D5DD] border-solid gap-2 rounded-lg'>
                        <div class='w-full flex justify-start items-center relative'>
                            <div (click)="openHyperLinkPopup()"
                                class='w-8 h-8 flex justify-center items-center hover:cursor-pointer hover:bg-[#DCDCDC] rounded overflow-hidden'>
                                <img src="/assets/icons/offers/hyperlink-icon.svg" class='w-4 h-4'
                                    alt="hyperlink-icon" />
                            </div>
                            <div *ngIf="hyperLinkPopup"
                                class='w-[200px] flex flex-col items-start gap-1 absolute p-2 bg-white top-[35px] left-1 border border-solid border-[#dadada] shadow-sm'>
                                <span class='text-[#a5a5a5] text-xs'>URL</span>
                                <input class='w-full border border-solid border-[#dadada] h-8 outline-none text-sm px-2'
                                    placeholder='https://' [(ngModel)]="hyperLink.url"
                                    [ngModelOptions]="{standalone: true}" />
                                <span class='text-[#a5a5a5] text-xs'>Text</span>
                                <input class='w-full border border-solid border-[#dadada] h-8 outline-none text-sm px-2'
                                    [(ngModel)]="hyperLink.label" [ngModelOptions]="{standalone: true}" />
                                <div class='w-full flex gap-2 mt-1'>
                                    <button type='button'
                                        class='w-[70px] flex justify-center items-center text-sm bg-[#2a6af6] outline-none border-none rounded box-border cursor-pointer h-[34px] min-w-[34px] px-2 font-Inter text-white'
                                        (click)="insertHyperLink()">Insert</button>
                                    <button type='button'
                                        class='w-[70px] flex justify-center items-center text-sm outline-none border border-solid border-[#d0d5dd] rounded box-border cursor-pointer h-[34px] min-w-[34px] px-2 font-Inter'
                                        (click)="cancelPopup()">Cancel</button>
                                </div>
                            </div>
                        </div>
                        <p #editableP contenteditable='true' [innerHTML]="job_comments_HTML" (paste)="onPaste($event)"
                            (input)="handlePInput($event)"
                            class='w-full border-none text-base font-Inter outline-none scroll-thin-style px-[14px] pb-[10px] h-[70px] overflow-x-hidden overflow-y-auto max-h-[70px]'>
                        </p>
                    </div>
                </div>
            </div>
            <div class='col-span-12'>
                <div class='w-full flex flex-col items-start gap-y-[6px]'>
                    <div class='w-full flex justify-start'>
                        <span class='text-[#344054] font-Inter text-sm font-medium'>Charging Comments</span>
                    </div>
                    <div class='w-full'>
                        <textarea
                            class='w-full  border border-[#D0D5DD] border-solid text-base font-Inter px-[14px] py-[10px] rounded-lg outline-none scroll-thin-style'
                            formControlName='charging_comments'></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class='w-full flex items-center gap-2'>
            <button class='custom-outlined-btn' type='button' (click)="closeModal()">Cancel</button>
            <button class='custom-solid-btn' type='submit'>Publish</button>
        </div>
    </form>
</ng-template>
<ng-template #assign_past_confirm_template>
    <div class='w-[400px] flex flex-col items-center box-border relative gap-y-8'>
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='w-full flex flex-col gap-y-4'>
            <div class='w-full flex justify-center items-center'>
                <img src="/assets/icons/offers/success.svg" alt="success-icon" />
            </div>
            <div class='w-full flex flex-col gap-y-[10px]'>
                <div class='w-full flex justify-center text-center font-Inter text-lg font-semibold leading-7'>
                    Assign
                    the job to {{assigneeDetail.assignee.name}} {{assigneeDetail.assignee.lname}}?</div>
                <div class='w-full flex flex-col items-center box-border py-[10px] gap-y-2 rounded-[10px] bg-[#F2F5FB]'>
                    <p class='text-sm font-Inter text-center font-normal text-[#475467]'>The start time for this
                        Job-Shift Offer occurs in the past. Would you like the start time to be updated to the current
                        time?</p>
                </div>
            </div>
        </div>
        <div class='flex items-center gap-2'>
            <button class='custom-outlined-btn whitespace-nowrap' (click)="keepOriginal()">Keep Original</button>
            <button class='custom-solid-btn' (click)="updateStart()">Update Start</button>
        </div>
    </div>
</ng-template>
<ng-template #notif_template>
    <div class='w-[400px] flex flex-col items-center box-border relative gap-y-8'>
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='w-full flex flex-col gap-y-4'>
            <div class='w-full flex justify-center items-center'>
                <img src="/assets/icons/offers/success.svg" alt="success-icon" *ngIf="modalStatus==='DELETED_NOTIF'" />
                <img src="/assets/icons/offers/failed.svg" alt="failed-icon"
                    *ngIf="modalStatus==='ASSIGN_FAIL_NOTIF'" />
            </div>
            <div class='w-full flex flex-col gap-y-[10px]'>
                <div class='w-full flex justify-center text-center font-Inter text-lg font-semibold leading-7'>
                    <ng-container *ngIf="modalStatus==='DELETED_NOTIF'">
                        Deleted!
                    </ng-container>
                    <ng-container *ngIf="modalStatus==='ASSIGN_FAIL_NOTIF'">
                        Job-Shift Offer could not be assigned
                    </ng-container>
                </div>
                <div class='w-full text-sm font-Inter text-center font-normal text-[#475467]'>
                    <ng-container *ngIf="modalStatus==='DELETED_NOTIF'">
                        The offer was successfully deleted.
                    </ng-container>
                    <ng-container *ngIf="modalStatus==='ASSIGN_FAIL_NOTIF'">
                        {{replaceEmplyee(failedNotifMsg,'driver')}}
                    </ng-container>
                </div>
            </div>
        </div>
        <button
            class='w-full flex box-border px-[18px] py-[10px] justify-center items-center rounded-lg font-Inter text-base font-semibold outline-none border border-solid text-white'
            (click)="closeModal()" [ngClass]="{'bg-[#CD4244] border-[#CD4244]':modalStatus==='ASSIGN_FAIL_NOTIF',
                'bg-[#2a6af6] border-[#2a6af6]':modalStatus==='DELETED_NOTIF'}">Close</button>
    </div>
</ng-template>



<app-loader-v2 [loading]="loading"></app-loader-v2>
