import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { buildParams, IndexQuery } from 'src/app/model/IndexQuery';
import { apiUrls } from 'src/app/settings/settings';
import { EpEncryptionService } from '../ep-encryption.service';
import {
  InterestedDriver,
  JobShiftOfferResponse,
} from 'src/app/model/custm/JobShiftOffer';
import * as moment from 'moment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobShiftOfferAPI {
  constructor(
    private http: HttpClient,
    private epEncryptionService: EpEncryptionService
  ) {}

  public store(data: JobShiftOfferResponse) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}shift-offer/store`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  //
  public index(query: IndexQuery) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}shift/pod${buildParams(query)}`
    );
  }

  public getAdminOfferdata(
    query: IndexQuery,
    startDate: string,
    endDate: string,
    state: string[],
    department: string[],
    vehicle: string[],
    assigned: boolean,
    orderArr: string[],
    isRefreshWholeData: boolean
  ) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}shift-offer/admin-offers${buildParams(query)}`,
      this.epEncryptionService.sendPayload({
        startDate,
        endDate,
        state,
        department,
        vehicle,
        assigned,
        orderArr,
        isRefreshWholeData,
      })
    );
  }


  public machshipSync() {
    return this.http.get<CodedResponseModel>(
        `${apiUrls.apiUrl}manual-machship-data-sync`
    );
  }

  public assignFlexiJobActive(flexi_job_header_id: number, driver_id: number, vehicle_type_id: string) {

    return this.http.post<CodedResponseModel>(
        `${apiUrls.apiUrl}assign-flexi-job-active`,
          {
            flexi_job_header_id,
            driver_id,
            vehicle_type_id
          }
    );
  }


  public getDrivers(): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(
        `${apiUrls.apiUrl}timesheet/drivers`
    );
  }

  // public getflexijobAssigned(
  //   query: IndexQuery,
  //   startDate: string,
  //   endDate: string,
  //   assigned: boolean,
  //   orderArr: string[],

  //   isRefreshWholeData: boolean

  // ) {
  //   return this.http.post<CodedResponseModel>(
  //     `${apiUrls.apistagingUrl}get-flexi-job-unassigned${buildParams(query)}`,
  //     this.epEncryptionService.sendPayload({
  //       startDate,
  //       endDate,
  //       assigned,
  //       orderArr,
  //       isRefreshWholeData,

  //     })
  //   );
  // }


    public getFlexiJobAssigned(
        query: IndexQuery,
        startDate: string,
        endDate: string,
        state: string[],
        department: string[],
        vehicle: string[],
        orderArr: string[],
        isRefreshWholeData: boolean,
        status: string[] = [],
    ) {
        const formData = new FormData();
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        state.forEach((s, index) => formData.append(`state[${index}]`, s));
        department.forEach((d, index) => formData.append(`department[${index}]`, d));
        vehicle.forEach((v, index) => formData.append(`vehicle[${index}]`, v));
        orderArr.forEach((o, index) => formData.append(`orderArr[${index}]`, o));
        formData.append('isRefreshWholeData', isRefreshWholeData.toString());
        formData.append('status_ids', status.toString());
        return this.http.post<CodedResponseModel>(
            `${apiUrls.apiUrl}get-flexi-job-assigned${buildParams(query)}`,
            formData
        );
    }

    public getFlexiJobunAssigned(
        query: IndexQuery,
        startDate: string,
        endDate: string,
        state: string[],
        department: string[],
        vehicle: string[],
        orderArr: string[],
        isRefreshWholeData: boolean
    ) {
        const formData = new FormData();
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        state.forEach((s, index) => formData.append(`state[${index}]`, s));
        department.forEach((d, index) => formData.append(`department[${index}]`, d));
        vehicle.forEach((v, index) => formData.append(`vehicle[${index}]`, v));
        orderArr.forEach((o, index) => formData.append(`orderArr[${index}]`, o));
        formData.append('isRefreshWholeData', isRefreshWholeData.toString());

        return this.http.post<CodedResponseModel>(
            `${apiUrls.apiUrl}get-flexi-job-unassigned${buildParams(query)}`,
            formData
        );
    }

    public getEligibleDriver(flexi_job_header_id?: number) {
      return this.http.get<CodedResponseModel>(
        `${apiUrls.apiUrl}get-eligible-drivers/${flexi_job_header_id}`
      );
    }

    public downloadSignature(flexi_job_header_id?: number) {
      return this.http.get<CodedResponseModel>(
        `${apiUrls.apiUrl}get-eligible-drivers/${flexi_job_header_id}`
      );
    }

    public sendPushNotification(manifest_id: any) {
      const formData = new FormData();
      formData.append('manifest_id', manifest_id);
      return this.http.post<CodedResponseModel>(
          `${apiUrls.apiUrl}send-push-notifications`,
          formData
      );
    }


    public getStates() {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}shift-offer/state`
    );
  }

  public getDepartments(state?: string) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}shift-offer/department?state=${state}`
    );
  }

  public getVehicles(state?: string, department?: string) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}shift-offer/vehicle?state=${state}&department=${department}`
    );
  }

  public getUsedDepartments() {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}shift-offer/used-departments`
    );
  }

  // public getFlexiDepartments(state?: string) {
  //   return this.http.get<CodedResponseModel>(
  //     `${apiUrls.apiUrl}flexi-offer/department?state=${state}`
  //   );
  // }
  //
  // public getFlexiVehicles(state?: string, department?: string) {
  //   return this.http.get<CodedResponseModel>(
  //     `${apiUrls.apiUrl}flexi-offer/vehicle?state=${state}&department=${department}`
  //   );
  // }
  //
  // public getFlexiUsedDepartments() {
  //   return this.http.get<CodedResponseModel>(
  //     `${apiUrls.apiUrl}flexi-offer/used-departments`
  //   );
  // }

  public assignOffer(data: {
    offer_id: number;
    assignee: InterestedDriver;
    isUpdateStart: boolean;
    start_time_anywhere: string | null;
    start_time: string | null;
  }) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}shift-offer/assign-offer`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  public deleteOffer(id: number) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}shift-offer/delete-offer`,
      this.epEncryptionService.sendPayload({ id })
    );
  }
}
