import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss']
})
export class FormCheckboxComponent implements OnInit {

  @Input() label: string = 'First Name*';
  @Input() isLabel: boolean = false;
  @Input() classes: Array<string> = [];
  @Input() isDisabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() model: any;
  @Output() modelChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public change(newValue: any) {
    this.model = newValue;
    this.modelChange.emit(newValue);
  }

}
