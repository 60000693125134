import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() text: string = '';
  @Input() classes: Array<string> = [];
  @Input() active: boolean = false;
  @Input() icon: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isReversed: boolean = false;
  @Output() buttonClickEvent = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  public clickButton() {
    if(!this.isDisabled)
      this.buttonClickEvent.emit();
  }

}
