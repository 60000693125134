import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TableColumn } from 'src/app/core/table/table.component';
import { exportAsExcel } from 'src/app/export-excel/Export2Excel';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { DepartmentType } from 'src/app/model/custm/ProofOfDelivery';
import {
  ShiftSummary,
  SortedSummaryObj,
} from 'src/app/model/custm/ShiftSummary';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { SummaryAPI } from 'src/app/services/custm/summaries.service';

@Component({
  selector: 'app-summaries',
  templateUrl: './summaries.component.html',
  styleUrls: ['./summaries.component.scss'],
})
export class SummariesComponent implements OnInit {
  public loading: boolean = false;
  public data: ShiftSummary[] = [];
  public searchCooldown: any;

  public columnDef = columnDefinition;

  // ! added code

  public searchModel: string = '';
  public isExpanded = {
    location: false,
    department: false,
    drawer: false,
  };

  public toExport: boolean = false;

  public range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  public departmentSearch: string = '';

  public location_department_data: {
    location: DepartmentType[];
    department: DepartmentType[];
  } = {
    location: [],
    department: [],
  };

  filteredData: {
    location: { label: string; id: string }[];
    department: { label: string; id: string }[];
  } = {
    location: [],
    department: [],
  };

  public previousDates: {
    fromDate: Date;
    toDate: Date;
  } = {
    fromDate: new Date(),
    toDate: new Date(),
  };

  public fromDate: Date = new Date();
  public toDate: Date = new Date();
  public isSortable: boolean = true;
  public sortedColumn: { columnName: string } = {
    columnName: 'date',
  };

  public sortOrder: (keyof SortedSummaryObj)[] = [
    'road_tolls',
    'regional',
    'attempted_deliveries',
    'skipped',
    'rejections',
    'check_address',
    'cards_left',
    'pick_ups',
    'stops_loaded',
    'clock_out_time',
    'clock_in_time',
    'role',
    'department',
    'location',
    'driver',
    'date',
  ];

  public sortStringObj = {
    road_tolls: 'road_tolls',
    regional: 'regional',
    attempted_deliveries:
      'CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(data, "$.stops_loaded")),"0") AS UNSIGNED) - CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(data, "$.skipped")),"0") AS UNSIGNED)',
    skipped: 'CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(data, "$.skipped")),"0") AS UNSIGNED)',
    rejections:
      'CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(data, "$.rejections")),"0") AS UNSIGNED)',
    check_address:
      'CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(data, "$.check_address")),"0") AS UNSIGNED)',
    cards_left:
      'CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(data, "$.cards_left")),"0") AS UNSIGNED)',
    pick_ups: 'CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(data, "$.pick_up")),"0") AS UNSIGNED)',
    stops_loaded:
      'CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(data, "$.stops_loaded")),"0") AS UNSIGNED)',
    clock_out_time:
      '(SELECT end_time FROM job_history WHERE job_history.id = shift_summaries.shift_id LIMIT 1)',
    clock_in_time:
      '(SELECT start_time FROM job_history WHERE job_history.id = shift_summaries.shift_id LIMIT 1)',
    role: '(SELECT vehicle FROM job_history WHERE job_history.id = shift_summaries.shift_id LIMIT 1)',
    department:
      '(SELECT CONCAT(COALESCE(client,""), " - ", COALESCE(depot,"")) FROM job_history WHERE job_history.id = shift_summaries.shift_id LIMIT 1)',
    location:
      '(SELECT location FROM job_history WHERE job_history.id = shift_summaries.shift_id LIMIT 1)',
    driver:
      '(SELECT CONCAT(COALESCE(name,""), " ", COALESCE(lname,"")) FROM users WHERE users.id = shift_summaries.user_id LIMIT 1)',
    date: 'created_at',
  };

  public sortObj: SortedSummaryObj = {
    date: false,
    driver: true,
    location: true,
    department: true,
    role: true,
    clock_in_time: true,
    clock_out_time: true,
    stops_loaded: true,
    pick_ups: true,
    cards_left: true,
    check_address: true,
    rejections: true,
    skipped: true,
    attempted_deliveries: true,
    regional: true,
    road_tolls: true,
  };

  // !
  public query: IndexQuery = {
    page: 1,
    perPage: 15,
    sortBy: 'created_at',
    sortDir: 'desc',
    filters: {
      search: '',
    },
  };

  public total: number = 0;
  public filtered: number = 0;

  constructor(
    private summaryApi: SummaryAPI,
    private cdref: ChangeDetectorRef
  ) {
    this.fetchData();

    // ! added
    this.previousDates.fromDate = this.fromDate;
    this.previousDates.toDate = this.toDate;
    // !
  }

  ngOnInit(): void {
    // Get locations and departments data.
    this.summaryApi.getLocationsAndDepartments().subscribe((res: any) => {
      const { data } = res;

      const location = _.groupBy(data?.locationsAndDepartments, 'location');
      const department = data?.locationsAndDepartments as DepartmentType[];

      // Stores locations and departments to location_department_data variable.
      this.location_department_data.location = Object.keys(location).map(
        (item) =>
          ({
            location: item,
            isChecked: false,
            isVisible: true,
          } as DepartmentType)
      );
      this.location_department_data.department = department;

      // Update screen after store data to location_department_data.
      this.cdref.detectChanges();
    });
  }

  public fetchData() {
    // ! addded Set the start date buy 0 o'clock and end date to 23:59 of the dates.
    this.fromDate.setHours(0);
    this.fromDate.setMinutes(0);
    this.toDate.setHours(23);
    this.toDate.setMinutes(59);

    // Convert dates to utc format
    const utcFromDate = moment(this.fromDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const utcToDate = moment(this.toDate).utc().format('YYYY-MM-DD HH:mm:ss');
    // !.
    this.loading = true;

    const orderArr = this.sortOrder.map(
      (item) =>
        `${this.sortStringObj[item]} ${this.sortObj[item] ? 'ASC' : 'DESC'}`
    );

    this.summaryApi
      .getSummariesData(
        this.query,
        String(utcFromDate),
        String(utcToDate),
        this.filteredData.location.map((item) => item.label),
        this.filteredData.department.map((item) => item.label),
        orderArr
      )
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);

        this.data = res.data.map((s: any) => ShiftSummary.create(s));

        this.filtered = res.filtered;
        this.total = res.total;

        this.loading = false;
      });
  }

  //! added

  public exportToExcel() {
    this.toExport = true;
    // Make it loading status
    this.loading = true;

    // Set the start date buy 0 o'clock and end date to 23:59 of the dates.
    this.fromDate.setHours(0);
    this.fromDate.setMinutes(0);
    this.toDate.setHours(23);
    this.toDate.setMinutes(59);

    // Convert dates to utc format
    const utcFromDate = moment(this.fromDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const utcToDate = moment(this.toDate).utc().format('YYYY-MM-DD HH:mm:ss');

    const orderArr = this.sortOrder.map(
      (item) =>
        `${this.sortStringObj[item]} ${this.sortObj[item] ? 'ASC' : 'DESC'}`
    );

    this.summaryApi
      .getSummariesData(
        this.query,
        String(utcFromDate),
        String(utcToDate),
        this.filteredData.location.map((item) => item.label),
        this.filteredData.department.map((item) => item.label),
        orderArr,
        true
      )
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);

        // Get POD data to export.
        const data: ShiftSummary[] = res.data.map((s: any) =>
          ShiftSummary.create(s)
        );

        const exportData = [
          { v: 'Date' },
          { v: 'Driver' },
          { v: 'Location' },
          { v: 'Department' },
          { v: 'Role' },
          { v: 'Clock In Time' },
          { v: 'Clock Out Time' },
          { v: 'Stops Loaded' },
          { v: 'Pick Ups' },
          { v: 'Cards Left' },
          { v: 'Check Address' },
          { v: 'Rejections' },
          { v: 'Skipped' },
          { v: 'Attempted Deliveries' },
          { v: 'Regional?' },
          { v: 'Road Tolls?' },
          { v: 'Summary Photo' },
        ];
        const tempData = data.map((item) => [
          {
            v: String(item.createdAt),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(`${item.user?.name ?? ''} ${item.user?.lname ?? ''}`),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.location),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.department),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.role),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.clockIn),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.clockOut),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.stopLoaded),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.pickUp),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.cardLeft),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.checkAddress),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.rejections),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.skipped),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.attemptedDeliveries),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.regional),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.road_tolls),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.shiftPhoto.join('\n')),
            t: 's',
            s: { alignment: { wrapText: true } },
          },
        ]);
        // Export data to xls file.
        exportAsExcel(
          [exportData, ...tempData],
          summariesColumnWidths,
          'Summaries'
        );

        // Make the export status by false
        this.toExport = false;

        // Stop loading
        this.loading = false;
      });
  }

  // Remove inputs in search driver field
  public clearSearch() {
    this.query.filters!.search = '';
    this.query.page = 1;
    this.fetchData();
  }

  // Define action for fromdate change
  public handleFromDateChange(e: string) {
    const fromDate = new Date(e);
    const toDate = new Date(this.toDate);
    this.query.page = 1;

    // If the user select past date of todate for fromdate, it will automatically set the same date for todate with fromdate
    if (fromDate.getTime() > toDate.getTime()) this.toDate = fromDate;
    this.fetchData();
  }

  // Define action for fromdate change
  public handleToDateChange(e: string) {
    const fromDate = new Date(this.fromDate);
    const toDate = new Date(e);
    this.query.page = 1;

    // If the user select previous date of fromdate for todate, it will automatically set the same date for fromdate with todate
    if (fromDate.getTime() > toDate.getTime()) this.fromDate = toDate;
    this.fetchData();
  }

  // Returns formated time with DD/MM/YY format
  getFormattedTime(value: Date) {
    return moment(value).format('DD/MM/YY');
  }

  public handleVisivility(
    key: 'location' | 'department' | 'drawer',
    value: boolean | null = null
  ) {
    // If the user close filter window without apply, it will ignore what the user newly checked.
    if (key === 'drawer' && value === false) {
      this.location_department_data.location.forEach((item, itemIndex) => {
        this.location_department_data.location[itemIndex].isChecked =
          this.filteredData.location
            .map((item) => item.id)
            .includes(item.location);
      });
      this.location_department_data.department.forEach((item, itemIndex) => {
        this.location_department_data.department[itemIndex].isChecked =
          this.filteredData.department
            .map((item) => item.id)
            .includes(`${item.location}-${item.client}-${item.depot}`);
      });
    }

    // It does expand action for location, department in filter and filter modal.
    if (value !== null) {
      this.isExpanded[key] = value;
      return;
    }
    this.isExpanded[key] = !this.isExpanded[key];
  }

  // This function define actions when user click apply button in filter modal.
  public handleFilterApply() {
    this.isExpanded.drawer = false;
    this.query.page = 1;
    this.filteredData = { location: [], department: [] };
    const filteredLocations: string[] = [];

    // Stored filtered locations data.
    this.location_department_data.location.forEach((item) => {
      if (item.isChecked) {
        filteredLocations.push(item.location);
        this.filteredData.location.push({
          label: item.location,
          id: item.location,
        });
      }
    });

    // If the filtered locations data is not empty, it will also fiter departments data which are related to filtered locations.
    if (filteredLocations.length)
      this.location_department_data.department =
        this.location_department_data.department.map((item) => ({
          ...item,
          isChecked: filteredLocations.includes(item.location)
            ? item.isChecked
            : false,
        }));

    // Store checked departments data.
    this.location_department_data.department.forEach((item) => {
      if (item.isChecked) {
        this.filteredData.department.push({
          label: `${item.client} - ${item.depot}`,
          id: `${item.location}-${item.client}-${item.depot}`,
        });
      }
    });

    // Filter data based on selected locations and departments
    this.fetchData();
  }

  // Check data and returns its boolean value
  public isChecked(value: boolean | undefined | null) {
    return Boolean(value);
  }

  // Difine actions for user inputs in Quick serach in filter modal.
  public searchDepartment(value: string) {
    this.location_department_data.department.forEach((item, itemIndex) => {
      if (
        `${item.client} - ${item.depot}`
          .toLowerCase()
          .includes(value.toLowerCase())
      ) {
        this.location_department_data.department[itemIndex].isVisible = true;
      } else {
        this.location_department_data.department[itemIndex].isVisible = false;
      }
    });
  }

  // Returns departments data by filtering based on selected locations and search keyword.
  public filterDepartments(arr: DepartmentType[]) {
    const checkedLocations = this.location_department_data.location.filter(
      (item) => item.isChecked
    );

    if (checkedLocations.length)
      return arr.filter(
        (item) =>
          (item?.isVisible === undefined || item?.isVisible === true) &&
          checkedLocations
            .map((subItem) => subItem.location)
            .includes(item.location)
      );
    else
      return arr.filter(
        (item) => item?.isVisible === undefined || item?.isVisible === true
      );
  }

  // Define action for user inputs in Search Driver field.
  public searcherInput(value: string) {
    this.query.filters!.search = value;
    this.query.page = 1;

    // It fetech data after 0.5 seconds when user input stops
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.fetchData();
    }, 500);
  }

  // Define action for when user remove filters for selected locations and departments
  public handleRemoveFilter(
    key: 'location' | 'department',
    value: { label: string; id: string }
  ) {
    this.query.page = 1;
    this.filteredData[key] = this.filteredData[key].filter(
      (item) => item.id !== value.id
    );

    const uncheckedIndex = this.location_department_data[key].findIndex(
      (item) =>
        key === 'location'
          ? item.location === value.id
          : `${item.location}-${item.client}-${item.depot}` === value.id
    );
    this.location_department_data[key][uncheckedIndex].isChecked = false;
    this.fetchData();
  }

  // Remove all filters including search keyword in Search Driver field.
  public handleClearAllFilters() {
    this.query.page = 1;
    this.filteredData = { location: [], department: [] };
    this.query.filters!.search = '';
    this.location_department_data.location =
      this.location_department_data.location.map((item) => ({
        ...item,
        isChecked: false,
      }));
    this.location_department_data.department =
      this.location_department_data.department.map((item) => ({
        ...item,
        isChecked: false,
      }));

    this.fetchData();
  }

  // Clear all checks from filter modal
  public handleClearAllChecked() {
    this.location_department_data.location =
      this.location_department_data.location.map((item) => ({
        ...item,
        isChecked: false,
      }));
    this.location_department_data.department =
      this.location_department_data.department.map((item) => ({
        ...item,
        isChecked: false,
      }));
  }

  // Set isChecked value based on user control in filter modal for location and department checkboxes.
  public setFilters(
    checked: boolean,
    key: 'location' | 'department',
    value: DepartmentType
  ) {
    const selectedIndex =
      key === 'location'
        ? this.location_department_data.location.findIndex(
            (item) => item.location === value.location
          )
        : this.location_department_data.department.findIndex(
            (item) =>
              item.location === value.location &&
              item.depot === value.depot &&
              item.client === value.client
          );
    this.location_department_data[key][selectedIndex].isChecked = checked;
  }

  public handleSort() {
    this.sortObj[this.sortedColumn.columnName as keyof SortedSummaryObj] =
      !this.sortObj[this.sortedColumn.columnName as keyof SortedSummaryObj];

    const index = this.sortOrder.findIndex(
      (item) => item === this.sortedColumn.columnName
    );
    this.sortOrder = [
      ...this.sortOrder.slice(0, index),
      ...this.sortOrder.slice(index + 1),
    ];
    this.sortOrder.push(this.sortedColumn.columnName as keyof SortedSummaryObj);
    // this.reset();
    this.query.page = 1;
    // this.isRefreshWholeData = true;
    this.fetchData();
  }

  //!

  // ! origin code

  // public searcherInput() {
  //   if (this.searchCooldown) clearTimeout(this.searchCooldown);
  //   this.searchCooldown = setTimeout(() => {
  //     this.fetchData();
  //   }, 500);
  // }

  // public export() {
  //   const dialogRef = this.dialog.open(SummaryExportComponent, {
  //     width: '60%',
  //     maxWidth: '600px',
  //     disableClose: true,
  //   });

  //   dialogRef.afterClosed().subscribe((res) => {
  //     console.log('closed');
  //   });
}

const columnDefinition: TableColumn[] = [
  // { label: 'ID', slug: 'id', sortable: true, filterType: 'number' },
  {
    label: 'Date',
    slug: 'createdAt',
    sortable: true,
    keyValue: 'date',
    accessor: (i: ShiftSummary) => i.createdAt,
  },
  {
    label: 'Driver',
    slug: 'user',
    sortable: true,
    keyValue: 'driver',
    accent: true,
    accessor: (i: ShiftSummary) => i.user?.fullName,
  },
  {
    label: 'Location',
    slug: 'location',
    sortable: true,
    keyValue: 'location',
    accessor: (i: ShiftSummary) => i.location,
  },
  {
    label: 'Department',
    slug: 'department',
    sortable: true,
    keyValue: 'department',
    accessor: (i: ShiftSummary) => i.department,
  },
  {
    label: 'Role',
    slug: 'role',
    sortable: true,
    keyValue: 'role',
    accessor: (i: ShiftSummary) => i.role,
  },
  {
    label: 'Clock In Time',
    slug: 'role',
    sortable: true,
    keyValue: 'clock_in_time',
    accessor: (i: ShiftSummary) => i.clockIn,
  },
  {
    label: 'Clock Out Time',
    slug: 'role',
    sortable: true,
    keyValue: 'clock_out_time',
    accessor: (i: ShiftSummary) => i.clockOut,
  },
  {
    label: 'Stops Loaded',
    slug: 'stopLoaded',
    sortable: true,
    keyValue: 'stops_loaded',
  },
  {
    label: 'Pick Ups',
    slug: 'pickUp',
    sortable: true,
    keyValue: 'pick_ups',
  },
  {
    label: 'Cards Left',
    slug: 'cardLeft',
    sortable: true,
    keyValue: 'cards_left',
  },
  {
    label: 'Check Address',
    slug: 'checkAddress',
    sortable: true,
    keyValue: 'check_address',
  },
  {
    label: 'Rejections',
    slug: 'rejections',
    sortable: true,
    keyValue: 'rejections',
  },
  {
    label: 'Skipped',
    slug: 'skipped',
    sortable: true,
    keyValue: 'skipped',
  },
  {
    label: 'Attempted Deliveries',
    slug: 'attemptedDeliveries',
    sortable: true,
    keyValue: 'attempted_deliveries',
  },
  {
    label: 'Regional?',
    slug: 'regional',
    sortable: true,
    keyValue: 'regional',
  },
  {
    label: 'Road Tolls?',
    slug: 'road_tolls',
    sortable: true,
    keyValue: 'road_tolls',
  },
  {
    label: 'Summary Photo',
    slug: 'shiftPhoto',
    sortable: false,
  },
  // {
  //   label: 'Data',
  //   slug: 'data',
  //   sortable: false,
  //   dataType: 'actions',
  //   actions: [
  //     {
  //       label: 'Show Data',
  //       action: (i: ShiftSummary) => {
  //         this.dialog.open(JsonDataComponent, { data: i.data });
  //       },
  //       condition: (i: ShiftSummary) => i.data,
  //     },
  //   ],
  // },
];

const summariesColumnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 35 },
  { wch: 15 },
  { wch: 35 },
  { wch: 35 },
  { wch: 10 },
  { wch: 10 },
  { wch: 10 },
  { wch: 10 },
  { wch: 10 },
  { wch: 10 },
  { wch: 10 },
  { wch: 10 },
  { wch: 10 },
  { wch: 35 },
];
