export interface shift_interface {
    id: number;
    user_id: number;
    humanforce_id: string;
    roster_id: string;
    route_id?: number;
    depot: string;
    location: string;
    client: string;
    vehicle: string;
    start_time: Date;
    end_time: Date;
}

export class Shift {
    public id: number = 0;
    public userId: number = 0;
    public humanforceId: string = '';
    public rosterId: string = '';
    public depot: string = '';
    public location: string = '';
    public client: string = '';
    public vehicle: string = '';
    public startTime: Date = new Date();
    public endTime: Date = new Date();

    public static create(data: shift_interface) {
        return (new this).map(data);
    }

    public map(data: shift_interface) {
        this.id = data.id;
        this.userId = data.user_id;
        this.humanforceId = data.humanforce_id;
        this.rosterId = data.roster_id ?? '';
        this.depot = data.depot;
        this.location = data.location;
        this.client = data.client;
        this.vehicle = data.vehicle;
        this.startTime = new Date(data?.start_time ?? "");
        this.endTime = new Date(data?.end_time ?? "");
        return this;
    }
}