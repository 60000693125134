import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './services/guards/auth.guard';
import { UsersComponent } from './pages/users/users.component';
import { UserFormComponent } from './pages/users/user-form/user-form.component';
import { DepotsComponent } from './pages/depots/depots.component';
import { DepotFormComponent } from './pages/depots/depot-form/depot-form.component';
import { PartialStateService } from './services/partial-state.service';
import { EmailsComponent } from './pages/emails/emails.component';
import { EmailFormComponent } from './pages/emails/email-form/email-form.component';
import { LogsComponent } from './pages/logs/logs.component';
import { PostsComponent } from './pages/posts/posts.component';
import { PostFormComponent } from './pages/posts/post-form/post-form.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SummariesComponent } from './pages/summaries/summaries.component';
import { ProofOfDeliveryComponent } from './pages/proof-of-delivery/proof-of-delivery.component';
import { ShiftStatusesComponent } from './pages/shift-statuses/shift-statuses.component';
import { JobShiftOfferComponent } from './pages/job-shift-offer/job-shift-offer.component';
import { FlexiJobOfferComponent } from './pages/job-shift-offer/flexi-job-offer/flexi-job-offer.component';

import { ClientComponent } from './pages/client/client.component';
import { ClientFormComponent } from './pages/client/client-form/client-form.component';
import { TimesheetComponent } from './pages/timesheet/timesheet.component';
import { LocationDataComponent } from './pages/location-data/location-data.component';
import { EditLocationsComponent } from './pages/settings/edit-locations/edit-locations.component';
import { EditDepartmentsComponent } from './pages/settings/edit-departments/edit-departments.component';
import { EditRolesComponent } from './pages/settings/edit-roles/edit-roles.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { EditAccessComponent } from './pages/settings/edit-access/edit-access.component';
import { EditInventoryComponent } from './pages/settings/edit-inventory/edit-inventory.component'
import { ClientFormViewComponent } from './pages/client/client-form-view/client-form-view.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [PartialStateService],
    runGuardsAndResolvers: 'always',
    children: [
      { path: 'login', component: LoginComponent },

      { path: 'forgotPassword', component: ForgotPasswordComponent },
      { path: 'resetPassword/:token', component: ResetPasswordComponent },

      {
        path: '',
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['blocked'],
          },
        },
        runGuardsAndResolvers: 'always',
        children: [
          { path: '', component: HomeComponent, data: { routeSlug: 'home' } },

          { path: 'account-settings', component: ProfileEditComponent },

          {
            path: '',
            data: { routeSlug: 'users' },
            children: [
              { path: 'users', component: UsersComponent },
              { path: 'users/new', component: UserFormComponent },
              { path: 'users/:id', component: UserFormComponent },
            ],
          },

          {
            path: '',
            data: { routeSlug: 'clients' },
            children: [
              { path: 'clients', component: ClientComponent },
              { path: 'clients/new', component: ClientFormComponent },
              { path: 'clients/:id', component: ClientFormComponent },
              { path: 'clients-view/:id', component: ClientFormViewComponent },
            ],
          },

          {
            path: '',
            data: { routeSlug: 'emails' },
            children: [
              { path: 'emails', component: EmailsComponent },
              { path: 'emails/new', component: EmailFormComponent },
              { path: 'emails/:id', component: EmailFormComponent },
            ],
          },

          {
            path: '',
            data: { routeSlug: 'depots' },
            children: [
              { path: 'depots', component: DepotsComponent },
              { path: 'depots/new', component: DepotFormComponent },
              { path: 'depots/:id', component: DepotFormComponent },
            ],
          },

          {
            path: '',
            data: {
              routeSlug: 'location-data',
            },
            children: [
              {
                path: 'location-data',
                component: LocationDataComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['full:location'],
                  },
                },
              },
              {
                path: 'location-data/:id',
                component: LocationDataComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['full:location'],
                  },
                },
              },
            ],
          },

          {
            path: '',
            data: { routeSlug: 'posts' },
            children: [
              { path: 'posts', component: PostsComponent },
              { path: 'posts/new', component: PostFormComponent },
              { path: 'posts/:id', component: PostFormComponent },
            ],
          },

          {
            path: '',
            data: { routeSlug: 'logs' },
            children: [{ path: 'logs', component: LogsComponent }],
          },

          {
            path: '',
            data: { routeSlug: 'shifts' },
            children: [{ path: 'shifts', component: ShiftStatusesComponent }],
          },

          {
            path: '',
            data: { routeSlug: 'summaries' },
            children: [
              {
                path: 'summaries',
                component: SummariesComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['full:summaries'],
                  },
                },
              },
            ],
          },

          {
            path: '',
            data: { routeSlug: 'offers' },
            children: [
              {
                path: 'offers',
                component: JobShiftOfferComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['full:offers'],
                  },
                },
              },
              {
                path: 'offers/flexijoboffers',
                component: FlexiJobOfferComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['full:offers'],
                  },
                },
              },
            ],
          },

          {
            path: '',
            data: { routeSlug: 'pod' },
            children: [
              {
                path: 'pod',
                component: ProofOfDeliveryComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['full:pod'],
                  },
                },
              },
            ],
          },

          {
            path: '',
            data: { routeSlug: 'timesheets' },
            children: [{ path: 'timesheets', component: TimesheetComponent }],
          },

          {
            path: '',
            data: { routeSlug: 'settings' },
            children: [
              { path: 'settings', component: SettingsComponent },
              {
                path: 'settings/edit-locations',
                component: EditLocationsComponent,
              },
              {
                path: 'settings/edit-departments',
                component: EditDepartmentsComponent,
              },
              { path: 'settings/edit-roles', component: EditRolesComponent },
              { path: 'settings/edit-inventory', component: EditInventoryComponent },
              { path: 'settings/edit-access', component: EditAccessComponent },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
