import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShiftState, ShiftStatus, ShiftStatusResponse} from '../../model/custm/ShiftStatus';
import {SelectOption} from "../../core/select/select.component";
import {TableColumn} from "../../core/table/table.component";
import {ShiftStatusesAPI} from "../../services/custm/statuses.service";
import {CodedResponseModel} from "../../model/CodedResponseModel";

interface ShiftQuery {
    dayOffset: number;
    statusFilter?: ShiftState;
}

@Component({
    selector: 'app-shift-statuses',
    templateUrl: './shift-statuses.component.html',
    styleUrls: ['./shift-statuses.component.scss']
})
export class ShiftStatusesComponent implements OnInit, OnDestroy {
    public loading = false;

    public data: ShiftStatus[] = [];

    public counters = {
        accepted: 0,
        rejected: 0,
        no_response: 0,
    };

    public dayOptions: SelectOption[] = [
        { label: 'Today', value: 0 },
        { label: 'Following day', value: 1 }
    ];

    public tableDef: TableColumn[] = [
        { label: 'Driver', slug: 'driver' },
        { label: 'Contact no.', slug: 'contact' },
        { label: 'Location', slug: 'location' },
        { label: 'Rostered dept.', slug: 'department' },
        { label: 'Status', slug: 'status', htmlData: true, accessor: (i: ShiftStatus) => {
            const values = (() => {
                switch (i.status){
                    case ShiftState.Accepted:
                        return { class: 'green', label: 'Accepted' };
                    case ShiftState.Rejected:
                        return { class: 'red', label: 'Rejected' };
                    case ShiftState.Indeterminate:
                        return { class: 'blue', label: 'No response' };
                } })();
            return `<div class="counter ${values.class}">${values.label}</div>`;
        }, headerDropdown: [
            { label: '<div class="dot green"></div>Accepted', action: () => { this.setStatusFilter(ShiftState.Accepted); } },
            { label: '<div class="dot red"></div>Rejected', action: () => { this.setStatusFilter(ShiftState.Rejected); } },
            { label: '<div class="dot blue"></div>No response', action: () => { this.setStatusFilter(ShiftState.Indeterminate); } },
            { label: '<div class="dot grey"></div>Clear sorting', action: () => { this.setStatusFilter(undefined); } }
        ]}
    ];

    public query: ShiftQuery = {
        dayOffset: 0,
    };

    private autoRefresher: any;

    constructor(
        private statusApi: ShiftStatusesAPI
    ) {}

    ngOnInit(): void {
        this.fetchData();
        this.autoRefresher = setInterval(() => this.fetchData(true), 10000);
    }

    ngOnDestroy(): void {
        clearInterval(this.autoRefresher);
    }

    public fetchData(quiet?: boolean): void{
        if (!quiet) this.loading = true;
        this.statusApi.index(this.query.dayOffset, this.query.statusFilter).subscribe(r => {
            const res = CodedResponseModel.decode(r);

            this.data = res.map((s: ShiftStatusResponse) => ShiftStatus.create(s));
            this.tallyShifts();

            if (!quiet) this.loading = false;
        });
    }

    public setStatusFilter(filter?: ShiftState): void {
        this.query.statusFilter = filter;
        this.fetchData();
    }

    public setDayOffset(offset: number): void {
        this.query.dayOffset = offset;
        this.fetchData();
    }

    public tallyShifts(): void {
        this.counters = {
            accepted: this.data.filter(s => s.status === ShiftState.Accepted).length,
            rejected: this.data.filter(s => s.status === ShiftState.Rejected).length,
            no_response: this.data.filter(s => s.status === ShiftState.Indeterminate).length
        };
    }
}
