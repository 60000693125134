import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss']
})
export class ButtonIconComponent implements OnInit {
  @Input() isText:boolean = false;
  @Input() text: string = '';
  @Input() icon: string = '';
  @Input() colorText: string = ''
  @Input() isReversed: boolean = false;
  @Input() disabled: boolean = false;
  @Output() clicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  public clickEvent(){
    if(!this.disabled)
      this.clicked.emit();
  }
}
