<div class='form-container'>
    <app-heading1 [headingTitle]="editMode?'Edit Template':'Add Template'"></app-heading1>

    <app-form-input [isDisabled]="true" *ngIf="editMode" labelText="Template ID" [model]="mail.id"></app-form-input>
    <app-form-input [(model)]="mail.event_slug" labelText="Slug"></app-form-input>
    <app-form-input [(model)]="mail.subject" labelText="Subject"></app-form-input>
    <app-form-input [(model)]="mail.mail_from" labelText="From"></app-form-input>
    <app-form-input [(model)]="mail.mail_to" labelText="To"></app-form-input>
    <app-form-textarea [(model)]="mail.template" labelText="Template code"></app-form-textarea>
    <div class='values'>
        <app-heading4 headingTitle="Main Values"></app-heading4>
        <app-button text="Add" (click)="mail.main_values.push('')"></app-button>
        <div class='value' *ngFor="let v of mail.main_values; let i = index; trackBy: trackByIndex">
            <app-form-input [(model)]="mail.main_values[i]" [isLabel]="false"></app-form-input>
            <app-link-button text="Remove" [isIcon]="false" (click)="mail.main_values.splice(i, 1)"></app-link-button>
        </div>
    </div>

    <app-button (buttonClickEvent)="save()" text="Save"></app-button>
    <app-link-button routerLink="/emails" [isIcon]="false" text="Back"></app-link-button>
</div>