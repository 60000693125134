import { Component, OnInit } from '@angular/core';
import { CodedResponseModel } from '../model/CodedResponseModel';
import { MenuComponent } from '../partials/menu/menu.component';
import { LoginService } from '../services/login.service';
import { NotificatorPartial } from '../partials/notificator/notificator.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email: string = '';
  sent: boolean = false;

  constructor(private loginApi: LoginService) {}
  ngOnInit() {
    MenuComponent.display(false);
  }

  public send() {
    this.loginApi.forgotPassword(this.email).subscribe(
      (res) => {
        let response = new CodedResponseModel();
        response.map(res);
        if (response.success) {
          NotificatorPartial.push({
            type: 'success',
            message: 'Will send you an email for reset password',
            timeout: 3000,
          });
          this.sent = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
