import * as moment from 'moment';
import { LoadingRatesType, PayratesType } from './Setting';

export const EXPORT_MODEL = [
  { v: 'ID' },
  { v: 'Date' },
  { v: 'Driver' },
  { v: 'Location' },
  { v: 'Department' },
  { v: 'Role' },
  { v: 'Job Start' },
  { v: 'In Time' },
  { v: 'Out Time' },
  { v: 'In Check' },
  { v: 'Out Check' },
  { v: 'Pay In' },
  { v: 'Pay Out' },
  { v: 'Gross Hours' },
  { v: 'Breaks (mins)' },
  { v: 'Net Hours' },
  { v: 'Attempted Deliveries' },
  { v: 'Attempted Deliveries Paid' },
  { v: 'Pickups' },
  { v: 'Summary' },
  { v: 'Shift Definition' },
  { v: 'Regional' },
  { v: 'Tolls' },
  { v: 'Status' },
  { v: 'Last Edit' },
  { v: 'Authorized By' },
  { v: 'Authorised' },
  { v: 'Gross Hours (Time decimal)'},
  { v: 'Breaks (Time decimal)'}
];

export const COLUMN_WIDTH_DEFS = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];

export const EXPORT_COLUMNS: (keyof Timesheet)[] = [
  'id',
  'Date',
  'Driver',
  'Location',
  'Department',
  'Role',
  'JobStart',
  'InTime',
  'OutTime',
  'InCheck',
  'OutCheck',
  'PayIn',
  'PayOut',
  'GrossHours',
  'Breaks',
  'NetHours',
  'AttemptedDeliveries',
  'AttemptedDeliveriesPaid',
  'Pickups',
  'Summary',
  'ShiftDefinition',
  'Regional',
  'Tolls',
  'Status',
  'LastEdit',
  'AuthorisedBy',
  'Authorised',
  'GrossHoursNumber',
  'BreaksNumber'
];

export interface Department2UploadType {
  ID: number;
  Department: string;
  isVisible: boolean;
  isChecked: boolean;
}

export interface BulkUpdateForamt {
  id: number;
  rowIndex: number;
  PayIn: string;
  PayOut: string;
  Breaks: number;
  AttemptedDeliveriesPaid: number;
  Pickups: number; //
  ShiftDefinition: string;
  Regional: string;
  Tolls: string;
  Authorised: boolean;
}

export type FilterType =
  | 'state'
  | 'department'
  | 'clockInCheck'
  | 'clockOutCheck'
  | 'actions'
  | 'regional'
  | 'tolls'
  | 'shiftDefinition'
  | 'paid'
  | 'checked';

export interface CommentsType {
  Roster: string;
  Timesheet: string;
  isRoster?: boolean;
  isTimesheet?: boolean;
}

export const TOLL_MATCHES: { [key: string]: string } = {
  no: 'ZZZ',
  'one-way': 'One-way',
  return: 'Return',
};

export const SHIFT_DEF_MATCHES: { [key: string]: string } = {
  sprint: 'Sprint',
  marathon: 'Marathon',
  flexi: 'FLEXI',
  'set run': 'Set Run',
};

export const TOLLS = ['No', 'One-way', 'Return'];
export const REGIONALS = ['Yes', 'No'];
export const STATUSES = ['Normal', 'Cancelled', 'Unpaid'];

export const COMPARISON_FIELDS = [
  'PayIn',
  'PayOut',
  'ShiftDefinition',
  'Breaks',
  'AttemptedDeliveriesPaid',
  'Pickups',
  'Regional',
  'Tolls',
  'Authorised',
];

export enum SHIFT_TYPE {
  Normal = 'Normal',
  Cancelled = 'Cancelled',
  Unpaid = 'Unpaid',
}

export enum SHIFT_DEFINITIONS {
  SPRINT = 'Sprint',
  MARATHON = 'Marathon',
  FLEXI = 'FLEXI',
  SET_RUN = 'Set Run',
}

export interface InvoiceRowType {
  ContactName: string;
  DriverName: string;
  InvoiceNumber: string;
  InvoiceDate: string;
  DueDate: string;
  Description: string;
  Quantity: number | string;
  UnitAmount: number | string;
  AccountCode: string;
  TaxType: string;
  Currency: string;
}
export interface DriverInvoiceADHoc {
  TrackingName: string;
  TrackingOption: string;
}

export interface ClientInvoiceADHoc {
  Reference: string;
  InventoryItemCode: string;
  TrackingName1: string;
  TrackingOption1: string;
  TrackingName2: string;
  TrackingOption2: string;
}

export type DriverInvoiceType = InvoiceRowType &
  DriverInvoiceADHoc &
  Partial<Timesheet>;

export type ClientInvoiceType = InvoiceRowType &
  ClientInvoiceADHoc &
  Partial<Timesheet>;

export const getInvoiceExportColumns = (
  type: 'Driver' | 'Client'
): (
  | keyof (InvoiceRowType & DriverInvoiceADHoc)
  | keyof (InvoiceRowType & ClientInvoiceADHoc)
)[] => {
  return type === 'Driver'
    ? [
        'ContactName',
        'DriverName',
        'InvoiceNumber',
        'InvoiceDate',
        'DueDate',
        'Description',
        'Quantity',
        'UnitAmount',
        'AccountCode',
        'TaxType',
        'Currency',
        'TrackingName',
        'TrackingOption',
      ]
    : [
        'ContactName',
        'InvoiceNumber',
        'Reference',
        'InvoiceDate',
        'DueDate',
        'InventoryItemCode',
        'Description',
        'Quantity',
        'UnitAmount',
        'AccountCode',
        'TaxType',
        'TrackingName1',
        'TrackingOption1',
        'TrackingName2',
        'TrackingOption2',
        'Currency',
      ];
};

export interface ShiftSummaryType {
  data: {
    stops_loaded: number;
    pick_up: number;
    cards_left: number;
    check_address: number;
    rejections: number;
    skipped: number;
  };
  id: number;
  regional: string;
  road_tolls: string;
  shift_photo: string;
}

export interface SelectedItemType {
  item: string;
  isSelected: boolean;
}

export interface TimesheetResponseType {
  id: number;
  User_ID: number;
  Routes: string[] | null;
  Shift_ID: number | null;
  Summary_ID: number | null;
  StartTime: string;
  Employee: string;
  Location: string;
  Department: string;
  Role: string;
  InTime: string | null;
  OutTime: string | null;
  InCheck: boolean;
  OutCheck: boolean;
  PayStartTime: string | null;
  PayEndTime: string | null;
  GrossMinutes: number;
  NetMinutes: number;
  GrossMinutes_Delta: number;
  NetMinutes_Delta: number;
  Breaks: number;
  Attempted_Deliveries_Paid: number;
  Shift_Definition: string;
  ShiftType: string;
  Roster_Comment: string;
  Timesheet_Comment: string;
  IsViewed: boolean;
  Authorised: boolean;
  authorised_by: { name: string; lname: string } | null;
  last_edit_by: { name: string; lname: string } | null;
  ClientProfile: any;
  tz: number;
  checked: boolean;
  Existing_User?: number | null;

  outlink: any;

  CON?: string;
  Payrates?: { default: PayratesType | null; overridden: PayratesType | null };

  Paid: boolean;
  invoiced: boolean;

  SummaryContainsSummary: boolean;
  Summary_Data: string | null;
  Regional: string | null;
  Tolls: string | null;
  Shift_Photo: string | null;
  AuthoriserName: string | null;
  LastEditorName: string | null;
}

export interface TimesheetSortObj {
  Date: boolean;
  Employee: boolean;
  Location: boolean;
  Department: boolean;
  Role: boolean;
  StartTime: boolean;
  InTime: boolean;
  OutTIme: boolean;
  InCheck: boolean;
  OutCheck: boolean;
  PayStartTime: boolean;
  PayEndTime: boolean;
  GrossMinutes: boolean;
  Breaks: boolean;
  NetMinutes: boolean;
  Attempted_Deliveries: boolean;
  Attempted_Deliveries_Paid: boolean;
  pick_up: boolean;
  Summary: boolean;
  Shift_Definition: boolean;
  regional: boolean;
  road_tolls: boolean;
  ShiftType: boolean;
  LastEditBy: boolean;
  AuthorisedBy: boolean;
  Paid: boolean;
  checked: boolean;
  invoiced: boolean;
}

export const getHoursMins = (mins: number | null, justHours = false) => {
  if (mins !== null && mins > 0) {
    const minutes = mins % 60;
    const hoursForMins = Math.round((minutes / 60) * 100);
    const hours = (mins - minutes) / 60;
    return justHours
      ? `${hours}${
          hoursForMins > 0
            ? `.${hoursForMins > 9 ? hoursForMins : '0' + hoursForMins}`
            : ''
        }h`
      : `${hours}h ${minutes > 9 ? minutes : `0${minutes}`}m`;
  } else return justHours ? '--h' : '--h --m';
};

export const getHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const decimalHours = (remainingMinutes / 60);
  return (hours + decimalHours).toFixed(2);
};

export function formatUSD(value: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export const getShiftDef = (shiftDef: SHIFT_DEFINITIONS) => {
  switch (shiftDef) {
    case SHIFT_DEFINITIONS.MARATHON:
      return 'dedi';
    case SHIFT_DEFINITIONS.SPRINT:
      return 'dedi';
    case SHIFT_DEFINITIONS.SET_RUN:
      return 'setRun';
    case SHIFT_DEFINITIONS.FLEXI:
      return 'flexi';
  }
};

export const getFLEXIQuantity = (inputString: string) => {
  const regex = /\d+/; // Matches one or more digits
  const match = inputString.match(regex);
  const target = Number(match?.[0] ?? '0');
  return target;
};

export type ClientLoadingRateType = (Partial<LoadingRatesType> & {
  from: string;
  to: string;
})[];

export type ClientChargingType = {
  role: string;
  rate: string;
  fuel: string;
  rate2?: string;
};

export type TimePairType = { from: string; to: string };
export type HolidayType = {
  _location: string[];
  type: 'National' | 'Local holiday';
  date: string;
};

export class Timesheet {
  id: number = 0;
  User_ID: number = -1;
  Shift_ID: number = -1;
  Summary_ID: number = -1;
  Date: string = '';
  Driver: string = '';
  Location: string = '';
  Department: string = '';
  Role: string = '';
  StartTime: string = '';
  JobStart: string = '';
  InTime: string = '';
  OutTime: string = '';
  InCheck: boolean = false;
  OutCheck: boolean = false;
  PayIn: string | null = null;
  PayOut: string | null = null;
  GrossHours: string = '';
  GrossHoursNumber: string = '';
  GrossMinutes: number = 0;
  GrossMinutes_Delta: number = 0;
  Breaks: number | null = null;
  BreaksNumber: number | null = null;
  NetMinutes: number = 0;
  NetMinutes_Delta: number = 0;
  NetHours: string = '';
  MarathonHours: number = 0;
  SprintHours: number = 0;
  Existing_User?: number | null = null;
  CON: string = '';
  Payrates?: { default: PayratesType | null; overridden: PayratesType | null };
  ClientDetail: any = {};
  Paid: boolean = false;
  invoiced: boolean = false;
  clocked_coords: any;

  AttemptedDeliveries: number = 0;
  Avaialble_Shift_Definitions: SelectedItemType[] = [];

  SummaryData: ShiftSummaryType['data'] = {
    stops_loaded: 0,
    pick_up: 0,
    cards_left: 0,
    check_address: 0,
    rejections: 0,
    skipped: 0,
  };
  tz: number = 0;
  AttemptedDeliveriesPaid: number = 0;
  Pickups: number = 0;
  Summary: string = '';
  ShiftDefinition: string = '';
  Regional: string = '';
  Tolls: string = '';
  Status: string = '';
  LastEdit: string = '';
  AuthorisedBy: string = '';
  GrossProfit: string = '';

  Comments: CommentsType | null = null;
  Viewed: boolean = false;
  checked: boolean = false;
  CommentExist: boolean = false;
  Routes: string[] = [];
  Authorised: boolean = false;
  IsDisabled: boolean = false;
  ClientLoadingRates: {
    dedi: ClientLoadingRateType | null;
    setRun: ClientLoadingRateType | null;
    flexi: ClientLoadingRateType | null;
  } = { dedi: null, setRun: null, flexi: null };
  ClientChargingRates: {
    dedi: ClientChargingType[] | null;
    setRun: ClientChargingType[] | null;
    flexi: ClientChargingType[] | null;
  } = {
    dedi: null,
    setRun: null,
    flexi: null,
  };

  public map(data: TimesheetResponseType, toExport = false, justHours = false) {
    const summaryData = data.Summary_Data
      ? JSON.parse(data.Summary_Data)
      : null;
    const regional = data.Regional;
    const road_tolls = data.Tolls;
    const shift_photo = JSON.parse(data.Shift_Photo ?? '[]');
    const {
      account_code = '',
      entity_name = '',
      invoice_code = '',
      invoice_prefix = '',
      payment_terms = '0',
    } = data.ClientProfile ?? {};
    const name = data.ClientProfile?.department?.name ?? '';

    this.clocked_coords = data?.outlink;

    this.id = data.id;
    this.User_ID = data.User_ID;
    this.Summary_ID = data?.Summary_ID ?? -1;
    this.Shift_ID = data?.Shift_ID ?? -1;
    this.Date = moment(data.StartTime).format('DD/MM/YYYY');
    this.Driver = data.Employee;
    this.Location = data.Location;
    this.Department = data.Department;
    this.Role = data.Role;
    this.StartTime = data.StartTime;
    this.JobStart = this.getTime(data.StartTime);
    this.InTime = this.getTime(data.InTime);
    this.OutTime = this.getTime(data.OutTime);
    this.InCheck = data.InCheck;
    this.OutCheck = data.OutCheck;
    this.PayIn = toExport
    ? this.getTime(data.PayStartTime)
    : this.getEditableTime(data.PayStartTime);
    this.PayOut = toExport
      ? this.getTime(data.PayEndTime)
      : this.getEditableTime(data.PayEndTime);
    this.GrossHours = getHoursMins(data.GrossMinutes);
    this.GrossMinutes = data.GrossMinutes;
    this.GrossMinutes_Delta = data.GrossMinutes_Delta;
    this.Breaks = data.Breaks;
    this.NetHours = getHoursMins(data.NetMinutes, justHours);
    this.NetMinutes = data.NetMinutes;
    this.NetMinutes_Delta = data.NetMinutes_Delta;

    this.AttemptedDeliveries = summaryData?.stops_loaded
      ? summaryData.stops_loaded - summaryData.skipped
      : 0;
    this.AttemptedDeliveriesPaid = data.Attempted_Deliveries_Paid ?? 0;
    this.Pickups = summaryData?.pick_up ?? 0;
    this.Summary = data.SummaryContainsSummary
      ? shift_photo.find((item: string) => item.includes('summary'))
      : '';
    this.Regional = regional ? (regional === 'ZZZ' ? 'No' : regional) : 'No';
    this.Tolls = road_tolls ? (road_tolls === 'ZZZ' ? 'No' : road_tolls) : 'No';
    this.SummaryData = summaryData || this.SummaryData;

    this.ShiftDefinition = data.Shift_Definition ?? '';
    this.Avaialble_Shift_Definitions = this.getShiftDefinition(
      data?.Shift_Definition ?? '',
      data.ClientProfile
    );

    this.tz = data.tz;

    this.Status = data.ShiftType;
    this.LastEdit = data.LastEditorName ?? '';
    this.AuthorisedBy = data.AuthoriserName ?? '';

    this.Comments = {
      Roster: data?.Roster_Comment ?? '',
      Timesheet: data?.Timesheet_Comment ?? '',
    };
    this.CommentExist =
      Boolean(data?.Roster_Comment) || Boolean(data?.Timesheet_Comment);
    this.Viewed = data.IsViewed;
    this.checked = !!data?.checked;
    this.Routes = (data.Routes ?? []).map(
      (route) => `https://route4me.com/route/${route}`
    );
    this.Authorised = data.Authorised;

    this.IsDisabled = this.editDisabled();
    this.Existing_User = data.Existing_User ?? null;
    this.CON = data.CON || '';
    this.Payrates = data.Payrates;
    this.ClientDetail = {
      account_code,
      invoice_code,
      name,
      entity_name,
      invoice_prefix,
      payment_terms,
    };
    this.Paid = data.Paid;
    this.invoiced = data.invoiced;
    return this;
  }

  public getEditableTime(time: string | null) {
    return time ? moment(time).format('HHmm') : null;
  }

  public editDisabled() {
    if (!Boolean(this.PayOut))
      if (this.InTime === '--:--')
        return !this.isEditable(
          `${this.Date} ${this.JobStart}`,
          'DD/MM/YYYY HH:mm',
          this.tz,
          2
        );
      else
        return !this.isEditable(
          `${this.Date} ${this.InTime}`,
          'DD/MM/YYYY HH:mm',
          this.tz,
          8
        );
    return false;
  }

  public isEditable(
    timeString: string,
    format: string,
    givenOffset: number,
    limit: number
  ) {
    const momentObj = moment(timeString, format);
    const currentTime = moment();
    momentObj
      .subtract(givenOffset - moment().utcOffset(), 'minutes')
      .add(limit, 'hours');
    // To edit timesheet row,  the calculated time (momentObj) must be earlier than currentTime.
    return momentObj.isBefore(currentTime);
  }

  public getShiftDefinition(
    currentShiftDefinition: string,
    clientProfile: any
  ): SelectedItemType[] {
    let shiftDefinitions: string[] = [];

    if (clientProfile?.vehicles?.length) {
      if (clientProfile?.dedi?.charging_dedi) {
        this.MarathonHours = Number(
          clientProfile?.dedi?.dedi_minimums?.marathon ?? '0'
        );
        this.SprintHours = Number(
          clientProfile?.dedi?.dedi_minimums?.sprint ?? '0'
        );
        this.ClientLoadingRates.dedi = clientProfile?.dedi?.dedi_terms;
        const dedi_product = clientProfile?.dedi?.dedi_product;
        this.ClientChargingRates.dedi = clientProfile.vehicles.map(
          (item: any) => {
            const found = dedi_product.find(
              (_item: any) => _item.role_id == item.id
            );
            return {
              ...(found ?? {
                rate: 0,
                fuel: 0,
              }),
              role: item.name,
            };
          }
        );
        shiftDefinitions = [...shiftDefinitions, 'Sprint', 'Marathon'];
      }

      if (clientProfile?.flexi?.charging_flexi) {
        this.ClientLoadingRates.flexi = clientProfile?.flexi?.flexi_terms;
        const flexi_product = clientProfile?.flexi?.flexi_product;
        this.ClientChargingRates.flexi = clientProfile.vehicles.map(
          (item: any) => {
            const found = flexi_product.find(
              (_item: any) => _item.role_id == item.id
            );
            return {
              ...(found ?? {
                rate: 0,
                rate2: 0,
                fuel: 0,
              }),
              role: item.name,
            };
          }
        );
        shiftDefinitions.push('FLEXI');
      }
      if (clientProfile?.setRun?.charging_setrun) {
        this.ClientLoadingRates.setRun = clientProfile?.setRun?.setRun_terms;
        const setRun_product = clientProfile?.setRun?.setRun_product;
        this.ClientChargingRates.setRun = clientProfile.vehicles.map(
          (item: any) => {
            const found = setRun_product.find(
              (_item: any) => _item.role_id == item.id
            );
            return {
              ...(found ?? {
                rate: 0,
                fuel: 0,
              }),
              role: item.name,
            };
          }
        );
        shiftDefinitions.push('Set Run');
      }
    }

    return [
      ...(this.Role.includes('FLEXI') ? ['FLEXI'] : shiftDefinitions),
      // 'Unpaid',
    ].map((item) => ({
      item,
      isSelected: item === currentShiftDefinition,
    }));
  }

  public getTime(time: string | null) {
    return time ? moment(time).format('HH:mm') : '--:--';
  }

  public getGrossProfit() {}

  public getPayingAmount4Driver() {
    let UnitAmount = 0;
    let Quantity = 0;
    const shiftDef = getShiftDef(this.ShiftDefinition as SHIFT_DEFINITIONS);
    if (this.Status === SHIFT_TYPE.Normal) {
      switch (shiftDef) {
        case 'dedi':
          Quantity = parseFloat(this.NetHours) || 0;
          UnitAmount = Number(
            this.Payrates?.overridden?.dedi_rate ??
              this.Payrates?.default?.dedi_rate ??
              0
          );
          break;
        case 'setRun':
          (Quantity = 1), (UnitAmount = 0);
          break;
        case 'flexi':
          Quantity = this.AttemptedDeliveriesPaid;
          UnitAmount = Number(
            this.Payrates?.overridden?.flexi_delivery ??
              this.Payrates?.default?.flexi_delivery ??
              0
          );
          if (this.Pickups > 0) Quantity = this.Pickups;
          UnitAmount = Number(
            this.Payrates?.overridden?.flexi_pickup ??
              this.Payrates?.default?.flexi_pickup ??
              0
          );
          break;

        default:
          break;
      }
    } else {
      switch (shiftDef) {
        case 'dedi':
          Quantity = parseFloat(this.NetHours) || 0;
          UnitAmount = Number(
            this.Payrates?.overridden?.dedi_rate ??
              this.Payrates?.default?.dedi_rate ??
              0
          );
          break;
        case 'setRun':
          Quantity = 1;
          UnitAmount = 0;
          break;
        case 'flexi':
          Quantity = getFLEXIQuantity(this.Role) / 2;
          UnitAmount = Number(
            this.Payrates?.overridden?.flexi_delivery ??
              this.Payrates?.default?.flexi_delivery ??
              0
          );
          break;

        default:
          break;
      }
    }
  }

  public getChargingAmount4Client() {}

  public static mapTimesheet(
    _data: any,
    toExport = false,
    justHours = false
  ): Timesheet {
    let timesheet = new Timesheet();
    timesheet.map(_data, toExport, justHours);
    return timesheet;
  }
}
