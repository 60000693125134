<div class='select-form-control'>
    <label>{{label}}</label>
    <div class='selector' id="sel-{{sId}}" [class.open]="showOptions" (click)="toggleOpen()">{{selLabel}}</div>
    <div class='option-list' id="opt-{{sId}}" *ngIf="showOptions" [@open]="isOpen">
        <li *ngFor="let o of options" (click)="selectOption(o)">{{o.label}}</li>
        <div class='loader' *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
<!-- <div class='click-bg' *ngIf="isOpen" (click)="bgClick($event)"></div> -->