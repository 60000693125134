import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { exportAsExcel } from 'src/app/export-excel/Export2Excel';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { MenuEntry } from 'src/app/partials/menu/menu.component';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { SettingsAPI } from 'src/app/services/custm/settings.service';
import { LoginService } from 'src/app/services/login.service';
import * as XLSX from 'xlsx-js-style';

export interface MappingType {
  data: string[][];
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public loading: boolean = false;
  public additionalWorktimeDeps: string[] = [];
  public specialWorkflowThreshold: number = 5;
  public geofenceTriggerRadius: number = 0;

  public fromDate: Date = new Date();
  public toDate: Date = new Date();
  public childRoutes: (MenuEntry & { permissions: string[] })[];

  constructor(
    private settingsApi: SettingsAPI,
    public authService: LoginService
  ) {
    this.childRoutes = [
      {
        label: 'Edit Locations',
        route: '/settings/edit-locations',
        permissions: ['full:settings','edit-locations:settings'],
      },
      {
        label: 'Edit Departments',
        route: '/settings/edit-departments',
        permissions: ['full:settings','edit-departments:settings'],
      },
      {
        label: 'Edit Roles',
        route: '/settings/edit-roles',
        permissions: ['full:settings','edit-roles:settings'],
      },
      {
        label: 'Edit Inventory Codes',
        route: '/settings/edit-inventory',
        permissions: ['full:settings'],
      },
      {
        label: 'Edit Access Levels',
        route: '/settings/edit-access',
        permissions: ['full:settings','uac:settings'],
      },
    ];
  }

  ngOnInit(): void {
    this.fetchData();
  }

  public fetchData() {
    this.settingsApi.getAll().subscribe((r) => {
      let res = CodedResponseModel.decode(r);
      this.additionalWorktimeDeps = res.extra_worktime_departments;
      this.specialWorkflowThreshold = res.special_workflow_threshold;
      this.geofenceTriggerRadius = res.geofence_trigger_radius;
    });
  }

  public save() {
    let data = {
      extra_worktime_departments: this.additionalWorktimeDeps,
      special_workflow_threshold: this.specialWorkflowThreshold,
      geofence_trigger_radius: this.geofenceTriggerRadius,
    };
    this.settingsApi.update(data).subscribe(
      (r) => {
        NotificatorPartial.push({
          type: 'success',
          message: 'Settings have been updated',
          timeout: 3000,
        });
      },
      (err) => {
        NotificatorPartial.push({
          type: 'error',
          message: 'An error has occurred when trying to update the settings',
          details: err.error.message,
        });
      }
    );
  }

  public trackByIndex(i: number) {
    return i;
  }

  // Define action for fromdate change
  public handleFromDateChange(e: string) {
    const fromDate = new Date(e);
    const toDate = new Date(this.toDate);

    // If the user select past date of todate for fromdate, it will automatically set the same date for todate with fromdate
    if (fromDate.getTime() > toDate.getTime()) this.toDate = fromDate;
  }

  // Define action for fromdate change
  public handleToDateChange(e: string) {
    const fromDate = new Date(this.fromDate);
    const toDate = new Date(e);

    // If the user select previous date of fromdate for todate, it will automatically set the same date for fromdate with todate
    if (fromDate.getTime() > toDate.getTime()) this.fromDate = toDate;
  }

  // Returns formated time with DD/MM/YY format
  getFormattedTime(value: Date) {
    return moment(value).format('DD/MM/YY');
  }

  getTrackingData() {
    // Make it loading status
    this.loading = true;

    // Set the start date buy 0 o'clock and end date to 23:59 of the dates.
    this.fromDate.setHours(0);
    this.fromDate.setMinutes(0);
    this.toDate.setHours(23);
    this.toDate.setMinutes(59);

    // Convert dates to utc format
    const utcFromDate = moment(this.fromDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const utcToDate = moment(this.toDate).utc().format('YYYY-MM-DD HH:mm:ss');

    // Get POD data by passing search keyword and filter options

    this.settingsApi
      .getTrackingData(String(utcFromDate), String(utcToDate))
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);

        if (!Boolean(res.data?.length)) {
          NotificatorPartial.push({
            type: 'warning',
            message: 'There are no data in the selected period',
            timeout: 3000,
          });
          this.loading = false;
          return;
        }
        const exportData = [
          { v: 'Name' },
          { v: 'Email' },
          { v: 'Latitude' },
          { v: 'Longitude' },
          { v: 'Timestamp' },
        ];

        const tempData = res.data.map((item: any) => [
          {
            v: String(item.name),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.email),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.lat),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.lng),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.timestamp),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
        ]);
        // Export data to xls file.
        const trackingColumnWidths = [
          { wch: 20 },
          { wch: 25 },
          { wch: 15 },
          { wch: 15 },
          { wch: 20 },
        ];

        exportAsExcel(
          [exportData, ...tempData],
          trackingColumnWidths,
          'Location Tracking'
        );

        this.loading = false;
      });
  }

  public handleFileInputChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    const validExtensions = ['.csv', '.xlsx'];

    if (file) {
      const fileName = file.name;
      const fileExt = fileName
        .substring(fileName.lastIndexOf('.'))
        .toLowerCase();
      if (!validExtensions.includes(fileExt)) {
        NotificatorPartial.push({
          type: 'error',
          message: 'File formation is incorrect.',
          timeout: 3000,
        });
        return false;
      }
    }

    this.loading = true;

    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Access the content of the first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: false,
      }) as MappingType['data'];

      if (
        !(excelData.length > 1) ||
        !(
          excelData[0][1] === 'Location' &&
          excelData[0][2] === 'Department' &&
          excelData[0][3] === 'Role'
        )
      ) {
        NotificatorPartial.push({
          type: 'error',
          message: 'Data formation is incorrect or empty.',
        });
        return false;
      }
      this.settingsApi.uploadMappingData(excelData.slice(1)).subscribe(
        (response) => {
          this.loading = false;
          NotificatorPartial.push({
            type: 'success',
            message: 'Settings have been updated',
            timeout: 3000,
          });
        },
        (err) => {
          this.loading = false;
          console.log(err);
          NotificatorPartial.push({
            type: 'error',
            message: 'Data upload failed. Please try again later.',
            timeout: 3000,
          });
        }
      );
      return false;
    };

    reader.readAsArrayBuffer(file);
    return false;
  }
}
