export enum ShiftState {
    Accepted,
    Rejected,
    Indeterminate
}

export interface ShiftStatusResponse {
    uid: string;
    driver: string;
    contact: string;
    location: string;
    department: string;
    status: ShiftState;
}

export class ShiftStatus {
    public uid = '';
    public driver = '';
    public contact = '';
    public location = '';
    public department = '';
    public status: ShiftState = 0;

    public static create(data: ShiftStatusResponse): ShiftStatus {
        return (new this()).map(data);
    }

    public map(data: ShiftStatusResponse): this {
        this.uid = data.uid;
        this.driver = data.driver;
        this.contact = data.contact;
        this.location = data.location;
        this.department = data.department;
        this.status = data.status;

        return this;
    }
    public responsify(): ShiftStatusResponse {
        return {
            uid: this.uid,
            driver: this.driver,
            contact: this.contact,
            location: this.location,
            department: this.department,
            status: this.status
        };
    }
}
