import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { apiUrls } from 'src/app/settings/settings';
import { EpEncryptionService } from '../ep-encryption.service';
import { MappingType } from 'src/app/pages/settings/settings.component';
import { IndexQuery, buildParams } from 'src/app/model/IndexQuery';

@Injectable({
  providedIn: 'root',
})
export class SettingsAPI {
  constructor(private http: HttpClient, private crypto: EpEncryptionService) {}

  public getAll() {
    return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}settings`);
  }
  public update(data: { [key: string]: any }) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}settings`,
      this.crypto.sendPayload(data)
    );
  }

  public getTrackingData(startDate: string, endDate: string) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/tracking-data`,
      this.crypto.sendPayload({
        startDate,
        endDate,
      })
    );
  }

  public uploadMappingData(data: MappingType['data']) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/mapping`,
      this.crypto.sendPayload(data)
    );
  }

  public getLocations(query: IndexQuery) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/mapping-locations${buildParams(query)}`
    );
  }
  public getDepartments(query: IndexQuery) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/mapping-departments${buildParams(query)}`
    );
  }
  public getRoles(query: IndexQuery) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/mapping-roles${buildParams(query)}`
    );
  }
  public getLoadingCharges() {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/loading-charges`
    );
  }
  public updateInventoryCodes(data: any) {
    return this.http.put<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/inventory-codes`,
      this.crypto.sendPayload(data)
    );
  }

  public fetchMappingData(location_id: number) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/business-mapping?location=${location_id}`
    );
  }

  public fetchPayrates(location_id: number) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/payrates?location=${location_id}`
    );
  }

  public delete(id: number, table: string) {
    return this.http.delete<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/${table}/${id}`
    );
  }
  public store(data: any, table: string) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/${table}`,
      this.crypto.sendPayload(data)
    );
  }
  public edit(data: any, id: number, table: string) {
    return this.http.put<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/${table}/${id}`,
      this.crypto.sendPayload(data)
    );
  }
  public getRolesAndPermissions() {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/roles-permissions`
    );
  }
  public setRolesAndPermissions(data: any) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/roles-permissions`,
      this.crypto.sendPayload(data)
    );
  }
}
