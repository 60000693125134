<label class="checkbox" [ngClass]="classes">
    <input 
        type="checkbox" 
        [ngModel]="model" 
        (ngModelChange)="change($event)"
        [disabled]="isDisabled"
        [readonly]="isReadOnly" >
    <span class="mark_wrap">
        <span class="mark">
            <div class="checked"></div>
        </span>
    </span>
    <span class='label'>{{label}}</span>
</label>