import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectOption } from 'src/app/core/select/select.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { MessagePost, PostCategory } from 'src/app/model/custm/MessagePost';
import { LoaderPartial } from 'src/app/partials/loader/loader.component';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { PostsAPI } from 'src/app/services/custm/posts.service';

@Component({
    selector: 'app-post-form',
    templateUrl: './post-form.component.html',
    styleUrls: ['./post-form.component.scss']
})
export class PostFormComponent implements OnInit {
    public post:MessagePost = new MessagePost();

    public editMode:boolean = false;

    public categories:SelectOption[] = [
        { label:'Updates', value: PostCategory.Updates },
        { label:'Links', value: PostCategory.Links },
        { label:'Tips', value: PostCategory.Tips }
    ];

    constructor(
        private postsApi:PostsAPI,
        private router:Router,
        private route:ActivatedRoute,
    ){
        this.route.params.subscribe(p => {
            if(p.id){
                this.editMode = true;

                LoaderPartial.show(true);
                this.postsApi.get(p.id).subscribe(r => {
                    let res = CodedResponseModel.decode(r);

                    this.post = MessagePost.create(res);

                    LoaderPartial.show(false);
                }, err => {
                    NotificatorPartial.push({ type:'error', message:'An error has occured', details: err.error.message, dismissable: true });
                })
            }
        })
    }

    ngOnInit():void {}

    public save(){
        if(this.editMode){
            this.postsApi.update(this.post).subscribe(r => {
                let res = CodedResponseModel.decode(r);

                NotificatorPartial.push({ type:'success', message:'The post has been updated', timeout: 3000 });
                this.router.navigate(['/posts']);
            }, err => {
                NotificatorPartial.push({ type:'error', message:'An error has occured', details: err.error.message, dismissable: true });
            })
        } else {
            this.postsApi.create(this.post).subscribe(r => {
                let res = CodedResponseModel.decode(r);

                NotificatorPartial.push({ type:'success', message:'The post has been created', timeout: 3000 });
                this.router.navigate(['/posts']);
            }, err => {
                NotificatorPartial.push({ type:'error', message:'An error has occured', details: err.error.message, dismissable: true });
            })
        }
    }
}
