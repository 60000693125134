import { Component, OnInit, ViewChild } from '@angular/core';
import { CodedResponseModel } from '../model/CodedResponseModel';
import { NotyficationMessageStateModel } from '../model/NotyficationMessageStateModel';
import { User } from '../model/User';
import { UsersAPIService } from '../services/users.service';
import { apiUrls } from '../settings/settings';

@Component({
	selector: 'app-profile-edit',
	templateUrl: './profile-edit.component.html',
	styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {
	public showLoader: boolean = false;
	public showNotyfication: boolean = false;
	public notyficationMessage: string = '';
	public notyficationMessageStateModel: NotyficationMessageStateModel = new NotyficationMessageStateModel();
	
	user:User = new User();
	avatar:File|null = null;

	constructor(
		private userApi: UsersAPIService,
	){
		this.showLoader = true;
		this.userApi.getCurrUser().subscribe(res => {
			let response = new CodedResponseModel();
			response.map(res);
			console.log(response.decodeData());
			this.user.map(response.decodeData());
			this.showLoader = false;
		}, err => {
			this.showLoader = false;
			this.showNotyfication = true;
			this.notyficationMessage = err.error.message;
			this.notyficationMessageStateModel.errors_save = true;
		})
	}
	ngOnInit(){}

	public updateData(){
		this.userApi.updateCurrUser(this.user).subscribe(res => {
			this.showNotyfication = true;
			this.notyficationMessage = res.message;
			this.notyficationMessageStateModel.save_info = true;
		}, err => {
			this.showNotyfication = true;
			this.notyficationMessage = err.error.message;
			this.notyficationMessageStateModel.errors_save = true;
		});
	}
	public uploader(e:Event){
		this.avatar = (e.target as any).files.item(0);
		if(this.avatar) this.userApi.uploadAvatar(this.avatar as File).subscribe(res => {
			this.showNotyfication = true;
			this.notyficationMessage = res.message;
			this.notyficationMessageStateModel.save_info = true;
		}, err => {
			this.showNotyfication = true;
			this.notyficationMessage = err.error.message;
			this.notyficationMessageStateModel.errors_save = true;
		})
	}
	public getAvatarUrl(){
		return `${apiUrls.imgUrl}avatars/${this.user.id}/${this.user.avatar_filename}`;
	}
}
