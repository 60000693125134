import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CodedResponseModel } from "../model/CodedResponseModel";
import { apiUrls } from "../settings/settings";
import { EpEncryptionService } from "./ep-encryption.service";
import { MailEvents } from '../model/MailEvents';
import { buildParams, IndexQuery } from '../model/IndexQuery';


@Injectable({
  providedIn: 'root'
})
export class MailEventsService {

  constructor(private http: HttpClient, private epEncryptionService: EpEncryptionService) { }


  public getAll(query:IndexQuery) {
    return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}mail_events${buildParams(query)}`);
  }

  public get(id:number){
    return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}mail_events/${id}`);
  }
  public add(event:MailEvents){
    return this.http.post<CodedResponseModel>(`${apiUrls.apiUrl}mail_events`,
      this.epEncryptionService.sendPayload(event));
  }
  public update(event:MailEvents){
    return this.http.put<CodedResponseModel>(`${apiUrls.apiUrl}mail_events/${event.id}`,
      this.epEncryptionService.sendPayload(event));
  }
  public delete(id:number){
    return this.http.delete<CodedResponseModel>(`${apiUrls.apiUrl}mail_events/${id}`);
  }

}
