import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrls } from 'src/app/settings/settings';
import { EpEncryptionService } from '../ep-encryption.service';

@Injectable({
  providedIn: 'root',
})
export class FlexiJobOffer {
  constructor(
    private http: HttpClient,
    private epEncryptor: EpEncryptionService
  ) {}

  public update (data: any = {}, id: any = "") {
    return this.http.put(
      `${apiUrls.apiUrl}flexi-job-update-comment/${id}`,
      data
    );
  }
}
