<div class='form-container'>
    <!-- <div class='values'>
        <app-heading4 headingTitle="Special Workflow Clients"></app-heading4>
        <app-button text="Add" (click)="specialWorkflowClients.push('')"></app-button>
        <div class='value' *ngFor="let v of specialWorkflowClients; let i = index; trackBy: trackByIndex">
            <app-form-input [(model)]="specialWorkflowClients[i]" [isLabel]="false"></app-form-input>
            <app-link-button text="Remove" [isIcon]="false" (click)="specialWorkflowClients.splice(i, 1)"></app-link-button>
        </div>
    </div> -->
    <div class='values' [ngClass]="{'non-touchable':!authService.hasPermissions([
        'full:settings','gtr:settings'
        ])}">
        <app-heading4 headingTitle="Geofence Trigger Radius"></app-heading4>
        <app-form-input labelText="Geofence Triggering Radius ( Unit m )" [(model)]="geofenceTriggerRadius"
            type="number"></app-form-input>
    </div>
    <div class='values' [ngClass]="{'non-touchable':!authService.hasPermissions([
    'full:settings','spec-workflow:settings'
    ])}">
        <app-heading4 headingTitle="Special workflow"></app-heading4>
        <app-form-input labelText="Special workflow manifest stop threshold" [(model)]="specialWorkflowThreshold"
            type="number"></app-form-input>
    </div>

    <div class='values'>
        <app-heading4 headingTitle="Return to Depot extra time departments"></app-heading4>
        <app-button *ngxPermissionsOnly="['full:settings','depot-extra-time:settings']" text="Add"
            (click)="additionalWorktimeDeps.push('')"></app-button>
        <div class='value' [ngClass]="{'non-touchable':!authService.hasPermissions([
        'full:settings','depot-extra-time:settings'
        ])}" *ngFor="let v of additionalWorktimeDeps; let i = index; trackBy: trackByIndex">
            <app-form-input [(model)]="additionalWorktimeDeps[i]" [isLabel]="false"></app-form-input>
            <app-link-button *ngxPermissionsOnly="['full:settings','depot-extra-time:settings']" text="Remove"
                [isIcon]="false" (click)="additionalWorktimeDeps.splice(i, 1)"></app-link-button>
        </div>
    </div>

    <div *ngxPermissionsOnly="['full:settings','mappings:settings']" class='values flex flex-col gap-y-5'>
        <h1 class='font-semibold'>Location, Department & Role Mappings</h1>
        <input type='file' (change)="handleFileInputChange($event)" class='hidden' #fileInput accept='.csv,.xlsx' />
        <button (click)="fileInput.click()"
            class='w-[195px] rounded bg-[#2A6AF6] text-white flex justify-center items-center font-Inter  outline-none py-3 px-5'>Upload</button>
    </div>

    <div *ngxPermissionsOnly="['full:settings','tracking-export:settings']" class='values flex flex-col gap-y-5'>
        <span class='text-base font-semibold'>Location Tracking Data Export</span>
        <div class="date_range_selection">
            <div class="date_from_selection">
                <div class="hide_item">
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="fromDate"
                            (ngModelChange)="handleFromDateChange($event)" />
                        <mat-datepicker #picker [ngClass]="'my-theme-primary'"><mat-datepicker-actions>
                                <button mat-button matDatepickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDatepickerApply>
                                    Apply
                                </button>
                            </mat-datepicker-actions></mat-datepicker>
                    </mat-form-field>
                </div>
                <span class="date_selection_text whitespace_nowrap">From:&nbsp;
                    <span class="date_selection_date">{{
                        getFormattedTime(fromDate)
                        }}</span>
                </span>
                <button (click)="picker.open()" class="date_select_button">
                    <img src="/assets/icons/calendar.svg" alt="search-icon" />
                </button>
            </div>
            <div class="date_from_selection">
                <div class="hide_item">
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker1" [(ngModel)]="toDate"
                            (ngModelChange)="handleToDateChange($event)" />
                        <mat-datepicker #picker1><mat-datepicker-actions>
                                <button mat-button matDatepickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDatepickerApply>
                                    Apply
                                </button>
                            </mat-datepicker-actions></mat-datepicker>
                    </mat-form-field>
                </div>
                <span class="date_selection_text whitespace_nowrap">To:&nbsp;
                    <span class="date_selection_date">{{
                        getFormattedTime(toDate)
                        }}</span>
                </span>
                <button (click)="picker1.open()" class="date_select_button">
                    <img src="/assets/icons/calendar.svg" alt="search-icon" />
                </button>
            </div>
        </div>
        <button (click)="getTrackingData()"
            class='w-[195px] rounded bg-[#2A6AF6] text-white flex justify-center items-center font-Inter  outline-none py-3 px-5'>Export</button>
    </div>

    <app-button (buttonClickEvent)="save()" text="Save"></app-button>

    <div class='values flex flex-col gap-y-5'>
        <ng-container *ngFor="let child of childRoutes">
            <button *ngxPermissionsOnly="child.permissions" [appOpenInNewTab]="child.route"
                class='w-full rounded bg-[#2A6AF6] text-white flex justify-center items-center font-Inter  outline-none py-3 px-5'>
                {{child.label}}
            </button>
        </ng-container>
    </div>


    <app-loader-v2 [loading]="loading"></app-loader-v2>
</div>