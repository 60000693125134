<div>
    <div class='controls'>
        <app-button *ngxPermissionsOnly="['full:users', 'create:users']" appOpenInNewTab="/users/new"
            text="Create New"></app-button>
        <app-form-input [(model)]="query.filters!.search" (modelChange)="searcherInput()" placeholder="Search..."
            [isLabel]="false"></app-form-input>
    </div>

    <div class='table_wraper'>
        <c-table [columns]="columnDef" [data]="users" [loading]="loading" [query]="query" [total]="filtered"
            [sortedColumn]="sortedColumn" (sorted)="handleSort()" (changed)="fetchData()"></c-table>
    </div>
</div>