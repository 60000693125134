<div>
    <div class='controls'>
        <app-button text="Create New" (buttonClickEvent)="openCreateForm()"></app-button>
        <app-form-input [(model)]="query.filters!.search" (modelChange)="searcherInput()" placeholder="Search..."
            [isLabel]="false"></app-form-input>
    </div>
    <div class='table_wraper'>
        <c-table [columns]="columnDef" [data]="locations" [query]="query" [total]="filtered"
            (changed)="fetchData()"></c-table>
    </div>
</div>
<app-modal-body *ngIf="modalStatus!=='NONE'">
    <ng-container [ngTemplateOutlet]="create_form"
        *ngIf="modalStatus==='CREATE_FORM'||modalStatus==='EDIT_FORM'"></ng-container>
    <ng-container [ngTemplateOutlet]="additional_create_form"
        *ngIf="modalStatus==='ADDITIONAL_CREATE_FORM'"></ng-container>
</app-modal-body>
<app-loader-v2 [loading]="loading"></app-loader-v2>

<ng-template #create_form>
    <form [formGroup]="locationForm" #formRef="ngForm" (ngSubmit)="store()" class='w-fit'>
        <div class='w-[800px] h-[600px] flex flex-col items-center box-border relative gap-y-8'>
            <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeForm()">
                <img src="/assets/icons/x-close.svg" alt="close-icon" />
            </div>
            <div class='w-full flex flex-1 flex-col gap-y-4'>
                <span class='text-base font-bold font-Inter'>
                    {{modalStatus==='CREATE_FORM'?
                    'Create New Location':'Edit - '+selected.name
                    }}
                </span>
                <div class='w-full'>
                    <mat-accordion class="example-headers-align">
                        <mat-expansion-panel [expanded]="step === 0" (opened)="handleExpandStep0()">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Location Details
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class='grid grid-cols-2 gap-2 box-border py-3'>
                                <div class='flex justify-start items-center gap-2'>
                                    <span class='text-sm text-[#475467] whitespace-nowrap'>Location Name</span>
                                    <input formControlName='name' type='text'
                                        class='w-[240px] border border-solid border-[#dadada] h-8 outline-none text-sm px-2'
                                        [ngClass]="{'border-[#CD4244]':isInvalidControl('name'),
                                'border-[#dadada]':!isInvalidControl('name')}" />
                                </div>
                                <div class='flex justify-start items-center gap-2'>
                                    <span class='text-sm text-[#475467] whitespace-nowrap'>Location Email</span>
                                    <input formControlName='email' type='email'
                                        class='w-[240px] border border-solid border-[#dadada] h-8 outline-none text-sm px-2'
                                        [ngClass]="{'border-[#CD4244]':isInvalidControl('email'),
                                'border-[#dadada]':!isInvalidControl('email')}" />
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 1" (opened)="handleExpandStep1()">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Select Departments & Roles
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class='w-full flex flex-col items-start'>
                                <span class='text-sm text-[#475467] whitespace-nowrap'>Select the Departments and Roles
                                    to
                                    be available at this location</span>
                                <div class='grid grid-cols-2 gap-x-4 gap-y-2 mt-1'>
                                    <div class='flex justify-between items-center'>
                                        <span class='flex items-center justify-start font-bold font-Inter'>Departments
                                            ({{
                                            filterChecked(departments) }} /{{
                                            departments.length }})&nbsp;
                                            <div class='hover:cursor-pointer' (click)="addDepartment()">
                                                <img src="/assets/icons/plus.svg" alt="plus-icon" />
                                            </div>
                                        </span>
                                        <div class='hover:cursor-pointer' (click)="handleSort('departments')">
                                            <img src="/assets/icons/sort_az.png" alt="sort_az-icon" />
                                        </div>
                                    </div>
                                    <div class='flex justify-between items-center'>
                                        <span class='flex items-center justify-start font-bold font-Inter'>Roles ({{
                                            filterChecked(roles) }} /{{
                                            roles.length }})&nbsp;
                                            <div class='hover:cursor-pointer' (click)="addRole()">
                                                <img src="/assets/icons/plus.svg" alt="plus-icon" />
                                            </div>
                                        </span>
                                        <div class='hover:cursor-pointer' (click)="handleSort('roles')">
                                            <img src="/assets/icons/sort_az.png" alt="sort_az-icon" />
                                        </div>
                                    </div>
                                    <div class='h-[170px]'>
                                        <c-table-v2 [columns]="formTblColumnDef1" [data]="departments"></c-table-v2>
                                    </div>
                                    <div class='h-[170px]'>
                                        <c-table-v2 [columns]="formTblColumnDef2" [data]="roles"></c-table-v2>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 2" (opened)="handleExpandStep2()">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Assign Roles to Departments
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class='w-full flex flex-col'>
                                <p>Select a department from the dropdown, then select the roles to be available within
                                    that department.</p>
                                <div class='w-full flex items-center justify-start gap-2'>
                                    <span class='font-bold font-Inter'>Department</span>
                                    <div class='w-[50%] flex relative'>
                                        <img src="/assets/icons/search.svg" alt="search-icon"
                                            class='absolute top-[10px] left-[10px]' />
                                        <input
                                            class='w-full h-10 overflow-hidden flex justify-start items-center pl-9 pr-[14px] py-[10px] gap-x-2 border border-solid rounded-lg outline-none text-base font-Inter border-[#D0D5DD]'
                                            placeholder='Search' [matAutocomplete]="departmentAuto"
                                            [(ngModel)]="searchedDepartment.name"
                                            [ngModelOptions]="{standalone: true}" />
                                        <mat-autocomplete #departmentAuto="matAutocomplete"
                                            (optionSelected)="handleSelectionChange($event)">
                                            <mat-option
                                                *ngFor="let department of filterDepartments()|searchFilter:searchedDepartment.name"
                                                [value]="department.name">
                                                {{department.name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                    <span class='font-bold font-Inter'>({{ filterChecked(_roles) }} /{{ _roles.length
                                        }})</span>
                                    <div class='hover:cursor-pointer' (click)="handleSort('_roles')">
                                        <img src="/assets/icons/sort_az.png" alt="sort_az-icon" />
                                    </div>
                                </div>

                                <div class='h-[170px]'>
                                    <c-table-v2 [columns]="formTblColumnDef2" [data]="_roles"></c-table-v2>
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 3" (opened)="handleExpandStep3()">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Edit Pay Rates
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class='w-full flex flex-col'>
                                <div class='w-full h-[200px]'>
                                    <c-table-v2 [columns]="formTblColumnDef3" [data]="payrates"></c-table-v2>
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step === 4" (opened)="handleExpandStep4()">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Edit Loading Rates
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class='w-full flex flex-col'>
                                <div class='w-full h-[200px]'>
                                    <c-table-v2 [columns]="formTblColumnDef4" [data]="loading_rates"></c-table-v2>
                                </div>
                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>

                </div>
            </div>
            <div class='w-full flex items-center gap-2'>
                <button class='custom-blue-solid-btn' type='submit'>
                    OK</button>
                <button class='custom-outlined-btn' type='button' (click)="closeForm()">Cancel</button>
            </div>
        </div>
    </form>
</ng-template>
<ng-template #additional_create_form>
    <div class='w-[400px] flex flex-col items-center box-border relative gap-y-8'>
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='w-full flex flex-col gap-y-4'>
            <span class='text-base font-bold font-Inter'>
                Ceate New {{additionalFormTitle}}
            </span>
            <app-form-input [(model)]="currentValue" placeholder="" [isLabel]="false"></app-form-input>
        </div>
        <div class='w-full flex items-center gap-2'>
            <button class='custom-blue-solid-btn' (click)="_store()">
                Save
            </button>
            <button class='custom-outlined-btn' (click)="closeModal()">Cancel</button>
        </div>
    </div>
</ng-template>