import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appOpenInNewTab]',
})
export class OpenInNewTabDirective {
  @Input() appOpenInNewTab?: string;

  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    if (!this.appOpenInNewTab) return;
    if (event.ctrlKey || event.metaKey || event.button === 1) {
      event.preventDefault();
      window.open(
        this.router.serializeUrl(
          this.router.createUrlTree([this.appOpenInNewTab])
        ),
        '_blank'
      );
    } else {
      this.router.navigate([this.appOpenInNewTab]);
    }
  }

  @HostListener('contextmenu', ['$event'])
  handleContextMenu(event: MouseEvent): void {
    if (!this.appOpenInNewTab) return;
    event.preventDefault();
    window.open(
      this.router.serializeUrl(
        this.router.createUrlTree([this.appOpenInNewTab])
      ),
      '_blank'
    );
  }
}
