<!-- <div class='menu-container' *ngIf="exists">
    <div class='buttons'>
        <button class='menu-entry' [class.active]="activeSlug===entry.slug"
            *ngFor="let entry of  (userRole==='Guest'?guestEntries: entries)"
            (click)="entry.onClick?entry.onClick():true" [appOpenInNewTab]="entry.route">
            {{entry.label}}
        </button>
    </div>
    <div class='usr-info'>Hello, {{currUser()?.name}}</div>
</div> -->
<div class='menu-container' *ngIf="exists">
    <div class='buttons'>
      <ng-container *ngFor="let entry of (userRole === 'Guest' ? guestEntries : entries)">
        <div class='menu-item' [class.active]="activeSlug === entry.slug">
          <button class='menu-entry' 
                  (click)="toggleSubmenu(entry)" 
                  [appOpenInNewTab]="entry.route">
            {{entry.label}}
          </button>
          <div *ngIf="entry.submenu?.length && entry.isOpen" class="submenu">
            <button class='submenu-entry' *ngFor="let subentry of entry.submenu"
                    [class.active]="activeSlug === subentry.slug"
                    (click)="subentry.onClick ? subentry.onClick() : true" 
                    [appOpenInNewTab]="subentry.route">
              {{subentry.label}}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class='usr-info'>Hello, {{currUser()?.name}}</div>
  </div>
  
  
  