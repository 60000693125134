import { User, user_interface } from "../User";

export interface ChecklistResponse{
    id:number;
    user_id:number;
    day:string;
    type:ChecklistType,
    module_id?:number;
    subform_id?:number;
    data?:any;
    user?:user_interface;
    created_at?:string;
}

export enum ChecklistType {
    FitForDuty,
    Prestart,
    Debrief,
    Begun,
    UnfitForDuty,
    IncidentCleared,
    Incident,
    Cancelled,
}

export class Checklist {
    public id:number = 0;
    public userId:number = 0;
    public day:Date = new Date();
    public type:ChecklistType = 0;
    public moduleId?:number;
    public subformId?:number;
    public data?:any;
    public createdAt?:Date;

    public user?:User;

    public map(data:ChecklistResponse){
        this.id = data.id;
        this.userId = data.user_id;
        this.day = new Date(data.day);
        this.type = data.type;
        this.moduleId = data.module_id;
        this.subformId = data.subform_id;
        this.data = data.data;

        this.user = data.user?User.mapUser(data.user):undefined;

        this.createdAt = data.created_at?new Date(data.created_at):undefined;

        return this;
    }
    public responsify():ChecklistResponse{
        return {
            id: this.id,
            user_id: this.userId,
            day: this.day.toISOString(),
            type: this.type,
            module_id: this.moduleId,
            subform_id: this.subformId,
            data: this.data,
        }
    }

    public static create(data:ChecklistResponse){
        let r = new this;
        return r.map(data);
    }

    public get event(){
        switch(this.type){
            case ChecklistType.FitForDuty:
                return 'Fit For Duty';
            case ChecklistType.Prestart:
                return 'Prestart';
            case ChecklistType.Debrief:
                return 'Debrief';
            case ChecklistType.Begun:
                return 'Manifest accepted';
            case ChecklistType.Incident:
                return 'Incident pending';
            case ChecklistType.Cancelled:
                return 'Shift cancelled';
            case ChecklistType.IncidentCleared:
                return 'Incident cleared';
            case ChecklistType.UnfitForDuty:
                return 'Fit For Duty (Unfit)';
        }
    }
}