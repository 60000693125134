import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(target: any[] | null, filterTerm: string): any[] {
    if (!target || !filterTerm) return target ?? [];
    filterTerm = filterTerm.toLowerCase();
    return target.filter((value) => {
      if (typeof value === 'string')
        return value.toLowerCase().includes(filterTerm);
      else return value.name.toLowerCase().includes(filterTerm);
    });
  }
}
