import { Component } from '@angular/core';
import { MenuComponent } from './partials/menu/menu.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'laravel8';
  public menuOpen: boolean = false;

  constructor(
    private auth: LoginService,
    private permissionService: NgxPermissionsService
  ) {
    MenuComponent.show.subscribe((r) => {
      this.menuOpen = r;
    });

    const role: string = this.auth.getUserRole();
    const permissions: string[] = this.auth.getUserPermissions();
    this.permissionService.loadPermissions([role, ...permissions]);
  }
}
