<!-- <div *ngIf="!sent">
    <div>
        <label>New password</label>
        <input [(ngModel)]="password" type="password">
    </div>
    <div>
        <label>Confirm password</label>
        <input [(ngModel)]="passConfirm" type="password">
    </div>
    <button (click)="send()">Change password</button>
</div>
<div *ngIf="sent">
    <p>Password has been reset!</p>
    <a routerLink="/login">Return to login page</a>
</div> -->

<div class='login-container'>
    <div class='form' *ngIf="!sent">
        <app-heading1 headingTitle="Change password"></app-heading1>
        <app-form-input [(model)]="password" type="password" labelText="New password"></app-form-input>
        <app-form-input [(model)]="passConfirm" type="password" labelText="Confirm password"></app-form-input>
        <div class='err-msg' *ngIf="errMsg">{{errMsg}}</div>
        <app-button text="Change password" (buttonClickEvent)="send()"></app-button>
    </div>
    <div *ngIf="sent" class="confirmation">
        <span>Your password has been reset. You can now log in using your new credentials.</span>
    </div>
</div>