import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Depot } from 'src/app/model/custm/Depot';
import { LoaderPartial } from 'src/app/partials/loader/loader.component';
import { MenuComponent } from 'src/app/partials/menu/menu.component';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { DepotAPI } from 'src/app/services/custm/depots.service';

@Component({
    selector: 'app-depot-form',
    templateUrl: './depot-form.component.html',
    styleUrls: ['./depot-form.component.scss']
})
export class DepotFormComponent implements OnInit {
    public depot:Depot = new Depot();

    public editMode:boolean = false;

    constructor(
        private depotApi:DepotAPI,
        private router:Router,
        private route:ActivatedRoute,
    ){
        this.route.params.subscribe(p => {
            if(p.id){
                this.editMode = true;

                LoaderPartial.show(true);
                this.depotApi.show(p.id).subscribe(r => {
                    let res = CodedResponseModel.decode(r);

                    this.depot = Depot.create(res);

                    LoaderPartial.show(false);
                }, err => {
                    NotificatorPartial.push({ type:'error', message:'An error has occured', details: err.error.message, dismissable: true });
                })
            }
        })
    }

    ngOnInit():void{}

    public save(){
        if(this.editMode){
            this.depotApi.update(this.depot).subscribe(r => {
                let res = CodedResponseModel.decode(r);

                NotificatorPartial.push({ type:'success', message:'User has been updated', timeout: 3000 });
                this.router.navigate(['/depots']);
            }, err => {
                NotificatorPartial.push({ type:'error', message:'An error has occured', details: err.error.message, dismissable: true });
            })
        } else {
            this.depotApi.create(this.depot).subscribe(r => {
                let res = CodedResponseModel.decode(r);

                NotificatorPartial.push({ type:'success', message:'The user has been created', timeout: 3000 });
                this.router.navigate(['/depots']);
            }, err => {
                NotificatorPartial.push({ type:'error', message:'An error has occured', details: err.error.message, dismissable: true });
            })
        }
    }
}
