import * as XLSX from 'xlsx-js-style';
import * as moment from 'moment';
import { Papa } from 'ngx-papaparse';

const defaultColumnWidths = [
  { wch: 20 },
  { wch: 12 },
  { wch: 15 },
  { wch: 30 },
  { wch: 50 },
  { wch: 10 },
  { wch: 10 },
  { wch: 15 },
  { wch: 30 },
  { wch: 15 },
  { wch: 15 },
  { wch: 10 },
  { wch: 18 },
  { wch: 100 },
];

export const createHyperlinkCell = (
  url: string,
  displayText: string
): XLSX.CellObject => {
  const cell: XLSX.CellObject = { t: 's', v: displayText };
  cell.l = { Target: url, Tooltip: url };
  return cell;
};

export const exportAsExcel = (
  tableData: any,
  columnWidths = defaultColumnWidths,
  title = 'POD'
) => {
  const wb = XLSX.utils.book_new();

  // STEP 3: Create worksheet with rows; Add worksheet to workbook
  const ws = XLSX.utils.aoa_to_sheet(tableData);
  for (let i = 0; i < columnWidths.length; i++) {
    ws['!cols'] = ws['!cols'] || [];
    ws['!cols'].push({ ...columnWidths[i], hidden: false });
  }
  XLSX.utils.book_append_sheet(wb, ws, 'readme demo');

  // STEP 4: Write Excel file to browser
  XLSX.writeFile(wb, `${title} ${moment().format('YYYY-MM-DD HH-mm-ss')}.xlsx`);
};

export const exportAsExcelFlexi = (
  tableData: any,
  columnWidths = defaultColumnWidths,
  title = 'FLEXI-JOB'
) => {
  const wb = XLSX.utils.book_new();

  // STEP 3: Create worksheet with rows; Add worksheet to workbook
  const ws = XLSX.utils.aoa_to_sheet(tableData);
  for (let i = 0; i < columnWidths.length; i++) {
    ws['!cols'] = ws['!cols'] || [];
    ws['!cols'].push({ ...columnWidths[i], hidden: false });
  }
  XLSX.utils.book_append_sheet(wb, ws, 'Flexi job');

  // STEP 4: Write Excel file to browser
  XLSX.writeFile(wb, `${title} - ${moment().format('YYYY-MM-DD HH-mm-ss')}.xlsx`);
};

export const exportCSV = (data: any, title = '') => {
  const papa = new Papa();
  const csv = papa.unparse(data);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${title} ${moment().format('YYYY-MM-DD HH-mm-ss')}.csv`;
  a.click();
  window.URL.revokeObjectURL(url);
};
