import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from 'src/app/core/table/table.component';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import {
  MessagePost,
  MessagePostResponse,
} from 'src/app/model/custm/MessagePost';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { PostsAPI } from 'src/app/services/custm/posts.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
})
export class PostsComponent implements OnInit {
  public loading: boolean = false;
  public posts: MessagePost[] = [];

  public searchCooldown: any;

  public columnDef: TableColumn[] = [
    { label: 'ID', slug: 'id', sortable: false, filterType: 'number' },
    { label: 'Title', slug: 'title', sortable: false },
    {
      label: 'Author',
      slug: 'author',
      sortable: false,
      accessor: (i: MessagePost) => i.user?.fullName,
    },
    {
      label: 'Category',
      slug: 'category',
      sortable: false,
      accessor: (i: MessagePost) => i.postCategory,
    },
    {
      label: 'Created At',
      slug: 'created_at',
      sortable: false,
      accessor: (i: MessagePost) =>
        this.datePipe.transform(i.createdAt, 'dd MMMM yyyy HH:mm'),
    },
    {
      label: 'Actions',
      slug: 'actions',
      dataType: 'actions',
      actions: [
        {
          label: 'Edit',
          type: 'route',
          route: (i: MessagePost) => `/posts/${i.id}`,
          condition: () =>
            this.authService.hasPermissions(['full:posts', 'edit:posts']),
        },
        {
          label: 'Delete',
          action: (i: MessagePost) => this.del(i),
          condition: () =>
            this.authService.hasPermissions(['full:posts', 'delete:posts']),
        },
      ],
    },
  ];

  public query: IndexQuery = {
    page: 1,
    perPage: 15,
    sortBy: 'created_at',
    sortDir: 'desc',
    filters: {
      search: '',
    },
  };

  public total: number = 0;
  public filtered: number = 0;

  constructor(
    private postsApi: PostsAPI,
    private datePipe: DatePipe,
    private authService: LoginService,
    private dialog: MatDialog
  ) {
    this.fetchData();
  }

  ngOnInit(): void {}

  public fetchData() {
    this.loading = true;
    this.postsApi.index(this.query).subscribe((r) => {
      let res = CodedResponseModel.decode(r);

      this.posts = res.data.map((p: MessagePostResponse) =>
        MessagePost.create(p)
      );

      this.total = res.total;
      this.filtered = res.filtered;

      this.loading = false;
    });
  }

  public del(p: MessagePost) {
    let d = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Are you sure you want to delete the post ${p.title}?`,
      },
    });
    d.afterClosed().subscribe((c) => {
      if (c) {
        this.postsApi.delete(p.id).subscribe(
          (r) => {
            NotificatorPartial.push({
              type: 'info',
              message: `'${p.title}' has been deleted`,
              timeout: 3000,
            });
            this.fetchData();
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: 'An error has occurred',
              details: err.error.message,
            });
          }
        );
      }
    });
  }

  public searcherInput() {
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.fetchData();
    }, 500);
  }
}
