import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TableColumn } from 'src/app/core/table/table.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import {
  DepartmentType,
  ProofOfDelivery,
  SortedPODObj,
} from 'src/app/model/custm/ProofOfDelivery';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { ProofOfDeliveryAPI } from 'src/app/services/custm/proof-of-delivery.service';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { exportAsExcel } from 'src/app/export-excel/Export2Excel';

@Component({
  selector: 'app-proof-of-delivery',
  templateUrl: './proof-of-delivery.component.html',
  styleUrls: ['./proof-of-delivery.component.scss'],
})
export class ProofOfDeliveryComponent implements OnInit {
  public loading: boolean = false;
  public data: ProofOfDelivery[] = [];
  public searchModel: string = '';
  public isExpanded = {
    location: false,
    department: false,
    drawer: false,
  };

  public toExport: boolean = false;

  public range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  public searchCooldown: any;

  public query: IndexQuery = {
    page: 1,
    perPage: 15,
    sortBy: 'created_at',
    sortDir: 'desc',
    filters: {
      search: '',
      tz: String(new Date().getTimezoneOffset()),
    },
  };

  public total: number = 0;
  public filtered: number = 0;
  public columnDef = columnDefinition;
  public departmentSearch: string = '';

  public location_department_data: {
    location: DepartmentType[];
    department: DepartmentType[];
  } = {
    location: [],
    department: [],
  };

  filteredData: {
    location: { label: string; id: string }[];
    department: { label: string; id: string }[];
  } = {
    location: [],
    department: [],
  };

  public previousDates: {
    fromDate: Date;
    toDate: Date;
  } = {
    fromDate: new Date(),
    toDate: new Date(),
  };

  public fromDate: Date = new Date();
  public toDate: Date = new Date();

  public sortedColumn: { columnName: string } = {
    columnName: 'date',
  };

  public sortOrder: (keyof SortedPODObj)[] = [
    'stop_outcome',
    'stop_result',
    'contact_number',
    'recipient_name',
    'business_name',
    'consignment_number',
    'stop_type',
    'address',
    'department',
    'location',
    'driver',
    'arrival_time',
    'date',
  ];

  public sortStringObj = {
    stop_outcome: 'COALESCE(stop_outcome,"")',
    stop_result: 'COALESCE(stop_result,"")',
    contact_number: 'COALESCE(contact_number,"")',
    recipient_name: 'COALESCE(recipient_name,"")',
    business_name: 'COALESCE(business_name,"")',
    consignment_number: 'COALESCE(consignment_number,"")',
    stop_type: 'COALESCE(stop_type,"")',
    address: 'COALESCE(address,"")',
    department:
      '(SELECT CONCAT(COALESCE(client,""), " - ", COALESCE(depot,"")) FROM job_history WHERE job_history.id = proof_of_delivery.shift_id LIMIT 1)',
    location:
      '(SELECT location FROM job_history WHERE job_history.id = proof_of_delivery.shift_id LIMIT 1)',
    driver:
      '(SELECT CONCAT(COALESCE(name,""), " ", COALESCE(lname,"")) FROM users WHERE users.id = proof_of_delivery.user_id LIMIT 1)',
    arrival_time: 'ISNULL(arrival_time), arrival_time',
    date: '(SELECT DATE(created_at) as date_only FROM job_history WHERE job_history.id = proof_of_delivery.shift_id LIMIT 1)',
  };

  public sortObj: SortedPODObj = {
    driver: true,
    date: false,
    location: true,
    department: true,
    address: true,
    stop_type: true,
    arrival_time: true,
    consignment_number: true,
    business_name: true,
    recipient_name: true,
    contact_number: true,
    stop_result: true,
    stop_outcome: true,
  };

  constructor(
    private podApi: ProofOfDeliveryAPI,
    private cdref: ChangeDetectorRef
  ) {
    this.fetchData();
    this.previousDates.fromDate = this.fromDate;
    this.previousDates.toDate = this.toDate;
  }

  ngOnInit(): void {
    // Get locations and departments data.
    this.podApi.getDepartments().subscribe((res: any) => {
      const { data } = res;
      const location = _.groupBy(data?.locations, 'location');
      const department = data?.locations as DepartmentType[];

      // Stores locations and departments to location_department_data variable.
      this.location_department_data.location = Object.keys(location).map(
        (item) =>
          ({
            location: item,
            isChecked: false,
            isVisible: true,
          } as DepartmentType)
      );
      this.location_department_data.department = department;

      // Update screen after store data to location_department_data.
      this.cdref.detectChanges();
    });
  }

  public fetchData() {
    // Make it loading status
    this.loading = true;

    // Set the start date buy 0 o'clock and end date to 23:59 of the dates.
    this.fromDate.setHours(0);
    this.fromDate.setMinutes(0);
    this.toDate.setHours(23);
    this.toDate.setMinutes(59);

    // Convert dates to utc format
    const utcFromDate = moment(this.fromDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const utcToDate = moment(this.toDate).utc().format('YYYY-MM-DD HH:mm:ss');

    const orderArr = this.sortOrder.map(
      (item) =>
        `${this.sortStringObj[item]} ${this.sortObj[item] ? 'ASC' : 'DESC'}`
    );

    // Get POD data by passing search keyword and filter options
    this.podApi
      .getPODdata(
        this.query,
        String(utcFromDate),
        String(utcToDate),
        this.filteredData.location.map((item) => item.label),
        this.filteredData.department.map((item) => item.label),
        orderArr,
        this.toExport
      )
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);

        // Initialize table data
        this.data = res.data.map((s: any) => ProofOfDelivery.create(s));
        this.filtered = res.filtered;
        this.total = res.total;

        // Stop data loading
        this.loading = false;
      });
  }

  public exportToExcel() {
    this.toExport = true;
    // Make it loading status
    this.loading = true;

    // Set the start date buy 0 o'clock and end date to 23:59 of the dates.
    this.fromDate.setHours(0);
    this.fromDate.setMinutes(0);
    this.toDate.setHours(23);
    this.toDate.setMinutes(59);

    // Convert dates to utc format
    const utcFromDate = moment(this.fromDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const utcToDate = moment(this.toDate).utc().format('YYYY-MM-DD HH:mm:ss');
    const orderArr = this.sortOrder.map(
      (item) =>
        `${this.sortStringObj[item]} ${this.sortObj[item] ? 'ASC' : 'DESC'}`
    );

    // Get POD data by passing search keyword and filter options
    this.podApi
      .getPODdata(
        this.query,
        String(utcFromDate),
        String(utcToDate),
        this.filteredData.location.map((item) => item.label),
        this.filteredData.department.map((item) => item.label),
        orderArr,
        this.toExport
      )
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);

        // Get POD data to export.
        const data: ProofOfDelivery[] = res.data.map((s: any) =>
          ProofOfDelivery.create(s)
        );

        const exportData = [
          { v: 'Driver' },
          { v: 'Date' },
          { v: 'Location' },
          { v: 'Department' },
          { v: 'Address' },
          { v: 'Stop Type' },
          { v: 'Arrival Time' },
          { v: 'Consignment Number' },
          { v: 'Business Name' },
          { v: 'Recipient Name' },
          { v: 'Contact Number' },
          { v: 'Stop Result' },
          { v: 'Stop Outcome' },
          { v: 'Attachment' },
        ];
        const tempData = data.map((item) => [
          {
            v: String(item.driver),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.date),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.location),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.department),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.address),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.stop_type),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.arrival_time),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.consignment_number),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.business_name),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.recipient_name),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.contact_number),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.stop_result),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.stop_outcome),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.attachment.join('\n')),
            t: 's',
            s: { alignment: { wrapText: true } },
          },
        ]);
        // Export data to xls file.
        exportAsExcel([exportData, ...tempData]);

        // Make the export status by false
        this.toExport = false;

        // Stop loading
        this.loading = false;
      });
  }

  // Remove inputs in search driver field
  public clearSearch() {
    this.query.filters!.search = '';
    this.query.page = 1;
    this.fetchData();
  }

  // Define action for fromdate change
  public handleFromDateChange(e: string) {
    const fromDate = new Date(e);
    const toDate = new Date(this.toDate);
    this.query.page = 1;

    // If the user select past date of todate for fromdate, it will automatically set the same date for todate with fromdate
    if (fromDate.getTime() > toDate.getTime()) this.toDate = fromDate;
    this.fetchData();
  }

  // Define action for fromdate change
  public handleToDateChange(e: string) {
    const fromDate = new Date(this.fromDate);
    const toDate = new Date(e);
    this.query.page = 1;

    // If the user select previous date of fromdate for todate, it will automatically set the same date for fromdate with todate
    if (fromDate.getTime() > toDate.getTime()) this.fromDate = toDate;
    this.fetchData();
  }

  // Returns formated time with DD/MM/YY format
  getFormattedTime(value: Date) {
    return moment(value).format('DD/MM/YY');
  }

  public handleVisivility(
    key: 'location' | 'department' | 'drawer',
    value: boolean | null = null
  ) {
    // If the user close filter window without apply, it will ignore what the user newly checked.
    if (key === 'drawer' && value === false) {
      this.location_department_data.location.forEach((item, itemIndex) => {
        this.location_department_data.location[itemIndex].isChecked =
          this.filteredData.location
            .map((item) => item.id)
            .includes(item.location);
      });
      this.location_department_data.department.forEach((item, itemIndex) => {
        this.location_department_data.department[itemIndex].isChecked =
          this.filteredData.department
            .map((item) => item.id)
            .includes(`${item.location}-${item.client}-${item.depot}`);
      });
    }

    console.log(this.departmentSearch);
    // It does expand action for location, department in filter and filter modal.
    if (value !== null) {
      this.isExpanded[key] = value;
      return;
    }
    this.isExpanded[key] = !this.isExpanded[key];
  }

  // This function define actions when user click apply button in filter modal.
  public handleFilterApply() {
    this.isExpanded.drawer = false;
    this.query.page = 1;
    this.filteredData = { location: [], department: [] };
    const filteredLocations: string[] = [];

    // Stored filtered locations data.
    this.location_department_data.location.forEach((item) => {
      if (item.isChecked) {
        filteredLocations.push(item.location);
        this.filteredData.location.push({
          label: item.location,
          id: item.location,
        });
      }
    });

    // If the filtered locations data is not empty, it will also fiter departments data which are related to filtered locations.
    if (filteredLocations.length)
      this.location_department_data.department =
        this.location_department_data.department.map((item) => ({
          ...item,
          isChecked: filteredLocations.includes(item.location)
            ? item.isChecked
            : false,
        }));

    // Store checked departments data.
    this.location_department_data.department.forEach((item) => {
      if (item.isChecked) {
        this.filteredData.department.push({
          label: `${item.client} - ${item.depot}`,
          id: `${item.location}-${item.client}-${item.depot}`,
        });
      }
    });

    // Filter data based on selected locations and departments
    this.fetchData();
  }

  // Check data and returns its boolean value
  public isChecked(value: boolean | undefined | null) {
    return Boolean(value);
  }

  // Difine actions for user inputs in Quick serach in filter modal.
  public searchDepartment(value: string) {
    this.location_department_data.department.forEach((item, itemIndex) => {
      if (
        `${item.client} - ${item.depot}`
          .toLowerCase()
          .includes(value.toLowerCase())
      ) {
        this.location_department_data.department[itemIndex].isVisible = true;
      } else {
        this.location_department_data.department[itemIndex].isVisible = false;
      }
    });
  }

  // Returns departments data by filtering based on selected locations and search keyword.
  public filterDepartments(arr: DepartmentType[]) {
    const checkedLocations = this.location_department_data.location.filter(
      (item) => item.isChecked
    );

    if (checkedLocations.length)
      return arr.filter(
        (item) =>
          (item?.isVisible === undefined || item?.isVisible === true) &&
          checkedLocations
            .map((subItem) => subItem.location)
            .includes(item.location)
      );
    else
      return arr.filter(
        (item) => item?.isVisible === undefined || item?.isVisible === true
      );
  }

  // Define action for user inputs in Search Driver field.
  public searcherInput(value: string) {
    this.query.filters!.search = value;
    this.query.page = 1;

    // It fetech data after 0.5 seconds when user input stops
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.fetchData();
    }, 500);
  }

  // Define action for when user remove filters for selected locations and departments
  public handleRemoveFilter(
    key: 'location' | 'department',
    value: { label: string; id: string }
  ) {
    this.query.page = 1;
    this.filteredData[key] = this.filteredData[key].filter(
      (item) => item.id !== value.id
    );

    const uncheckedIndex = this.location_department_data[key].findIndex(
      (item) =>
        key === 'location'
          ? item.location === value.id
          : `${item.location}-${item.client}-${item.depot}` === value.id
    );
    this.location_department_data[key][uncheckedIndex].isChecked = false;
    this.fetchData();
  }

  // Remove all filters including search keyword in Search Driver field.
  public handleClearAllFilters() {
    this.query.page = 1;
    this.filteredData = { location: [], department: [] };
    this.query.filters!.search = '';
    this.location_department_data.location =
      this.location_department_data.location.map((item) => ({
        ...item,
        isChecked: false,
      }));
    this.location_department_data.department =
      this.location_department_data.department.map((item) => ({
        ...item,
        isChecked: false,
      }));

    this.fetchData();
  }

  // Clear all checks from filter modal
  public handleClearAllChecked() {
    this.location_department_data.location =
      this.location_department_data.location.map((item) => ({
        ...item,
        isChecked: false,
      }));
    this.location_department_data.department =
      this.location_department_data.department.map((item) => ({
        ...item,
        isChecked: false,
      }));
  }

  // Set isChecked value based on user control in filter modal for location and department checkboxes.
  public setFilters(
    checked: boolean,
    key: 'location' | 'department',
    value: DepartmentType
  ) {
    const selectedIndex =
      key === 'location'
        ? this.location_department_data.location.findIndex(
            (item) => item.location === value.location
          )
        : this.location_department_data.department.findIndex(
            (item) =>
              item.location === value.location &&
              item.depot === value.depot &&
              item.client === value.client
          );
    this.location_department_data[key][selectedIndex].isChecked = checked;
  }

  public handleSort() {
    this.sortObj[this.sortedColumn.columnName as keyof SortedPODObj] =
      !this.sortObj[this.sortedColumn.columnName as keyof SortedPODObj];

    const index = this.sortOrder.findIndex(
      (item) => item === this.sortedColumn.columnName
    );
    this.sortOrder = [
      ...this.sortOrder.slice(0, index),
      ...this.sortOrder.slice(index + 1),
    ];
    this.sortOrder.push(this.sortedColumn.columnName as keyof SortedPODObj);
    // this.reset();
    this.query.page = 1;
    // this.isRefreshWholeData = true;
    this.fetchData();
  }
}

// POD table column definition
const columnDefinition: TableColumn[] = [
  {
    label: 'Driver',
    slug: 'user',
    keyValue: 'driver',
    sortable: true,
    accent: true,
    accessor: (i: ProofOfDelivery) => i.driver,
  },
  {
    label: 'Date',
    slug: 'user',
    keyValue: 'date',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.date,
  },
  {
    label: 'Location',
    slug: 'user',
    keyValue: 'location',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.location,
  },
  {
    label: 'Department',
    slug: 'user',
    keyValue: 'department',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.department,
  },
  {
    label: 'Address',
    slug: 'user',
    keyValue: 'address',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.address,
  },
  {
    label: 'Stop Type',
    slug: 'user',
    keyValue: 'stop_type',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.stop_type,
  },
  {
    label: 'Arrival Time',
    slug: 'user',
    keyValue: 'arrival_time',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.arrival_time,
  },
  {
    label: 'Consignment Number',
    slug: 'user',
    keyValue: 'consignment_number',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.consignment_number,
  },
  {
    label: 'Business Name',
    slug: 'user',
    keyValue: 'business_name',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.business_name,
  },
  {
    label: 'Recipient Full Name',
    slug: 'user',
    keyValue: 'recipient_name',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.recipient_name,
  },
  {
    label: 'Contact Number',
    slug: 'user',
    keyValue: 'contact_number',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.contact_number,
  },
  {
    label: 'Stop Result',
    slug: 'user',
    keyValue: 'stop_result',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.stop_result,
  },
  {
    label: 'Stop Outcome',
    slug: 'user',
    keyValue: 'stop_outcome',
    sortable: true,
    accessor: (i: ProofOfDelivery) => i.stop_outcome,
  },
  {
    label: 'Attachments',
    slug: 'user',
    sortable: false,
    accessor: (i: ProofOfDelivery) => i.attachment,
  },
  // {
  //   label: 'Data',
  //   slug: 'data',
  //   sortable: false,
  //   dataType: 'actions',
  // actions: [
  //   {
  //     label: 'Show Data',
  //     action: (i: ProofOfDelivery) => {
  //       this.dialog.open(JsonDataComponent, { data: i.data });
  //     },
  //     condition: (i: ProofOfDelivery) => i.data,
  //   },
  // ],
  // },
];
