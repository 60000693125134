import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss']
})
export class FormTextareaComponent implements OnInit {

  @Input() isLabel: boolean = false;
  @Input() labelText: string = 'Label text';
  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() classes: Array<string> = [];
  @Input() model: any;
  @Input() isDisabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() imgSrc: string = '';
  @Input() isError: boolean = false
  
  @Output() modelChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public change(newValue: any) {
    this.model = newValue;
    this.modelChange.emit(newValue);
  }

}
