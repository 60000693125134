import { Directive, HostListener, ElementRef, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChange } from '@angular/core';

@Directive({
  selector: '[appEpValidator]'
})
export class EpValidatorDirective implements OnChanges {

  @Input('appEpValidator') validTyp: Array<string> =[];
  @Input('fieldName') fieldName: string = '';
  @Output() validInfoEvent = new EventEmitter();
  @Output() destroyElement = new EventEmitter();
  @Output('validateOnInit') initEvent: EventEmitter<any> = new EventEmitter();
  @Input('fieldValue') fieldValue: any;

  private intervalStop: any;
  private intervalIndex: number = 1;


  constructor(private el: ElementRef) {

  }

  ngAfterContentInit() {
    let self = this;
    this.intervalStop = setInterval(function () {
      self.intervalIndex++;
      if (self.intervalIndex == 10 || self.el.nativeElement.value.length > 0) {
        self.validateFormField();
        clearInterval(self.intervalStop);
      }
    }, 200);
  }

  ngOnDestroy() {
    this.destroyElement.emit(this.fieldName);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    this.validateFormField();
  }



  private validateFormField() {

    let self = this;

    setTimeout(function () {
      let value: string = self.el.nativeElement.value;
      self.fieldValue = value;
      let validStatus = self.validate(value);
      if (!validStatus) {
        self.el.nativeElement.style = 'border: 1px solid #D43838';
      } else {
        self.el.nativeElement.style = '';
      }
      if (self.fieldName) {
        let validField = {
          'fieldName': self.fieldName,
          'isValid': validStatus
        }
        self.initEvent.emit(validField);
      }
    }, 500);
  }

  @HostListener('input') onInput() {
    this.validateFormField();
  }

  private validate(_value: any) {

    if (this.validTyp.indexOf('required') != -1) {
      if (_value === null || _value === undefined) {
        return false;
      }
      if (_value.length === 0) {
        return false;
      }
    }

    if (this.validTyp.indexOf('max255') != -1) {
      if (_value.length > 255) {
        return false;
      }
    }
    if (this.validTyp.indexOf('number') != -1) {
      let numberValid = Number(_value);
      if (isNaN(numberValid)) {
        return false;
      }
    }
    if (this.validTyp.indexOf('nip') != -1) {
      return this.validNip(_value.replace(/-/g, ''));
    }
    if (this.validTyp.indexOf('pesel') != -1) {
      if (_value.length > 0) {
        return this.validPesel(_value);
      }
    }
    if (this.validTyp.indexOf('nip_pesel') != -1) {
    }
    if (this.validTyp.indexOf('regon') != -1) {
      if (_value.length > 0) {
        return this.validRegon(_value);
      }
    }
    if (this.validTyp.indexOf('phone') != -1) {
      if (_value.length > 0) {
        let numberValid = Number(_value);
        if (isNaN(numberValid)) {
          return false;
        }
      }
    }
    if (this.validTyp.indexOf('email') != -1) {
      if (_value.length !== 0) {
        let regexpMail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let isMail = regexpMail.test(_value);
        return isMail;
      }
    }
    if (this.validTyp.indexOf('bankAcc') != -1) {
      let valueNoWhiteSpace = _value.replace(/\s/g, "");
      let numberValid = Number(valueNoWhiteSpace);
      if (valueNoWhiteSpace.length !== 0) {
        if (valueNoWhiteSpace.length !== 26 || isNaN(numberValid)) {
          return false;
        }
      }
    }
    return true;
  }

  public validNip(_nip: string): boolean {
    if (_nip === null || _nip === undefined)
      return false;
    if (_nip.length != 10)
      return false;

    let nipWages = [6, 5, 7, 2, 3, 4, 5, 6, 7]
    let nipData = [];
    let nipRatio = [];

    nipData[0] = Number(_nip[0]);
    nipData[1] = Number(_nip[1]);
    nipData[2] = Number(_nip[2]);
    nipData[3] = Number(_nip[3]);
    nipData[4] = Number(_nip[4]);
    nipData[5] = Number(_nip[5]);
    nipData[6] = Number(_nip[6]);
    nipData[7] = Number(_nip[7]);
    nipData[8] = Number(_nip[8]);
    nipData[9] = Number(_nip[9]);

    nipRatio[0] = Number(nipData[0] * nipWages[0]);
    nipRatio[1] = Number(nipData[1] * nipWages[1]);
    nipRatio[2] = Number(nipData[2] * nipWages[2]);
    nipRatio[3] = Number(nipData[3] * nipWages[3]);
    nipRatio[4] = Number(nipData[4] * nipWages[4]);
    nipRatio[5] = Number(nipData[5] * nipWages[5]);
    nipRatio[6] = Number(nipData[6] * nipWages[6]);
    nipRatio[7] = Number(nipData[7] * nipWages[7]);
    nipRatio[8] = Number(nipData[8] * nipWages[8]);

    let sum: number = Number(nipRatio[0] + nipRatio[1] + nipRatio[2] + nipRatio[3] + nipRatio[4] + nipRatio[5] + nipRatio[6] + nipRatio[7] + nipRatio[8]);
    let modulo: number = Number(sum % 11);
    if (modulo != nipData[9])
      return false;

    return true;
  }

  public validPesel(_pesel: string): boolean {
    if (_pesel.length != 0) {

      if (_pesel.length != 11)
        return false;

      let peselWages = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
      let peselData = [];
      let peselRatio = [];

      peselData[0] = Number(_pesel[0]);
      peselData[1] = Number(_pesel[1]);
      peselData[2] = Number(_pesel[2]);
      peselData[3] = Number(_pesel[3]);
      peselData[4] = Number(_pesel[4]);
      peselData[5] = Number(_pesel[5]);
      peselData[6] = Number(_pesel[6]);
      peselData[7] = Number(_pesel[7]);
      peselData[8] = Number(_pesel[8]);
      peselData[9] = Number(_pesel[9]);
      peselData[10] = Number(_pesel[10]);

      peselRatio[0] = Number(peselData[0] * peselWages[0]);
      peselRatio[1] = Number(peselData[1] * peselWages[1]);
      peselRatio[2] = Number(peselData[2] * peselWages[2]);
      peselRatio[3] = Number(peselData[3] * peselWages[3]);
      peselRatio[4] = Number(peselData[4] * peselWages[4]);
      peselRatio[5] = Number(peselData[5] * peselWages[5]);
      peselRatio[6] = Number(peselData[6] * peselWages[6]);
      peselRatio[7] = Number(peselData[7] * peselWages[7]);
      peselRatio[8] = Number(peselData[8] * peselWages[8]);
      peselRatio[9] = Number(peselData[9] * peselWages[9]);


      let sum: number = Number(peselRatio[0] + peselRatio[1] + peselRatio[2] + peselRatio[3] + peselRatio[4] + peselRatio[5] + peselRatio[6] + peselRatio[7] + peselRatio[8] + peselRatio[9]);
      let modulo: number = Number(sum % 10);
      if (modulo != peselData[10])
        return false;
    }
    return true;
  }

  public validRegon(_regon: string): boolean {

    if (_regon === null || _regon === undefined)
      return false;



    if (_regon.length != 9 && _regon.length != 14)
      return false;

    let regon9Wages = [8, 9, 2, 3, 4, 5, 6, 7];
    let regon14Wages = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
    let regon9Data = [];
    let regon14Data = [];
    let regon9Ratio = [];
    let regon14Ratio = [];

    if (_regon.length === 9) {
      regon9Data[0] = Number(_regon[0]);
      regon9Data[1] = Number(_regon[1]);
      regon9Data[2] = Number(_regon[2]);
      regon9Data[3] = Number(_regon[3]);
      regon9Data[4] = Number(_regon[4]);
      regon9Data[5] = Number(_regon[5]);
      regon9Data[6] = Number(_regon[6]);
      regon9Data[7] = Number(_regon[7]);
      regon9Data[8] = Number(_regon[8]);

      regon9Ratio[0] = Number(regon9Data[0] * regon9Wages[0]);
      regon9Ratio[1] = Number(regon9Data[1] * regon9Wages[1]);
      regon9Ratio[2] = Number(regon9Data[2] * regon9Wages[2]);
      regon9Ratio[3] = Number(regon9Data[3] * regon9Wages[3]);
      regon9Ratio[4] = Number(regon9Data[4] * regon9Wages[4]);
      regon9Ratio[5] = Number(regon9Data[5] * regon9Wages[5]);
      regon9Ratio[6] = Number(regon9Data[6] * regon9Wages[6]);
      regon9Ratio[7] = Number(regon9Data[7] * regon9Wages[7]);

      let sum: number = Number(regon9Ratio[0] + regon9Ratio[1] + regon9Ratio[2] + regon9Ratio[3] + regon9Ratio[4] + regon9Ratio[5] + regon9Ratio[6] + regon9Ratio[7]);
      let modulo: number = Number(sum % 11);
      if (modulo != regon9Data[8] && modulo != 10)
        return false;
    }

    if (_regon.length === 14) {
      regon14Data[0] = Number(_regon[0]);
      regon14Data[1] = Number(_regon[1]);
      regon14Data[2] = Number(_regon[2]);
      regon14Data[3] = Number(_regon[3]);
      regon14Data[4] = Number(_regon[4]);
      regon14Data[5] = Number(_regon[5]);
      regon14Data[6] = Number(_regon[6]);
      regon14Data[7] = Number(_regon[7]);
      regon14Data[8] = Number(_regon[8]);
      regon14Data[9] = Number(_regon[9]);
      regon14Data[10] = Number(_regon[10]);
      regon14Data[11] = Number(_regon[11]);
      regon14Data[12] = Number(_regon[12]);
      regon14Data[13] = Number(_regon[13]);

      regon14Ratio[0] = Number(regon14Data[0] * regon14Wages[0]);
      regon14Ratio[1] = Number(regon14Data[1] * regon14Wages[1]);
      regon14Ratio[2] = Number(regon14Data[2] * regon14Wages[2]);
      regon14Ratio[3] = Number(regon14Data[3] * regon14Wages[3]);
      regon14Ratio[4] = Number(regon14Data[4] * regon14Wages[4]);
      regon14Ratio[5] = Number(regon14Data[5] * regon14Wages[5]);
      regon14Ratio[6] = Number(regon14Data[6] * regon14Wages[6]);
      regon14Ratio[7] = Number(regon14Data[7] * regon14Wages[7]);
      regon14Ratio[8] = Number(regon14Data[8] * regon14Wages[8]);
      regon14Ratio[9] = Number(regon14Data[9] * regon14Wages[9]);
      regon14Ratio[10] = Number(regon14Data[10] * regon14Wages[10]);
      regon14Ratio[11] = Number(regon14Data[11] * regon14Wages[11]);
      regon14Ratio[12] = Number(regon14Data[12] * regon14Wages[12]);

      let sum: number = Number(regon14Ratio[0] + regon14Ratio[1] + regon14Ratio[2] + regon14Ratio[3] + regon14Ratio[4] + regon14Ratio[5] + regon14Ratio[6] + regon14Ratio[7] + regon14Ratio[8] + regon14Ratio[9] +
        regon14Ratio[10] + regon14Ratio[11] + regon14Ratio[12]);
      let modulo: number = Number(sum % 11);
      if (modulo != regon14Data[13])
        return false;
    }
    return true;
  }

  public validNipPesel(_nipPesel: any) {
    if (_nipPesel === null || _nipPesel === undefined)
      return false;
    if (_nipPesel.length != 11 && _nipPesel.length != 10)
      return false;


    if (_nipPesel.length == 11) {
      return this.validPesel(_nipPesel);
    } else if (_nipPesel.length === 10) {
      return this.validNip(_nipPesel);
    }
    return false;
  }
}
