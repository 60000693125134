import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { MappingDataType } from 'src/app/model/custm/Setting';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { SettingsAPI } from 'src/app/services/custm/settings.service';

@Component({
  selector: 'app-edit-inventory',
  templateUrl: './edit-inventory.component.html',
  styleUrls: ['./edit-inventory.component.scss'],
})
export class EditInventoryComponent implements OnInit {
  public inventoryCodeSetForm!: FormGroup;
  public roles: MappingDataType[] = [];
  public loadingCharges: MappingDataType[] = [];

  constructor(
    private settingsApi: SettingsAPI,
    private formBuilder: FormBuilder
  ) {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.settingsApi
      .getRoles({
        page: 1,
        perPage: 1000,
        filters: {
          search: '',
        },
      })
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);
        this.roles = res.data;
        this.roles.forEach((item) => {
          this.vehiclesFormArray.push(
            this.formBuilder.group({
              id: item.id,
              name: item.name,
              inventory_code: [
                item?.inventory_code ?? '',
                Validators.maxLength(20),
              ],
            })
          );
        });
      });
    this.settingsApi.getLoadingCharges().subscribe((r) => {
      let res = CodedResponseModel.decode(r);
      this.loadingCharges = res.data;
      this.loadingCharges.forEach((item) => {
        this.loadingChargesFormArray.push(
          this.formBuilder.group({
            id: item.id,
            name: item.name,
            inventory_code: [
              item?.inventory_code ?? '',
              Validators.maxLength(20),
            ],
          })
        );
      });
    });
  }

  initializeForm() {
    // Set up the client form with initialisation data
    this.inventoryCodeSetForm = this.formBuilder.group({
      vehicles: this.formBuilder.array([]),
      loading_charges: this.formBuilder.array([]),
    });
  }

  // Set up get methods especially for FormArrays to interact easily.
  get vehiclesFormArray(): FormArray {
    return this.inventoryCodeSetForm.get('vehicles') as FormArray;
  }
  get loadingChargesFormArray(): FormArray {
    return this.inventoryCodeSetForm.get('loading_charges') as FormArray;
  }

  isControlInValidInGroup(
    formArray: FormArray,
    groupIndex: number,
    controlName: string
  ): boolean {
    const formGroup = formArray.at(groupIndex) as FormGroup;
    const control = formGroup.get(controlName);
    return (
      (control?.invalid ?? false) &&
      ((control?.dirty ?? false) || (control?.touched ?? false))
    );
  }

  save() {
    const formValues = this.inventoryCodeSetForm.value;
    if (this.inventoryCodeSetForm.invalid)
      return NotificatorPartial.push({
        type: 'error',
        message: 'There are some innvalid inputs',
        timeout: 3000,
      });
    this.settingsApi.updateInventoryCodes(formValues).subscribe(
      (r) => {
        NotificatorPartial.push({
          type: 'success',
          message: `Successfully saved`,
          timeout: 3000,
        });
      },
      (err) => {
        NotificatorPartial.push({
          type: 'error',
          message: err.error.message,
          timeout: 3000,
        });
      }
    );
  }
}
