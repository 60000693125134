import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmModalInterface{
    message:string;
    button1?:string;
    button2?:string;
}

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

    constructor(
        private ref:MatDialogRef<ConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data:ConfirmModalInterface
    ){}

    ngOnInit():void{}

    public close(v:boolean){
        this.ref.close(v);
    }
}
