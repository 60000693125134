import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import {
  FunctionalityItemType,
  PermissionObjType,
  RolesPermissionsType,
  USER_ROLES,
} from 'src/app/model/custm/Setting';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { SettingsAPI } from 'src/app/services/custm/settings.service';

@Component({
  selector: 'app-edit-access',
  templateUrl: './edit-access.component.html',
  styleUrls: ['./edit-access.component.scss'],
})
export class EditAccessComponent implements OnInit {
  public WEB_APPLICATION_FUNCTIONALITY: FunctionalityItemType[] = [
    {
      functionality: 'Users',
      depth: 0,
      permission: 'full:users',
      parent: '',
      children: [
        {
          functionality: 'Create New',
          depth: 1,
          permission: 'create:users',
          parent: 'Users',
          children: [],
        },
        {
          functionality: 'Edit',
          depth: 1,
          permission: 'edit:users',
          parent: 'Users',
          children: [
            {
              functionality: 'Assign Locations, Departments and Roles',
              depth: 2,
              permission: 'edit-mapping:users',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Edit custom pay rates',
              depth: 2,
              permission: 'edit-rates:users',
              parent: 'Edit',
              children: [],
            },
          ],
        },
        {
          functionality: 'Send Shift Notifs',
          depth: 1,
          permission: 'send-notifs:users',
          parent: 'Users',
          children: [],
        },
        {
          functionality: 'Delete',
          depth: 1,
          permission: 'delete:users',
          parent: 'Users',
          children: [],
        },
        {
          functionality: 'Reset Password',
          depth: 1,
          permission: 'reset-password:users',
          parent: 'Users',
          children: [],
        }
      ],
    },
    {
      functionality: 'Clients',
      depth: 0,
      permission: 'full:clients',
      parent: '',
      children: [
        {
          functionality: 'Create New',
          depth: 1,
          permission: 'create:clients',
          parent: 'Clients',
          children: [],
        },
        {
          functionality: 'Edit',
          depth: 1,
          permission: 'edit:clients',
          parent: 'Clients',
          children: [
            {
              functionality: 'Details',
              depth: 2,
              permission: 'edit-details:clients',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Add & Edit Operations Details',
              depth: 2,
              permission: 'edit-operations:clients',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Select & Edit Charging Terms',
              depth: 2,
              permission: 'edit-terms:clients',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Force Proof of Delivery?',
              depth: 2,
              permission: 'edit-fpod:clients',
              parent: 'Edit',
              children: [],
            },
          ],
        },
        {
          functionality: 'Delete',
          depth: 1,
          permission: 'delete:clients',
          parent: 'Clients',
          children: [],
        },
      ],
    },
    {
      functionality: 'Emails',
      depth: 0,
      permission: 'full:emails',
      parent: '',
      children: [
        {
          functionality: 'Create New',
          depth: 1,
          permission: 'create:emails',
          parent: 'Emails',
          children: [],
        },
        {
          functionality: 'Edit',
          depth: 1,
          permission: 'edit:emails',
          parent: 'Emails',
          children: [],
        },
        {
          functionality: 'Delete',
          depth: 1,
          permission: 'delete:emails',
          parent: 'Emails',
          children: [],
        },
      ],
    },
    {
      functionality: 'Depots',
      depth: 0,
      permission: 'full:depots',
      parent: '',
      children: [
        {
          functionality: 'Create New',
          depth: 1,
          permission: 'create:depots',
          parent: 'Depots',
          children: [],
        },
        {
          functionality: 'Edit',
          depth: 1,
          permission: 'edit:depots',
          parent: 'Depots',
          children: [],
        },
        {
          functionality: 'Delete',
          depth: 1,
          permission: 'delete:depots',
          parent: 'Depots',
          children: [],
        },
      ],
    },
    {
      functionality: 'Location Data (Beta)',
      depth: 0,
      permission: 'full:location',
      parent: '',
      children: [],
    },
    {
      functionality: 'Posts',
      depth: 0,
      permission: 'full:posts',
      parent: '',
      children: [
        {
          functionality: 'Create New',
          depth: 1,
          permission: 'create:posts',
          parent: 'Posts',
          children: [],
        },
        {
          functionality: 'Edit',
          depth: 1,
          permission: 'edit:posts',
          parent: 'Posts',
          children: [],
        },
        {
          functionality: 'Delete',
          depth: 1,
          permission: 'delete:posts',
          parent: 'Posts',
          children: [],
        },
      ],
    },
    {
      functionality: 'Logs',
      depth: 0,
      permission: 'full:logs',
      parent: '',
      children: [
        {
          functionality: 'Show Data',
          depth: 1,
          permission: 'view:logs',
          parent: 'Logs',
          children: [],
        },
        {
          functionality: 'Revert',
          depth: 1,
          permission: 'revert:logs',
          parent: 'Logs',
          children: [
            // {
            //   functionality: 'Unfit for Duty',
            //   depth: 2,
            //   permission: 'unfit4duty:logs',
            //   parent: 'Revert',
            //   children: [],
            // },
            {
              functionality: 'Manifest Accepted',
              depth: 2,
              permission: 'revert:begun',
              parent: 'Revert',
              children: [],
            },
            {
              functionality: 'Shift Cancelled',
              depth: 2,
              permission: 'revert:cancelled',
              parent: 'Revert',
              children: [],
            },
          ],
        },
        {
          functionality: 'Incident Pending (Cancel Shift, Clear Incident)',
          depth: 1,
          permission: 'incident:logs',
          parent: 'Revert',
          children: [
            {
              functionality: 'Injury',
              depth: 2,
              permission: 'incident-injury:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Property Damage',
              depth: 2,
              permission: 'incident-property-damage:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Critical Impact',
              depth: 2,
              permission: 'incident-critical-impact:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Theft',
              depth: 2,
              permission: 'incident-theft:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Fraudulent Activity',
              depth: 2,
              permission: 'incident-fraudulent-activity:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Near Miss',
              depth: 2,
              permission: 'incident-near-miss:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Non conformance',
              depth: 2,
              permission: 'incident-non-conformance:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Environmental',
              depth: 2,
              permission: 'incident-environmental:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Workplace Hazard',
              depth: 2,
              permission: 'incident-workplace-hazard:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Reporting Property Damage',
              depth: 2,
              permission: 'incident-reporting-property-damage:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Hazardous Practice',
              depth: 2,
              permission: 'incident-hazardous-practice:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Opportunity for Improvement',
              depth: 2,
              permission: 'opportunity-for-improvement:logs',
              parent: 'Incident Pending',
              children: [],
            },
            {
              functionality: 'Other Incident',
              depth: 2,
              permission: 'incident-other-incident:logs',
              parent: 'Incident Pending',
              children: [],
            },
          ],
        },
      ],
    },
    {
      functionality: 'Summaries',
      depth: 0,
      permission: 'full:summaries',
      parent: '',
      children: [],
    },
    {
      functionality: 'POD',
      depth: 0,
      permission: 'full:pod',
      parent: '',
      children: [],
    },
    {
      functionality: 'Timesheets',
      depth: 0,
      permission: 'full:timesheets',
      parent: '',
      children: [
        {
          functionality: 'Create New',
          depth: 1,
          permission: 'create:timesheets',
          parent: 'Timesheets',
          children: [],
        },
        {
          functionality: 'Export',
          depth: 1,
          permission: 'export:timesheets',
          parent: 'Timesheets',
          children: [],
        },
        {
          functionality: 'Upload',
          depth: 1,
          permission: 'upload:timesheets',
          parent: 'Timesheets',
          children: [],
        },
        {
          functionality: 'Edit',
          depth: 1,
          permission: 'edit:timesheets',
          parent: 'Timesheets',
          children: [
            {
              functionality: 'Pay In',
              depth: 2,
              permission: 'edit-pay-in:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Pay Out',
              depth: 2,
              permission: 'edit-pay-out:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Breaks',
              depth: 2,
              permission: 'edit-breaks:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Attempted Deliveries',
              depth: 2,
              permission: 'edit-attempted-deliveries:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Attempted Deliveries Paid',
              depth: 2,
              permission: 'edit-attempted-deliveries-paid:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Pickups',
              depth: 2,
              permission: 'edit-pickups:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Shift Definition',
              depth: 2,
              permission: 'edit-shift-definition:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Regional',
              depth: 2,
              permission: 'edit-regional:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Tolls',
              depth: 2,
              permission: 'edit-tolls:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Status',
              depth: 2,
              permission: 'edit-status:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Authorise',
              depth: 2,
              permission: 'edit-authorise:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Unauthorise',
              depth: 2,
              permission: 'edit-unauthorise:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Add/Edit Roster Comment',
              depth: 2,
              permission: 'edit-add/edit-roster-comment:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Add/Edit Timesheet Comment',
              depth: 2,
              permission: 'edit-add/edit-timesheet-comment:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Delete Timesheet',
              depth: 2,
              permission: 'edit-delete-timesheet:timesheets',
              parent: 'Edit',
              children: [],
            },
            {
              functionality: 'Process Payments',
              depth: 2,
              permission: 'edit-process-payments:timesheets',
              parent: 'Edit',
              children: [],
            },
          ],
        },
      ],
    },
    {
      functionality: 'Offers',
      depth: 0,
      permission: 'full:offers',
      parent: '',
      children: [],
    },
    {
      functionality: 'Settings',
      depth: 0,
      permission: 'full:settings',
      parent: '',
      children: [
        {
          functionality: 'Geofence Trigger Radius',
          depth: 1,
          permission: 'gtr:settings',
          parent: 'Settings',
          children: [],
        },
        {
          functionality: 'Special Workflow',
          depth: 1,
          permission: 'spec-workflow:settings',
          parent: 'Settings',
          children: [],
        },
        {
          functionality: 'Return to Depot Extra Time',
          depth: 1,
          permission: 'depot-extra-time:settings',
          parent: 'Settings',
          children: [],
        },
        {
          functionality: 'Location, Department & Role Mappings',
          depth: 1,
          permission: 'mappings:settings',
          parent: 'Settings',
          children: [],
        },
        {
          functionality: 'Location Tracking Data Export',
          depth: 1,
          permission: 'tracking-export:settings',
          parent: 'Settings',
          children: [],
        },
        {
          functionality: 'Edit Locations',
          depth: 1,
          permission: 'edit-locations:settings',
          parent: 'Settings',
          children: [],
        },
        {
          functionality: 'Edit Departments',
          depth: 1,
          permission: 'edit-departments:settings',
          parent: 'Settings',
          children: [],
        },
        {
          functionality: 'Edit Roles',
          depth: 1,
          permission: 'edit-roles:settings',
          parent: 'Settings',
          children: [],
        },
        {
          functionality: 'Edit User Access Levels',
          depth: 1,
          permission: 'uac:settings',
          parent: 'Settings',
          children: [],
        },
      ],
    },
  ];

  public permissionObj: {
    Admin: PermissionObjType;
    Accounting: PermissionObjType;
    Operations: PermissionObjType;
    Compliance: PermissionObjType;
    Command: PermissionObjType;
  } = {
    Admin: {},
    Accounting: {},
    Operations: {},
    Compliance: {},
    Command: {},
  };

  public loading: boolean = false;
  public roles_permissions: RolesPermissionsType[] = [];

  constructor(private settingsApi: SettingsAPI) {}

  ngOnInit(): void {
    this.fetchData();
  }

  public fetchData() {
    this.loading = true;
    this.settingsApi.getRolesAndPermissions().subscribe((r) => {
      let res: RolesPermissionsType[] = CodedResponseModel.decode(r);
      let _obj = {};
      res.forEach((item) => {
        _obj = { ..._obj, [item.name]: _.groupBy(item.permissions, 'name') };
        return true;
      });
      Object.keys(this.permissionObj).forEach((obj: string) => {
        this.generatePermissionObj(
          obj as USER_ROLES,
          this.WEB_APPLICATION_FUNCTIONALITY,
          _obj
        );
        return true;
      });
      
      this.loading = false;
    });
  }

  private generatePermissionObj(
    role: USER_ROLES,
    data: FunctionalityItemType[],
    permissionObj: any,
    parent?: string,
    _parents?: string[]
  ) {
    data.forEach((item) => {
      let parents: string[] = [];
      if (_parents?.length) parents = [..._parents];
      if (parent) parents.push(parent);
      this.permissionObj[role] = {
        ...this.permissionObj[role],
        [item.permission]: {
          checked: Boolean(permissionObj?.[role]?.[item.permission]),
          parents,
        },
      };
      if (item.children)
        this.generatePermissionObj(
          role,
          item.children,
          permissionObj,
          item.permission,
          parents
        );
      return true;
    });
  }

  store() {
    let payload = {};
    Object.keys(this.permissionObj).forEach((obj) => {
      const permissonObj = this.permissionObj[obj as USER_ROLES];
      const permissions = Object.keys(permissonObj).flatMap((permission) => {
        if (permissonObj[permission].checked) return [permission];
        return [];
      });
      payload = { ...payload, [obj]: permissions };
      return true;
    });
    this.settingsApi.setRolesAndPermissions(payload).subscribe(
      (r) => {
        NotificatorPartial.push({
          type: 'success',
          message: 'Successfully saved',
          timeout: 3000,
        });
      },
      (err) => {
        NotificatorPartial.push({
          type: 'error',
          message: err.error.message,
          timeout: 3000,
        });
      }
    );
  }

  handleChangePermission(
    checked: boolean,
    role: USER_ROLES,
    permission: string
  ) {
    Object.keys(this.permissionObj[role]).forEach((_permission) => {
      if (this.permissionObj[role][_permission].parents.includes(permission))
        this.permissionObj[role][_permission].checked = checked;
      return true;
    });
    if (!checked)
      this.permissionObj[role][permission].parents.forEach((parent) => {
        this.permissionObj[role][parent].checked = checked;
        return true;
      });
    if (checked) {
      const _parents = this.permissionObj[role][permission].parents;
      _parents.forEach((parent) => {
        let _checked = true;
        Object.keys(this.permissionObj[role]).forEach((_permission) => {
          if (
            this.permissionObj[role][_permission].parents.includes(parent) &&
            !_parents.includes(_permission)
          )
            _checked =
              _checked && this.permissionObj[role][_permission].checked;
          return true;
        });
        this.permissionObj[role][parent].checked = _checked;
        return true;
      });
    }
  }
}
