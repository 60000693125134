import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  @Input() screenCoordinate!: { x: number; y: number };
  @Input() popupWndSize!: { width: number; height: number };
  @Input() isVisible!: boolean;

  public position = { x: 0, y: 0 };
  public popupTipPosition: number = 0;
  constructor() {}

  ngOnInit(): void {
    const { innerWidth, innerHeight } = window;
    this.position.x = this.screenCoordinate.x - this.popupWndSize.width / 2;
    this.position.y =
      this.screenCoordinate.y - this.popupWndSize.height / 2 - 20;

    if (
      this.screenCoordinate.x + this.popupWndSize.width / 2 + 10 >
      innerWidth
    ) {
      this.position.x = this.screenCoordinate.x - this.popupWndSize.width - 10;
      this.popupTipPosition = 1;
    }
    if (this.screenCoordinate.x - this.popupWndSize.width / 2 - 10 < 0) {
      this.position.x = this.screenCoordinate.x + 10;
      this.popupTipPosition = 2;
    }

    if (this.screenCoordinate.y - this.popupWndSize.height / 2 - 20 < 0) {
      this.position.y =
        this.screenCoordinate.y + this.popupWndSize.height / 2 + 20;
    }
  }
}
