<div class="shifts-container">
    <div class="top">
        <h1>Shift status</h1>
        <div class="controls">
            <div class="counters">
                <span>Totals:</span>
                <div class="counter green">Accepted ({{counters.accepted}})</div>
                <div class="counter red">Rejected ({{counters.rejected}})</div>
                <div class="counter blue">No response ({{counters.no_response}})</div>
            </div>
            <c-select [options]="dayOptions" [selected]="dayOptions[0]" (modelChange)="setDayOffset($event)"></c-select>
        </div>
    </div>
    <c-table [columns]="tableDef" [data]="data" [loading]="loading"></c-table>
</div>