<div class="input_cont" [ngClass]="classes">
    <label *ngIf="isLabel">
    {{ labelText }}
  </label>
    <div class="input_wrap" [class.error]="isError">
        <ng-container *ngIf="max>0">
            <input [type]="type" [ngModel]="model" class="input_cont" (ngModelChange)="change($event)" [disabled]="isDisabled" [readonly]="isReadOnly" [placeholder]="placeholder" [max]="max" #inputEl />
        </ng-container>
        <ng-container *ngIf="max==0">
            <input [type]="type" [ngModel]="model" class="input_cont" (ngModelChange)="change($event)" [disabled]="isDisabled" [readonly]="isReadOnly" [placeholder]="placeholder" #inputEl />
        </ng-container>
        
        <img [src]="imgSrc" class="left_aligned_icon" *ngIf="imgSrc" />
    </div>
</div>