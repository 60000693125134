import { User, user_interface } from '../User';
import * as moment from 'moment';

interface ShiftDataType {
  location: string;
  depot: string;
  client: string;
  vehicle: string;
  created_at: string;
}

export interface DepartmentType {
  depot: string;
  client: string;
  location: string;
  isChecked: boolean;
  isVisible: boolean;
}

export interface SortedPODObj {
  driver: boolean;
  date: boolean;
  location: boolean;
  department: boolean;
  address: boolean;
  stop_type: boolean;
  arrival_time: boolean;
  consignment_number: boolean;
  business_name: boolean;
  recipient_name: boolean;
  contact_number: boolean;
  stop_result: boolean;
  stop_outcome: boolean;
}

export interface ProofOfDeliveryResponse {
  id: number;
  user_id: number;
  shift_id: number;
  address: string;
  stop_type: string;
  arrival_time: string;
  consignment_number: string;
  business_name: string;
  recipient_name: string;
  contact_number: string;
  stop_result: string;
  stop_outcome: string;
  attachment: any;

  users: user_interface;
  job_history: ShiftDataType;

  created_at: string;
  updated_at: string;
}

export class ProofOfDelivery {
  public id: number = 0;
  public userId: number = 0;
  public shiftId: number = 0;
  public driver: string = '';
  public date: string = '';
  public origin_date: Date = new Date();
  public location: string = '';
  public department: string = '';
  public address: string = '';
  public stop_type: string = '';
  public arrival_time: string = '';
  public consignment_number: string = '';
  public business_name: string = '';
  public recipient_name: string = '';
  public contact_number: string = '';
  public stop_result: string = '';
  public stop_outcome: string = '';
  public attachment: string[] = [];

  public map(data: ProofOfDeliveryResponse) {
    this.id = data.id;
    this.userId = data.user_id;
    this.shiftId = data.shift_id;
    this.address = data?.address ?? '';
    this.stop_type = data?.stop_type ?? '';
    this.origin_date = new Date(data.job_history.created_at);
    this.date = moment(new Date(data.job_history.created_at)).format(
      'DD/MM/YY'
    );
    this.arrival_time = data?.arrival_time
      ? moment.utc(new Date(data.arrival_time)).local().format('h:mm A')
      : '';
    this.consignment_number = data?.consignment_number ?? '';
    this.business_name = data?.business_name ?? '';
    this.recipient_name = data?.recipient_name ?? '';
    this.contact_number = data?.contact_number ?? '';
    this.stop_result = data?.stop_result ?? '';
    this.stop_outcome = data?.stop_outcome ?? '';
    this.attachment =
      data.attachment?.length > 0
        ? JSON.parse(data.attachment).map((item: string) =>
            item.replace('\\', '')
          )
        : [];
    this.location = data?.job_history?.location ?? '';
    this.department = `${data?.job_history?.client ?? ''} - ${
      data?.job_history?.depot ?? ''
    }`;
    this.driver = `${data?.users?.name ?? ''} ${data?.users?.lname ?? ''}`;

    return this;
  }

  public static create(data: ProofOfDeliveryResponse) {
    return new this().map(data);
  }
}
