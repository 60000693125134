import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { buildParams, IndexQuery } from 'src/app/model/IndexQuery';
import { apiUrls } from 'src/app/settings/settings';
import { EpEncryptionService } from '../ep-encryption.service';

@Injectable({
  providedIn: 'root',
})
export class ProofOfDeliveryAPI {
  constructor(
    private http: HttpClient,
    private epEncryptionService: EpEncryptionService
  ) {}

  public index(query: IndexQuery) {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}shift/pod${buildParams(query)}`
    );
  }

  public getPODdata(
    query: IndexQuery,
    startDate: string,
    endDate: string,
    location: string[],
    department: string[],
    orderArr: string[],
    toExport: boolean
  ) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}shift/pod${buildParams(query)}`,
      this.epEncryptionService.sendPayload({
        startDate,
        endDate,
        location,
        department,
        orderArr,
        toExport,
      })
    );
  }

  public getDepartments() {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}shift/department`
    );
  }
}
