<div class="popup-content">
    <ng-container [ngTemplateOutlet]="AttemptedDeliveries" *ngIf="currentPopup==='AttemptedDeliveries'"></ng-container>
    <ng-container [ngTemplateOutlet]="ShiftDefinition"
        *ngIf="currentPopup==='ShiftDefinition'||currentPopup==='Regional'||currentPopup==='Tolls'||currentPopup==='Status'"></ng-container>
    <ng-container [ngTemplateOutlet]="Comments" *ngIf="currentPopup==='Comments'"></ng-container>
    <ng-container [ngTemplateOutlet]="CommentsReadOnly" *ngIf="currentPopup==='CommentsReadOnly'"></ng-container>
    <ng-container [ngTemplateOutlet]="Delete" *ngIf="currentPopup==='Delete'"></ng-container>
</div>
<ng-template #AttemptedDeliveries>
    <div
        class='w-[620px] h-[340px] flex flex-col box-border px-9 py-8 rounded-2xl bg-white border border-solid border-[#EAECF0] '>
        <div class='grid grid-cols-2 gap-x-[56px] gap-y-6'>
            <div class='flex justify-between items-center'>
                <div class='w-fit flex flex-col'>
                    <span>Total Stops Loaded</span>
                    <span>(Excluding Pickups)</span>
                </div>
                <input [(ngModel)]='popupdata.stops_loaded'
                    class='w-14 border border-solid border-[#D0D5DD] rounded box-border outline-none px-[14px] py-[2px] leading-6 text-[11px] text-[#475467] font-Inter font-semibold text-center' />
            </div>
            <div class='flex justify-between items-center'>
                <span>Rejections</span>
                <input [(ngModel)]='popupdata.rejections'
                    class='w-14 border border-solid border-[#D0D5DD] rounded box-border outline-none px-[14px] py-[2px] leading-6 text-[11px] text-[#475467] font-Inter font-semibold text-center' />
            </div>

            <hr class='col-span-2 w-full border-t border-solid border-[#EAECF0]' />

            <div class='flex justify-between items-center'>
                <span>Cards Left</span>
                <input [(ngModel)]='popupdata.cards_left'
                    class='w-14 border border-solid border-[#D0D5DD] rounded box-border outline-none px-[14px] py-[2px] leading-6 text-[11px] text-[#475467] font-Inter font-semibold text-center' />
            </div>
            <div class='flex justify-between items-center'>
                <span>Skipped</span>
                <input [(ngModel)]='popupdata.skipped'
                    class='w-14 border border-solid border-[#D0D5DD] rounded box-border outline-none px-[14px] py-[2px] leading-6 text-[11px] text-[#475467] font-Inter font-semibold text-center' />
            </div>

            <hr class='col-span-2 w-full border-t border-solid border-[#EAECF0]' />

            <div class='flex justify-between items-center'>
                <span>Check Address</span>
                <input [(ngModel)]='popupdata.check_address'
                    class='w-14 border border-solid border-[#D0D5DD] rounded box-border outline-none px-[14px] py-[2px] leading-6 text-[11px] text-[#475467] font-Inter font-semibold text-center' />
            </div>
            <div class='flex justify-between items-center'>
                <span>Attempted Deliveries</span>
                <span
                    class='w-14 px-[14px] py-[2px] leading-6 text-[11px] text-[#475467] font-Inter font-semibold text-center'>{{popupdata.stops_loaded-popupdata.skipped}}
                </span>
            </div>

            <hr class='col-span-2 w-full border-t border-solid border-[#EAECF0]' />

            <div class='col-span-2 flex justify-end items-center'>
                <div class='w-fit flex gap-3'>
                    <button
                        class='w-[98px] flex justify-center items-center box-border px-6 py-2 text-[#344054] border border-solid border-[#D0D5DD] rounded-lg'
                        (click)="CancelUpdates()">Cancel</button>
                    <button
                        class='w-[98px] flex justify-center items-center box-border px-6 py-2 bg-[#2A6AF6] text-white rounded-lg'
                        (click)="SaveUpdates()">Save</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #ShiftDefinition>
    <div
        class='w-[180px] h-fit max-h-[150px] flex flex-col p-1 box-border rounded-lg bg-white border border-solid border-[#EAECF0] overflow-x-hidden overflow-y-auto scroll-thin-style'>
        <div *ngFor="let dataItem of popupdata; let i = index;" (click)="handleClick(i)"
            class='w-full flex justify-between items-center px-2 py-[10px] hover:cursor-pointer'
            [ngClass]="{'bg-[#F9FAFB] rounded-md':dataItem.isSelected}">
            <span class='text-sm text-[#101828] font-medium font-Inter leading-6'>{{dataItem.item}}</span>
            <span>
                <img *ngIf="dataItem.isSelected" src='/assets/icons/offers/check.svg' />
            </span>
        </div>
    </div>
</ng-template>

<ng-template #CommentsReadOnly>
    <div
        class='w-[400px] h-fit flex flex-col p-3 box-border rounded-lg bg-white border border-solid border-[#EAECF0] rounded-b-xl rounded-tl-xl rounded-tr-none gap-5'>
        <div *ngIf="popupdata.Roster" class='w-full flex flex-col items-start gap-[6px]'>
            <span class='text-sm text-[#191919] font-medium font-Inter'>Roster</span>
            <p class='w-full h-fit max-h-[100px] text-[#555] text-xs font-normal'>
                {{popupdata.Roster}}</p>
        </div>
        <div *ngIf="popupdata.Timesheet" class='w-full flex flex-col items-start gap-[6px]'>
            <span class='text-sm text-[#191919] font-medium font-Inter'>Timesheet</span>
            <p class='w-full h-fit max-h-[100px] text-[#555] text-xs font-normal'>
                {{popupdata.Timesheet}}</p>
        </div>
    </div>
</ng-template>

<ng-template #Comments>
    <div
        class='w-[400px] h-fit flex flex-col p-3 box-border rounded-lg bg-white border border-solid border-[#EAECF0] rounded-b-xl rounded-tl-xl rounded-tr-none gap-5'>
        <div *ngIf="popupdata.isRoster" class='w-full flex flex-col items-start gap-[6px]'>
            <span class='text-sm text-[#344054] font-medium font-Inter'>Roster</span>
            <textarea
                class='w-full h-[100px] rounded-lg border border-solid border-[#EAECF0] outline-none  overflow-x-hidden overflow-y-auto scroll-thin-style text-[#555] text-[11px] box-border px-2 py-1'
                [(ngModel)]="popupdata.Roster"></textarea>
        </div>
        <div *ngIf="popupdata.isTimesheet" class='w-full flex flex-col items-start gap-[6px]'>
            <span class='text-sm text-[#344054] font-medium font-Inter'>Timesheet</span>
            <textarea
                class='w-full h-[100px] rounded-lg border border-solid border-[#EAECF0] outline-none  overflow-x-hidden overflow-y-auto scroll-thin-style text-[#555] text-[11px] box-border px-2 py-1'
                [(ngModel)]="popupdata.Timesheet"></textarea>
        </div>

        <div class='w-full flex gap-3 pt-1 box-border'>
            <button
                class='w-full flex justify-center items-center box-border px-6 py-2 text-sm text-[#344054] border border-solid border-[#D0D5DD] rounded-lg'
                (click)="CancelUpdates()">Cancel</button>
            <button
                class='w-full flex justify-center items-center box-border px-6 py-2 text-sm bg-[#2A6AF6] text-white rounded-lg'
                (click)="SaveUpdates()">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #Delete>
    <div class='w-[138px] h-[46px] flex justify-start items-center px-[14px] py-3 box-border bg-white border border-solid border-[#EAECF0] rounded-xl gap-2 hover:cursor-pointer'
        (click)="handleDelete()">
        <img src='/assets/icons/offers/trash.svg' /><span
            class='text-sm text-[#FF3A3A] leading-6 font-medium font-Inter'>Delete</span>
    </div>
</ng-template>