import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { Depot } from "src/app/model/custm/Depot";
import { buildParams, IndexQuery } from "src/app/model/IndexQuery";
import { apiUrls } from "src/app/settings/settings";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root'
})
export class DepotAPI{
    constructor(
        private http:HttpClient,
        private crypto:EpEncryptionService
    ){}

    public index(query:IndexQuery){
        return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}depots${buildParams(query)}`);
    }

    public show(id:number){
        return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}depots/${id}`);
    }

    public create(depot:Depot){
        return this.http.post<CodedResponseModel>(`${apiUrls.apiUrl}depots`,
            this.crypto.sendPayload(depot.responsify()));
    }

    public update(depot:Depot){
        return this.http.put<CodedResponseModel>(`${apiUrls.apiUrl}depots/${depot.id}`,
            this.crypto.sendPayload(depot.responsify()));
    }

    public delete(id:number){
        return this.http.delete<CodedResponseModel>(`${apiUrls.apiUrl}depots/${id}`);
    }
}