<div>
    <div class='controls'>
        <app-button *ngxPermissionsOnly="['full:depots', 'create:depots']" text="Create New" appOpenInNewTab="/depots/new"></app-button>
        <app-form-input [(model)]="query.filters!.search" (modelChange)="searcherInput()" placeholder="Search..."
            [isLabel]="false"></app-form-input>
    </div>
    <div class='table_wraper'>
        <c-table [columns]="columnDef" [data]="depots" [loading]="loading" [query]="query" [total]="filtered"
            (changed)="fetch()"></c-table>
    </div>
</div>