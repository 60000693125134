import { animate, state, style, transition, trigger } from "@angular/animations";

export const slideToggle: any = trigger('expandCollapse', [
    state('collapsed', style({
        height: 0,
        overflow: 'hidden',
        paddingTop: 0,
        paddingBottom: 0,
    })),

    state('expanded', style({
        visibility: 'inherit',
        overflow: 'hidden',
    })),

    transition('collapsed => expanded', [
        animate('300ms ease-out')
    ]),

    transition('expanded => collapsed', [
        animate('300ms ease-out')
    ]),
]);

export const fadeInOut: any = trigger('inOutAnimation', [
    transition(
        ':enter',
        [
            style({ opacity: 0 }),
            animate('0.3s ease-out',
                style({ opacity: 1 }))
        ]
    ),
    transition(
        ':leave',
        [
            style({ opacity: 1 }),
            animate('0.3s ease-in',
                style({ opacity: 0 }))
        ]
    )
]);