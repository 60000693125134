import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {

  @Input() isLabel: boolean = true;
  @Input() labelText: string = 'Label text';
  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() classes: Array<string> = [];
  @Input() model: any;
  @Input() isDisabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() imgSrc: string = '';
  @Input() max: number = 0;
  @Input() isError: boolean = false
  @Output() modelChange = new EventEmitter();

  @ViewChild('inputEl') inputEl?: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  public change(newValue: any) {
    this.model = newValue;
    this.modelChange.emit(newValue);
  }

}
