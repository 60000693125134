import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { Popupv2Component } from '../popupv2/popupv2.component';
import _ from 'lodash';

export interface TableColumn {
  label: string;
  slug: string;
  keyValue?: string;

  hybrid?: boolean;

  editable?: boolean;
  inputstyle?: string;
  inputmask?: string;
  suffix?: string;
  placeholder?: string;
  invoker?: CallableFunction;
  disableRule?: CallableFunction;

  poppable?: boolean;
  popupicon?: string;
  popupdata?: any;
  popupvalidator?: CallableFunction;

  outlink?: boolean;

  multiAction?: boolean;
  multiActions?: TableColumn[];

  globalDisable?: CallableFunction;

  toggle?: boolean;
  toggled?: boolean;
  toggleAction?: CallableFunction;
  
  touchable?: CallableFunction;
  
  accent?: boolean;
  alignCenter?: boolean;
  checked?: boolean;
  icon?: boolean;
  iconUrl?: CallableFunction;
  dataType?: 'data' | 'actions';
  filterType?: 'text' | 'number' | 'date' | 'list' | 'bool';
  filterList?: any[];
  sortable?: boolean;
  filterable?: boolean;
  validator?: CallableFunction;
  attachableValidator?: CallableFunction;
  accessor?: CallableFunction;
  onClick?: CallableFunction;
  actions?: ColumnAction[];
  htmlData?: boolean;
  headerDropdown?: ColumnAction[];
  conditionalClass?: CallableFunction;
}
export interface ColumnAction {
  label: string;
  type?: 'action' | 'route' | 'checkbox';
  action?: CallableFunction;
  condition?: CallableFunction;
  route?: string | CallableFunction;
}

export interface ErrorType {
  id: number;
  col: number;
  message: string;
}

@Component({
  selector: 'c-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() public data: any[] = [];
  @Input() public columns: TableColumn[] = [];
  @Input() public loading: boolean = false;
  @Input() public query: IndexQuery = {};
  @Input() public total: number = 0;
  @Input() public sortedColumn: { columnName: string } = { columnName: '' };
  @Input() public collapsibleColumn: boolean = false;
  @Input() public activePagination: boolean = true;
  @Input() public collapsedColumns: number[] = [];
  @Input() public toggleObj: { [key: string]: boolean } = {};

  @Output() public filtered = new EventEmitter<string>();
  @Output() public sorted = new EventEmitter<string>();
  @Output() public changed = new EventEmitter<void>();

  public invalidationErrors: ErrorType[] = [];
  public isBlurTriggeredEvent: boolean = false;

  public openDropdown?: number;

  public showMoreArr: number[] = [];

  public payIn: string = '';

  private overlayRef: OverlayRef | null;
  public timerId: any;
  public isPoppedUp: boolean = false;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder
  ) {
    this.overlayRef = null;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.invalidationErrors = [];
    }
  }

  public handleShowMoreClick(id: number) {
    const index = this.showMoreArr.findIndex((item) => item == id);
    if (index > -1) {
      this.showMoreArr.splice(index, 1);
    } else {
      this.showMoreArr.push(id);
    }
  }

  public handleSortClick(keyValue: string | undefined) {
    this.sortedColumn.columnName = keyValue ?? '';
    this.sorted.emit(keyValue);
  }

  public getData(data: string[], id: number) {
    if (this.showMoreArr.includes(id)) return data;
    else return data.slice(0, 1);
  }

  public cellData(row: any, column: TableColumn) {
    if (column.accessor) return column.accessor(row);
    return row[column.slug];
  }
  public functionableValue(row: any, value: any) {
    if (typeof value === 'function') return value(row);
    else return value;
  }
  public pageChange(p: number) {
    if (p < 1 || p > this.maxPage) return;
    this.query.page = p;
    this.changed.emit();
  }

  public dropdownClick(index: number): void {
    if (this.openDropdown === index) this.openDropdown = undefined;
    else this.openDropdown = index;
  }

  public handleCollapseColumn(index: number): void {
    if (this.collapsedColumns.includes(index))
      this.collapsedColumns.splice(this.collapsedColumns.indexOf(index), 1);
    else this.collapsedColumns.push(index);
  }

  public isCollapsedColumn(index: number) {
    return this.collapsedColumns.includes(index);
  }

  public handleUpdateInput(
    rowNumber: number,
    colNumber: number,
    col: TableColumn,
    event?: any
  ) {
    if (col.validator) {
      if (!col.validator(this.data[rowNumber])) {
        const isExistingError = this.invalidationErrors.find(
          (item) =>
            item.id === this.data[rowNumber].id && item.col === colNumber
        );
        if (!isExistingError)
          this.invalidationErrors.push({
            id: this.data[rowNumber].id,
            col: colNumber,
            message: 'Invalidate inputs...',
          });
        if (!col.hybrid) return;
        this.data[rowNumber][col.slug] = 'No';
      } else {
        _.remove(this.invalidationErrors, {
          id: this.data[rowNumber].id,
          col: colNumber,
        });
      }
    }
    if (!this.isBlurTriggeredEvent) {
      if (col.invoker) col.invoker(rowNumber, this.data[rowNumber]);

      if (event) {
        this.isBlurTriggeredEvent = true;
        event.target.blur();
      }
    } else {
      this.isBlurTriggeredEvent = false;
    }
  }

  public isValidate(row: any, j: number) {
    const error = this.invalidationErrors.find(
      (item) => item.id === row.id && item.col === j
    );
    if (error) return error.message;
    else return false;
  }

  showPopup(
    event: MouseEvent,
    rowNumber: number,
    row: any,
    column: TableColumn
  ) {
    this.closePopup();
    this.timerId = setTimeout(() => {
      if (column.invoker && column.slug === 'Comments') {
        column.invoker(rowNumber, this.data[rowNumber], 'Reviewed');
        this.data[rowNumber].Viewed = true;
      }
    }, 300);
    this.isPoppedUp = true;
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'top',
        },
      ])
      .withPush(true);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'opacity-100',
    });

    const popupComponentRef = this.overlayRef.attach(
      new ComponentPortal(Popupv2Component)
    );

    let popupData = null;

    if (column.popupdata) popupData = column.popupdata(row);

    popupComponentRef.instance.currentPopup = column.slug;
    popupComponentRef.instance.popupdata = Array.isArray(popupData)
      ? [...popupData]
      : { ...popupData };
    popupComponentRef.instance.validator = column.popupvalidator;
    popupComponentRef.instance.popupdataChange.subscribe((data: any) => {
      this.isPoppedUp = false;
      this.closePopup();
      if (data && !column.hybrid) {
        if (column.invoker)
          column.invoker(rowNumber, this.data[rowNumber], data);
      } else if (column.hybrid) {
        this.data[rowNumber][column.slug] = '';
      }
    });

    // Add this to close the overlay when the backdrop is clicked
    this?.overlayRef?.backdropClick().subscribe(() => {
      this.overlayRef?.detach();
      this.isPoppedUp = false;
      if (this.timerId) clearTimeout(this.timerId);
    });
  }
  showPopupReadOnly(
    event: MouseEvent,
    rowNumber: number,
    row: any,
    column: TableColumn
  ) {
    if (this.timerId) clearTimeout(this.timerId);
    if (!this.isPoppedUp) {
      this.timerId = setTimeout(() => {
        setTimeout(() => {
          if (column.invoker && column.slug === 'Comments') {
            column.invoker(rowNumber, this.data[rowNumber], 'Reviewed');
            this.data[rowNumber].Viewed = true;
          }
        }, 300);

        const positionStrategy = this.overlayPositionBuilder
          .flexibleConnectedTo({ x: event.x, y: event.y })
          .withPositions([
            {
              originX: 'start',
              originY: 'center',
              overlayX: 'end',
              overlayY: 'top',
            },
          ])
          .withPush(true);

        this.overlayRef = this.overlay.create({
          positionStrategy,
        });

        const popupComponentRef = this.overlayRef.attach(
          new ComponentPortal(Popupv2Component)
        );

        let popupData = null;

        if (column.popupdata) popupData = column.popupdata(row);

        popupComponentRef.instance.currentPopup = 'CommentsReadOnly';
        popupComponentRef.instance.popupdata = popupData;
        // Add this to close the overlay when the backdrop is clicked
        this?.overlayRef?.backdropClick().subscribe(() => {
          this.overlayRef?.detach();
        });
      }, 300);
    }
  }

  public clearPopupTimer() {
    if (this.overlayRef && !this.isPoppedUp) {
      setTimeout(() => {
        this.closePopup();
      }, 300);
    } else {
      if (this.timerId) clearTimeout(this.timerId);
    }
  }

  public closePopup() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
    if (this.timerId) clearTimeout(this.timerId);
  }
  handleToggleChange(e: any, col: any) {
    this.data.forEach((value: any) => {
      value[col.slug] = col.toggleAction(value, e.checked);
    });
    this.toggleObj[col.slug] = e.checked;
  }
  public get firstIndex() {
    return (this.query.perPage ?? 15) * ((this.query.page ?? 1) - 1) + 1;
  }
  public get maxPage() {
    return Math.ceil(this.total / (this.query.perPage ?? 15));
  }
}
