export interface DepotResponse {
    id:number;
    name:string;
    street:string;
    suburb:string;
    lat:number;
    lng:number;
}

export class Depot {
    public id:number = 0;
    public name:string = '';
    public street:string = '';
    public suburb:string = '';
    public lat:number = 0;
    public lng:number = 0;

    public map(data:DepotResponse){
        this.id = data.id;
        this.name = data.name;
        this.street = data.street??"";
        this.suburb = data.suburb??"";
        this.lat = data.lat;
        this.lng = data.lng;

        return this;
    }
    public responsify():DepotResponse {
        return {
            id: this.id,
            name: this.name,
            street: this.street,
            suburb: this.suburb,
            lat: this.lat,
            lng: this.lng
        }
    }

    public static create(data:DepotResponse){
        let r = new this;
        return r.map(data);
    }
}