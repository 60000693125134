import { PayratesType, UserRoles } from './custm/Setting';

export interface UserSortObj {
  id: boolean;
  name: boolean;
  phone: boolean;
  email: boolean;
  humanforce_id: boolean;
  donesafe_id: boolean;
  route4me_id: boolean;
  referral_code: boolean;
  role_name: boolean;
}

export interface user_interface {
  id: number;
  school_id: number;
  name: string;
  lname?: string;
  email: string;
  password?: string;
  phone?: string;
  bankacc?: string;
  user_role?: string;
  bankno?: number;
  bsb?: number;
  humanforce_id?: string;
  donesafe_id?: number;
  route4me_id?: number;
  role?: any[];
  is_admin?: boolean;
  fcm_token?: string;
  beta_user: boolean;
  beta_visible: boolean;
  referral_code?: string;
  locations?: number[];
  departments?: number[];
  payrates?: number[];
  notes?: string;
  override?:
    | (PayratesType & {
        payrate_id: number;
        user_id: number;
      })[]
    | boolean;
  humanforce_details?: any
}

export class User {
  public id: number = 0;
  public schoolID: number = 0;
  public name: string = '';
  public lname: string = '';
  public email: string = '';
  public display_name: string = '';
  public phone?: string;
  public bankAcc?: string;
  public bankNo?: number;
  public bsb?: number;

  public humanforceId?: string;
  public donesafe_id?: number;
  public route4meId?: number;

  public user_role: string = UserRoles.Guest;
  public checked: boolean = false;
  public avatar_filename: string | null = null;
  public is_first_login: boolean = true;

  public referralCode: string = '';

  public invitation_send_date?: Date;

  public changePassword: boolean = false;
  public password: string | null = null;
  public newPass: string | null = null;
  public passConfirm: string | null = null;
  public hasFCM: boolean = false;
  public locations: number[] = [];
  public departments: number[] = [];
  public payrates: number[] = [];
  public beta_user: boolean = false;
  public beta_visible: boolean = false;
  public notes: string = "";
  public override:
    | (PayratesType & {
        payrate_id: number;
        user_id: number;
      })[]
    | boolean = false;
  public humanforce_details?: any;
  public map(_data: user_interface) {
    this.id = _data.id;
    this.name = _data.name;
    this.lname = _data.lname ?? '';
    this.email = _data.email;
    this.changePassword = false;
    this.phone =  _data.humanforce_details?.Mobile ?  _data.humanforce_details?.Mobile: _data.phone ?? '';
    this.bankAcc = _data.bankacc;
    this.bankNo = _data.bankno;
    this.bsb = _data.bsb;

    this.humanforceId = _data.humanforce_id;
    this.donesafe_id = _data.donesafe_id;
    this.beta_user = _data.beta_user;
    this.beta_visible = _data.beta_visible;
    this.route4meId = _data.route4me_id;

    this.referralCode = _data.referral_code ?? '';

    this.user_role = _data.role?.[0]?.name ?? UserRoles.Guest;
    this.hasFCM = Boolean(_data.fcm_token);

    this.locations = _data.locations ?? [];
    this.departments = _data.departments ?? [];
    this.payrates = _data.payrates ?? [];
    this.override = _data.override ?? [];
    this.notes = _data.notes ?? "";
    return this;
  }

  public static mapUser(_data: any): User {
    let user = new User();
    user.map(_data);
    return user;
  }

  public toResponse() {
    let response: Partial<user_interface> = {
      id: this.id,
      school_id: this.schoolID,
      name: this.name,
      email: this.email,
      password: this.password ? this.password : '',
      phone: this.phone,
      bankacc: this.bankAcc,
      bankno: this.bankNo,
      bsb: this.bsb,
    };

    if (this.user_role) {
      response.user_role = this.user_role;
    }

    return response;
  }

  public selectVale() {}

  public selectValeTitle(): string {
    let title: string = '';

    return title;
  }

  public selectValeDesc(): string {
    let desc: string = '';

    return desc;
  }

  public get fullName() {
    return `${this.name} ${this.lname}`;
  }
}
