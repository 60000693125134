import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-heading6',
  templateUrl: './heading6.component.html',
  styleUrls: ['./heading6.component.scss']
})
export class Heading6Component implements OnInit {

  @Input() headingTitle: string = '';
  @Input() headingClass: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
