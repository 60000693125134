<h1>Account settings</h1>
<div>
    <label>Avatar</label>
    <img *ngIf="user.avatar_filename" [src]="getAvatarUrl()">
    <button (click)="fInput.click()">Change</button>
    <input type="file" #fInput [hidden]="true" (change)="uploader($event)">
</div>
<div>
    <label>First name</label>
    <input [(ngModel)]="user.name">
</div>
<div>
    <label>Last name</label>
    <input [(ngModel)]="user.lname">
</div>
<div>
    <label>Email</label>
    <input [(ngModel)]="user.email">
</div>
<ng-container *ngIf="!user.changePassword">
    <div>
        <label>Password</label>
        <input disabled value="asd123" type="password">
    </div>
    <a href="javascript://" (click)="user.changePassword=true">Change password</a>
</ng-container>
<ng-container *ngIf="user.changePassword">
    <div>
        <label>Current password</label>
        <input type="password" [(ngModel)]="user.password">
    </div>
    <div>
        <label>New password</label>
        <input type="password" [(ngModel)]="user.newPass">
    </div>
    <div>
        <label>Confirm new password</label>
        <input type="password" [(ngModel)]="user.passConfirm">
    </div>
    <a href="javascript://" (click)="user.changePassword=false">Don't change password</a>
</ng-container>
<button (click)="updateData()">Save changes</button>