export const apiUrls = {
  // apiUrl: 'https://preprod.devcustm.com.au/api/',
  // imgUrl: 'https://preprod.devcustm.com.au/storage/media/',
  // tmpUrl: 'https://preprod.devcustm.com.au/storage/',

  // apiUrl: 'http://localhost:8000/api/',
  // imgUrl: 'http://localhost:8000/storage/media/',
  // tmpUrl: 'http://localhost:8000/storage/',
  apiUrl: 'https://app.custm.com.au/api/',
  imgUrl: 'https://app.custm.com.au/storage/media/',
  tmpUrl: 'https://app.custm.com.au/storage/',
  // apistagingUrl: 'https://staging.devcustm.com.au/api/',
  // apiUrl: 'https://staging.devcustm.com.au/api/',
  // imgUrl: 'https://staging.devcustm.com.au/storage/media/',
  // tmpUrl: 'https://staging.devcustm.com.au/storage/',
};

export const decryptPayload = false;

export const googleApiKey = 'AIzaSyDOmczG69yIMGlPzHzpnOkGcKhMU_bti2w';

export const currentTimeZone = 'Australia/Melbourne';

export const MAPBOX_ACCESSTOKEN =
  'pk.eyJ1IjoiY3VzdG0tZGV2IiwiYSI6ImNsbGVoZzRwNzBpd3UzZ3F2Nmo4MTRwaXkifQ.9YGmNmMVc9hDa2oPafaJ3Q';
