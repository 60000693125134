import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { slideToggle } from 'src/app/settings/animation-config';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
  animations: [slideToggle],
  host: {
    '(document:click)': 'slideUpList($event)',
  },
})
export class LinkButtonComponent implements OnInit {

  @Input() text: string = '';
  @Input() icon: string = 'assets/svg/add_icon.svg';
  @Input() isIcon: boolean = true;
  @Input() classes: Array<string> = [];
  @Input() isDropdown: boolean = false;
  @Input() list: Array<any> = [];
  @Input() closeBtn: boolean = false;
  @Input() dropDownPosition: string = 'left';
  @Input() iconPosition: string = 'left';
  @Input() isLink: boolean = false;
  @Input() link: string = '';
  @Input() isArrow: boolean = true;
  @Input() iconStroke: boolean = false;
  @Output() itemSelectedEvent = new EventEmitter();

  @Output() modelChange = new EventEmitter();

  public active: boolean = false;

  constructor(
    private eRef: ElementRef
  ) { }

  ngOnInit(): void {
    if (this.closeBtn) {
      this.icon = '../../../assets/svg/close_icon.svg'
    }
  }

  public slideToggle() {
    if (this.isDropdown)
      this.active = !this.active;
  }

  public slideUpList(_event: any) {
    if (!this.eRef.nativeElement.contains(_event.target))
      this.active = false;
  }

  public selectItem(item: any) {
    this.itemSelectedEvent.emit(item.slug);
    this.active = false;
  }
}
