import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-json-data',
    templateUrl: './json-data.component.html',
    styleUrls: ['./json-data.component.scss']
})
export class JsonDataComponent implements OnInit {
    constructor(
        private ref:MatDialogRef<JsonDataComponent>,
        @Inject(MAT_DIALOG_DATA) public data:string
    ){}

    ngOnInit():void{}
}
