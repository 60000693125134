import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuComponent } from '../partials/menu/menu.component';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  password: string = '';
  passConfirm: string = '';
  token: string | null = '';
  sent: boolean = false;
  public errMsg: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginApi: LoginService
  ) {
    MenuComponent.display(false);
    this.token = this.route.snapshot.paramMap.get('token');
    if (!this.token) {
      console.error('Token missing');
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {}

  public send() {
    this.errMsg = '';
    if (this.passConfirm != this.password) {
      this.errMsg = 'Passwords must match';
      return;
    }
    if (this.password.length < 6) {
      this.errMsg = 'Password should be at least 6 characters long';
      return;
    }
    this.loginApi
      .resetPassword(String(this.token), this.password, this.passConfirm)
      .subscribe(
        (res) => {
          this.sent = true;
        },
        (err) => {
          console.log(err);
          this.errMsg = err.error.message;
        }
      );
  }
}
