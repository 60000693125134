<!-- <a routerLink="/login">< Back to login</a>
<div *ngIf="!sent">
    <div>
        <label>Email:</label>
        <input [(ngModel)]="email">
    </div>
    <button (click)="send()">Send</button>
</div>
<div *ngIf="sent">
    <p>Email with the instructions to reset your password has been sent to your account's email address.</p>
    <a routerLink="/login">Back to login</a>
</div> -->

<div class='login-container'>
    <div class='form'>
        <app-heading1 headingTitle="Reset password"></app-heading1>
        <app-form-input [(model)]="email" labelText="Email"></app-form-input>
        <app-button text="Request password reset" (buttonClickEvent)="send()"></app-button>
    </div>
</div>