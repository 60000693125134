import { MappingDataType } from './Setting';

export interface ClientType {
  id: number;
  name: string;
  point_of_contact: string;
  emails: string[];
  forced_pod: boolean;
  account_code: string;
  invoice_code: string;
  operation_contacts: OperationContactType[];
  contact_number: string;
  address: string;
  suburb: string;
  postcode: string;
  state: string;
  depot_lat: number;
  depot_lng: number;
  entity_name: string;
  invoice_prefix: string;
  payment_terms: number;
  vehicles: MappingDataType[];
  location: MappingDataType;
  department: MappingDataType;
  chargingTermsCollapse: any;
  dedi: any;
  dedi_notes: any;
  flexi: any;
  flexi_notes: any;
  setRun: any;
  setrun_notes: any;
  attachments: any[];
}

export interface OperationContactType {
  point_of_contact: string;
  contact_number?: string;
  position?: string;
  email: string;
  send_booking?: string;
}

export class Client {
  public id: number = 0;
  public name: string = '';
  public point_of_contact: string = '';
  public emails: string[] = [];
  public forced_pod: boolean = false;
  public account_code: string = '';
  public invoice_code: string = '';
  public operation_contacts: OperationContactType[] = [];
  public contact_number: string = '';
  public address: string = '';
  public suburb: string = '';
  public postcode: string = '';
  public state: string = '';
  public depot_lat: number = 0;
  public depot_lng: number = 0;
  public entity_name: string = '';
  public invoice_prefix: string = '';
  public payment_terms: number = 0;
  public vehicles: MappingDataType[] = [];
  public chargingTermsCollapse: any = {};
  public dedi: any = {};
  public dedi_notes: any = '';
  public flexi: any = {};
  public flexi_notes: any = '';
  public setRun: any = {};
  public setrun_notes: any = '';
  public location!: MappingDataType;
  public department!: MappingDataType;
  public attachments: any[] = [];

  public map(_data: ClientType) {
    this.id = _data.id;
    this.name = _data.name;
    this.point_of_contact = _data.point_of_contact;
    this.emails = _data.emails;
    this.forced_pod = _data.forced_pod;
    this.account_code = _data.account_code;
    this.invoice_code = _data.invoice_code;
    this.operation_contacts = _data?.operation_contacts ?? [];
    this.contact_number = _data.contact_number;
    this.address = _data.address;
    this.suburb = _data.suburb;
    this.postcode = _data.postcode;
    this.state = _data.state;
    this.depot_lat = _data.depot_lat;
    this.depot_lng = _data.depot_lng;
    this.entity_name = _data.entity_name;
    this.invoice_prefix = _data.invoice_prefix;
    this.payment_terms = _data.payment_terms;
    this.vehicles = _data.vehicles;
    this.chargingTermsCollapse = _data.chargingTermsCollapse;
    this.dedi = _data.dedi;
    this.flexi = _data.flexi;
    this.setRun = _data.setRun;
    this.location = _data.location;
    this.department = _data.department;
    this.dedi_notes = _data.dedi_notes;
    this.flexi_notes = _data.flexi_notes;
    this.setrun_notes = _data.setrun_notes;
    this.attachments = _data.attachments;

    return this;
  }

  public static mapUser(_data: any): Client {
    let user = new Client();
    user.map(_data);
    return user;
  }

  public selectVale() {}

  public selectValeTitle(): string {
    let title: string = '';

    return title;
  }

  public selectValeDesc(): string {
    let desc: string = '';

    return desc;
  }
}
