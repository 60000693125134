import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { buildParams, IndexQuery } from 'src/app/model/IndexQuery';
import { apiUrls } from 'src/app/settings/settings';
import { EpEncryptionService } from '../ep-encryption.service';
import { JobShiftOfferResponse } from 'src/app/model/custm/JobShiftOffer';

@Injectable({
  providedIn: 'root',
})
export class FileApi {
  constructor(
    private http: HttpClient,
    private epEncryptor: EpEncryptionService
  ) {}

  public upload(data: string, name: string) {
    return this.http.post(
      `${apiUrls.apiUrl}upload`,
      this.epEncryptor.sendPayload({ data: data, name: name }),
      { reportProgress: true, observe: 'events' }
    );
  }
}
