import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { MailEvents } from 'src/app/model/MailEvents';
import { LoaderPartial } from 'src/app/partials/loader/loader.component';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { MailEventsService } from 'src/app/services/mail-events.service';

@Component({
    selector: 'app-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.scss']
})
export class EmailFormComponent implements OnInit {
    public mail:MailEvents = new MailEvents();

    public editMode:boolean = false;

    constructor(
        private mailApi:MailEventsService,
        private router:Router,
        private route:ActivatedRoute,
    ){
        this.route.params.subscribe(p => {
            if(p.id){
                this.editMode = true;

                LoaderPartial.show(true);
                this.mailApi.get(p.id).subscribe(r => {
                    let res = CodedResponseModel.decode(r);

                    this.mail = MailEvents.create(res);

                    LoaderPartial.show(false);
                }, err => {
                    NotificatorPartial.push({ type:'error', message:'An error has occured', details: err.error.message, dismissable: true });
                })
            }
        })
    }

    ngOnInit():void {}

    public save(){
        if(this.editMode){
            this.mailApi.update(this.mail).subscribe(r => {
                let res = CodedResponseModel.decode(r);

                NotificatorPartial.push({ type:'success', message:'The email template has been updated', timeout: 3000 });
                this.router.navigate(['/emails']);
            }, err => {
                NotificatorPartial.push({ type:'error', message:'An error has occured', details: err.error.message, dismissable: true });
            })
        } else {
            this.mailApi.add(this.mail).subscribe(r => {
                let res = CodedResponseModel.decode(r);

                NotificatorPartial.push({ type:'success', message:'The email template has been created', timeout: 3000 });
                this.router.navigate(['/emails']);
            }, err => {
                NotificatorPartial.push({ type:'error', message:'An error has occured', details: err.error.message, dismissable: true });
            })
        }
    }

    public trackByIndex(i:number){
        return i;
    }
}
