import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from 'src/app/core/table/table.component';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Depot, DepotResponse } from 'src/app/model/custm/Depot';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { LoaderPartial } from 'src/app/partials/loader/loader.component';
import { MenuComponent } from 'src/app/partials/menu/menu.component';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { DepotAPI } from 'src/app/services/custm/depots.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-depots',
  templateUrl: './depots.component.html',
  styleUrls: ['./depots.component.scss'],
})
export class DepotsComponent implements OnInit {
  public loading: boolean = false;

  public depots: Depot[] = [];

  public searchCooldown: any;

  public columnDef: TableColumn[] = [
    { label: 'ID', slug: 'id', sortable: false, filterType: 'number' },
    { label: 'Name', slug: 'name', sortable: false },
    { label: 'Street Address', slug: 'street', sortable: false },
    { label: 'Suburb', slug: 'suburb', sortable: false },
    { label: 'Latitude', slug: 'lat', sortable: false },
    { label: 'Longitude', slug: 'lng', sortable: false },
    {
      label: 'Actions',
      slug: 'actions',
      dataType: 'actions',
      actions: [
        {
          label: 'Edit',
          type: 'route',
          route: (i: Depot) => `/depots/${i.id}`,
          condition: () =>
            this.authService.hasPermissions(['full:depots', 'edit:depots']),
        },
        {
          label: 'Delete',
          action: (i: Depot) => this.del(i),
          condition: () =>
            this.authService.hasPermissions(['full:depots', 'delete:depots']),
        },
      ],
    },
  ];

  public query: IndexQuery = {
    page: 1,
    perPage: 15,
    filters: {
      search: '',
    },
  };

  public total: number = 0;
  public filtered: number = 0;

  constructor(
    private depotApi: DepotAPI,
    private authService: LoginService,
    private dialog: MatDialog
  ) {
    this.fetch();
  }

  ngOnInit(): void {}

  public fetch() {
    this.loading = true;
    this.depotApi.index(this.query).subscribe((r) => {
      let res = CodedResponseModel.decode(r);

      this.depots = res.data.map((d: DepotResponse) => Depot.create(d));

      this.filtered = res.filtered;
      this.total = res.total;

      this.loading = false;
    });
  }

  public del(depot: Depot) {
    let d = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Are you sure you want to delete the depot ${depot.name}?`,
      },
    });
    d.afterClosed().subscribe((c) => {
      if (c) {
        this.depotApi.delete(depot.id).subscribe(
          (r) => {
            NotificatorPartial.push({
              type: 'info',
              message: `${depot.name} has been deleted`,
              timeout: 3000,
            });
            this.fetch();
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: 'An error has occurred',
              details: err.error.message,
            });
          }
        );
      }
    });
  }

  public searcherInput() {
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.fetch();
    }, 500);
  }
}
