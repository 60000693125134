import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { TableColumnV2 } from 'src/app/core/table-v2/table-v2.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { User } from 'src/app/model/User';
import {
  CheckedStatusType,
  LDRType,
  MappingDataType,
  PayratesType,
  UserRoles,
} from 'src/app/model/custm/Setting';
import { LoaderPartial } from 'src/app/partials/loader/loader.component';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { LoginService } from 'src/app/services/login.service';
import { UsersAPIService } from 'src/app/services/users.service';

const COMPARISON_FIELDS = [
  'dedi_rate',
  'fuel_levy',
  'flexi_delivery',
  'flexi_pickup',
];

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  public user: User = new User();

  public editMode: boolean = false;

  public locations: (MappingDataType & CheckedStatusType)[] = [];
  public user_overridden: (PayratesType & {
    payrate_id: number;
    user_id: number;
  })[] = [];
  public departments: (MappingDataType & CheckedStatusType)[] = [];
  public filteredDepartments: (MappingDataType & CheckedStatusType)[] = [];
  public _payrates: (PayratesType & {
    location: MappingDataType;
    role: MappingDataType;
    id: number;
  })[] = [];
  public payrates: (PayratesType & {
    location: MappingDataType;
    role: MappingDataType;
    id: number;
  } & CheckedStatusType)[] = [];
  public filteredPayrates: (PayratesType & {
    location: MappingDataType;
    role: MappingDataType;
    id: number;
  } & CheckedStatusType)[] = [];

  public ldr: LDRType[] = [];
  public filteredldr: LDRType[] = [];

  public formTblColumnDef3: TableColumnV2[] = [
    {
      label: 'checked',
      slug: 'checked',
      colType: 'checkbox',
      touchable: () =>
        this.authService.hasPermissions([
          'full:users',
          'edit:users',
          'edit-mapping:users',
        ]),
    },
    {
      label: 'Location',
      slug: 'role',
      accessor: (
        i: PayratesType & {
          location: MappingDataType;
          role: MappingDataType;
        } & CheckedStatusType
      ) => i.location.name,
    },
    {
      label: 'Role',
      slug: 'role',
      accessor: (
        i: PayratesType & {
          location: MappingDataType;
          role: MappingDataType;
        } & CheckedStatusType
      ) => i.role.name,
    },
    {
      label: 'DEDI Rate',
      slug: 'dedi_rate',
      colType: '_input',
      options: { prefix: '$', suffix: '', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full',
      accessor: (i: PayratesType) => i.dedi_rate,
      touchable: () =>
        this.authService.hasPermissions([
          'full:users',
          'edit:users',
          'edit-rates:users',
        ]),
    },
    {
      label: 'Fuel Levy',
      slug: 'fuel_levy',
      colType: '_input',
      options: { prefix: '', suffix: '%', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full',
      accessor: (i: PayratesType) => i.fuel_levy,
      touchable: () =>
        this.authService.hasPermissions([
          'full:users',
          'edit:users',
          'edit-rates:users',
        ]),
    },
    {
      label: 'FLEXI Delivery Rate',
      slug: 'flexi_delivery',
      colType: '_input',
      options: { prefix: '$', suffix: '', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full',
      accessor: (i: PayratesType) => i.flexi_delivery,
      touchable: () =>
        this.authService.hasPermissions([
          'full:users',
          'edit:users',
          'edit-rates:users',
        ]),
    },
    {
      label: 'FLEXI Pick Up Rate',
      slug: 'flexi_pickup',
      colType: '_input',
      options: { prefix: '$', suffix: '', precision: 3 },
      customClass: 'border-none outline-none text-sm w-full',
      accessor: (i: PayratesType) => i.flexi_pickup,
      touchable: () =>
        this.authService.hasPermissions([
          'full:users',
          'edit:users',
          'edit-rates:users',
        ]),
    },
  ];

  constructor(
    private userApi: UsersAPIService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: LoginService
  ) {
    this.route.params.subscribe((p) => {
      if (p.id) {
        this.editMode = true;

        LoaderPartial.show(true);
        this.userApi.show(p.id).subscribe(
          (r) => {
            let res = CodedResponseModel.decode(r);
            this.user = User.mapUser(res);
            this.user_overridden = this.user.override as (PayratesType & {
              payrate_id: number;
              user_id: number;
            })[];
            this.initializeValues();
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
              details: err.error.message,
              dismissable: true,
            });
          }
        );
      }
    });
  }

  ngOnInit(): void {
    if (!this.editMode) this.initializeValues();
  }

  public initializeValues() {
    LoaderPartial.show(true);
    this.userApi.getLocations().subscribe((r) => {
      let res = CodedResponseModel.decode(r);
      this.locations = res.data.map((item: MappingDataType) => ({
        ...item,
        checked: this.user.locations.includes(item.id),
      }));
      this.userApi.getDepartments().subscribe((r) => {
        let res = CodedResponseModel.decode(r);
        this.departments = res.data.map((item: MappingDataType) => ({
          ...item,
          checked: this.user.departments.includes(item.id),
        }));
        this.userApi.getPayrates().subscribe((r) => {
          LoaderPartial.show(false);
          const data = CodedResponseModel.decode(r);
          data.forEach((item: any) => {
            const overridden_payrate = this.user_overridden.length
              ? this.user_overridden.find(
                  (overridden) => overridden.payrate_id === item.id
                ) ?? {}
              : {};
            this.payrates.push({
              ...item,
              ..._.pick(overridden_payrate, COMPARISON_FIELDS),
              checked: this.user.payrates.includes(item.id),
            });
            this._payrates.push({ ...item });
          });

          this.setCheckedStatus('location', false, {
            checked: false,
          } as MappingDataType & CheckedStatusType);
        });
      });
    });

    this.userApi.getLDR().subscribe((r) => {
      const data = CodedResponseModel.decode(r);
      this.ldr = data;
    });
  }

  public setCheckedStatus(
    type: 'location' | 'department',
    checkedStatus: boolean,
    data: MappingDataType & CheckedStatusType
  ) {
    data.checked = checkedStatus;
    if (type === 'location') {
      const _checked = this.locations.flatMap((item) => {
        if (item.checked) return item.departments;
        return [];
      });
      this.filteredDepartments = this.departments.flatMap((item) => {
        if (_checked.includes(item.id)) {
          return [item];
        } else {
          item.checked = false;
          return [];
        }
      });
    }
    const checked_location_ids = this.locations
      .filter((item) => item.checked)
      .map((item) => item.id);
    const checked_department_ids = this.filteredDepartments
      .filter((item) => item.checked)
      .map((item) => item.id);

    const filteredLDR: Partial<LDRType>[] = this.ldr
      .filter(
        (item) =>
          checked_location_ids.includes(item.location_id) &&
          checked_department_ids.includes(item.department_id)
      )
      .map((item) => ({
        location_id: item.location_id,
        vehicle_id: item.vehicle_id,
      }));

    const filteredUniqLDR = _.uniqWith(filteredLDR, _.isEqual);

    this.filteredPayrates = _.sortBy(
      this.payrates.flatMap((item) => {
        const found = filteredUniqLDR.find(
          (subItem) =>
            subItem.location_id === item.location?.id &&
            subItem.vehicle_id === item.role?.id
        );
        if (found) return [item];
        else {
          item.checked = false;
          return [];
        }
      }),
      (obj) => obj.role.name
    );

    // this.filteredldr = this.ldr.filter;
  }

  public save() {
    this.user.locations = this.locations.flatMap((item) => {
      if (item.checked) return [item.id];
      return [];
    });
    this.user.departments = this.departments.flatMap((item) => {
      if (item.checked) return [item.id];
      return [];
    });

    const overriddenPayrates: (Partial<PayratesType> & {
      payrate_id: number;
      user_id: number;
    })[] = [];

    this.user.payrates = this.filteredPayrates.flatMap((item) => {
      if (item.checked) {
        const found = this._payrates.find((_item) => _item.id === item.id);
        if (
          !_.isEqual(
            _.pick(found, COMPARISON_FIELDS),
            _.pick(item, COMPARISON_FIELDS)
          )
        ) {
          this.user.override = true;
          overriddenPayrates.push({
            user_id: this.user.id,
            payrate_id: item.id,
            ..._.pick(item, COMPARISON_FIELDS),
          });
        }
        return [item.id];
      }
      return [];
    });

    if (this.editMode) {
      this.user.override = Boolean(this.user.override);
      this.userApi.edit(this.user).subscribe(
        (r) => {
          let res = CodedResponseModel.decode(r);

          if (overriddenPayrates.length)
            this.userApi
              .upsertOverriddenPayrates(overriddenPayrates)
              .subscribe((res) => {});
          NotificatorPartial.push({
            type: 'success',
            message: 'User has been updated',
            timeout: 3000,
          });
          this.router.navigate(['/users']);
        },
        (err) => {
          NotificatorPartial.push({
            type: 'error',
            message: err.error.message,
            details: err.error.message,
            dismissable: true,
          });
        }
      );
    } else {
      this.user.override = Boolean(this.user.override);
      this.userApi.create(this.user).subscribe(
        (r) => {
          let res = CodedResponseModel.decode(r);

          const user = User.mapUser(res);
          const _overriddenPayrates = overriddenPayrates.map((item) => ({
            ...item,
            user_id: user.id,
          }));

          if (overriddenPayrates.length)
            this.userApi
              .upsertOverriddenPayrates(_overriddenPayrates)
              .subscribe((res) => {});
          NotificatorPartial.push({
            type: 'success',
            message: 'The user has been created',
            timeout: 3000,
          });
          this.router.navigate(['/users']);
        },
        (err) => {
          NotificatorPartial.push({
            type: 'error',
            message: err.error.message,
            details: err.error.message,
            dismissable: true,
          });
        }
      );
    }
  }
}
