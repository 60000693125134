<div class='table-container scroll-thin-style h-full overflow-scroll'>
    <table [class.loading]="loading">
        <thead>
            <th *ngFor="let col of columns; let i = index" [ngClass]="{'collapsed-column':isCollapsedColumn(i)}">
                <div class='flex flex-col h-full justify-start'>
                    <div *ngIf="collapsibleColumn"
                        class='w-full flex justify-center items-center font-bold h-[13px] bg-[#2A6AF5] text-white hover:cursor-pointer'
                        (click)='handleCollapseColumn(i)'>
                        <img src='/assets/icons/timesheet/chevron-left.svg' *ngIf="!isCollapsedColumn(i)" />
                        <img src='/assets/icons/timesheet/chevron-right.svg' *ngIf="isCollapsedColumn(i)" />
                    </div>
                    <div *ngIf="isCollapsedColumn(i)" class='w-[18px] h-full'>
                    </div>
                    <div *ngIf="!isCollapsedColumn(i)" class='h-full flex flex-col justify-center items-center p-[6px]'>
                        <span *ngIf="!col.headerDropdown" class='flex items-center'>
                            <span class='leading-6 text-center text-xs font-medium text-[#475467]'
                                [ngClass]="{'whitespace-nowrap':col.label.length<15,'w-[140px]':col.label.length>15,'multi-actions':col.multiAction}">
                                {{col.label}}
                            </span>
                            <span class='hover:cursor-pointer min-w-[16px] min-h-[16px]' *ngIf="col.sortable"
                                (click)='handleSortClick(col.keyValue)'>
                                <img src='/assets/icons/offers/chevron-vertical.svg' />
                            </span>
                        </span>
                        <div *ngIf="col.toggle">
                            <mat-slide-toggle color="primary" [checked]="toggleObj[col.slug]"
                                (change)="handleToggleChange($event,col)">
                            </mat-slide-toggle>
                        </div>
                        <div class="dropdown-wrap" *ngIf="col.headerDropdown">
                            <div class="clickable" (click)="dropdownClick(i)">{{col.label}}</div>
                            <div class="dropdown-list" *ngIf="openDropdown === i">
                                <li *ngFor="let option of col.headerDropdown"
                                    (click)="option.action!(); openDropdown = undefined" [innerHtml]="option.label">
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </th>
        </thead>
        <tbody>
            <tr *ngFor="let row of data;
            let i = index">
                <td *ngFor="let col of columns;
                let j = index" [ngSwitch]="col.dataType"
                    [ngClass]="{'text-accent':col.accent&&!row.Authorised,'text-normal':!col.accent&&!row.Authorised,'text-reviewed':row.Authorised&&!isCollapsedColumn(j),'collapsed-column':isCollapsedColumn(j)}"
                    aria-disabled='true'>
                    <span *ngIf="isCollapsedColumn(j)" class='w-[18px] h-full'></span>
                    <ng-container *ngSwitchCase="'actions'">
                        <ng-container *ngIf="!isCollapsedColumn(j)">
                            <ng-container *ngFor="let action of col.actions" [ngSwitch]="action.type">
                                <ng-container *ngIf="action.condition===undefined||action.condition(row)">
                                    <app-link-button *ngSwitchDefault [text]="action.label" [isIcon]="false"
                                        (click)="action.action!(row)"></app-link-button>
                                    <app-link-button *ngSwitchCase="'route'" [text]="action.label" [isIcon]="false"
                                        [appOpenInNewTab]="functionableValue(row, action.route)"></app-link-button>
                                    <span *ngSwitchCase="'checkbox'" [ngClass]="{'text-align-center':col.alignCenter}">
                                        <input type="checkbox" [(ngModel)]="row.checked"
                                            (ngModelChange)="action.action!(row)">
                                    </span>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <ng-container *ngIf="!isCollapsedColumn(j)">
                            <ng-container *ngIf="col.label==='Attachments' || col.label === 'Summary Photo'">
                                <div *ngFor="let action of getData(cellData(row, col),row?.id||0)">
                                    <a href={{action}} target='_blank'>{{ action }}</a>
                                </div>
                                <small style='cursor: pointer;font-size: small;font-weight: 500;'
                                    (click)="handleShowMoreClick(row?.id||0)"
                                    *ngIf="(cellData(row, col)?.length||0)> 1">
                                    {{showMoreArr.includes(row?.id||0)? 'Showless':'Show more'}}
                                </small>
                            </ng-container>
                            <ng-container
                                *ngIf="(!(col.label==='Attachments'))&&(!col.htmlData)&&(!(col.label==='Summary Photo')&&(!col.icon))&&!col.editable&&!col.poppable&&!col.hybrid&&!col.outlink&&!col.multiAction">
                                <span
                                    [ngClass]="{'text-[#FF3A3A]':col.attachableValidator&&(col.attachableValidator(row)==='Critical'),'text-[#D6A850]':col.attachableValidator&&(col.attachableValidator(row)==='Warning')}">
                                    {{cellData(row,
                                    col)}}</span>
                            </ng-container>

                            <!-- //!  -->
                            <div *ngIf="col.multiAction" class='w-full flex justify-around items-center box-border'>
                                <div *ngFor="let action of col.multiActions;let k = index;">
                                    <div *ngIf="action.onClick">
                                        <img *ngIf="cellData(row, action)&&action.touchable&&action.touchable('unauthorise')"
                                            src='/assets/icons/timesheet/x-close.svg' alt='popup icon'
                                            class='hover:cursor-pointer' (click)="action.onClick(i,row,false)" />
                                        <img *ngIf="!cellData(row, action)&&action.validator&&action.validator(row)&&action.touchable&&action.touchable('authorise')"
                                            src='/assets/icons/timesheet/check-in-circle.svg' alt='popup icon'
                                            class='hover:cursor-pointer' (click)="action.onClick(i,row,true)" />
                                        <img *ngIf="!cellData(row, action)&&!(action.validator&&action.validator(row))&&action.touchable&&action.touchable('authorise')"
                                            src='/assets/icons/timesheet/inactive-check-in-circle.svg' alt='popup icon'
                                            class='hover:cursor-pointer' />
                                    </div>

                                    <span *ngIf="action.outlink">
                                        <span *ngIf="cellData(row, action)"
                                            (click)='action.onClick&&action.onClick(row)'>
                                            <img src="/assets/icons/timesheet/route.svg" alt='route icon'
                                                class='hover:cursor-pointer' />
                                        </span>
                                        <span *ngIf="!cellData(row, action)">
                                            <img src="/assets/icons/timesheet/inactive-route.svg" alt='route icon'
                                                class='hover:cursor-pointer' />
                                        </span>
                                    </span>

                                    <div *ngIf="action.poppable&&action.slug!=='Delete'&&((action.touchable&&action.touchable()&&!row.CommentExist)||row.CommentExist)"
                                        class='hover:cursor-pointer p-1 box-border'
                                        (mouseenter)="row.CommentExist&&showPopupReadOnly($event,i,row,action)"
                                        (mouseleave)="row.CommentExist&&clearPopupTimer()"
                                        (click)="!(columns[0].globalDisable&&columns[0].globalDisable(row))&&action.touchable&&action.touchable()&&showPopup($event,i,row,action)">
                                        <img [src]='row.CommentExist?
                                        row.Viewed?"/assets/icons/timesheet/read-comments.svg":
                                        "/assets/icons/timesheet/unread-comments.svg"
                                        :"/assets/icons/timesheet/add-comments.svg"' alt='popup icon' class='' />
                                    </div>
                                    <div
                                        [ngClass]='{"pointer-events-none":columns[0].globalDisable&&columns[0].globalDisable(row)}'>
                                        <div *ngIf="action.poppable&&action.slug==='Delete'">
                                            <img *ngIf="action.touchable&&action.touchable()"
                                                src='/assets/icons/timesheet/dots-vertical.svg' alt='popup icon'
                                                class='hover:cursor-pointer' (click)="showPopup($event,i,row,action)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- //!  -->

                            <span *ngIf="col.outlink">
                                <a *ngIf='cellData(row, col)' [href]='cellData(row, col)' target='_blank'>View</a>
                            </span>
                            <div>
                                <div [ngClass]="{'non-touchable':(col.touchable&&!col.touchable())||(columns[0].globalDisable&&columns[0].globalDisable(row))}"
                                    *ngIf="col.poppable||(col.hybrid&&(cellData(row, col)==='No'))"
                                    class='w-full flex justify-around items-center box-border'>
                                    <span>{{cellData(row, col)}}</span>
                                    <img *ngIf="col.touchable&&col.touchable()" [src]='col.popupicon' alt='popup icon'
                                        class='hover:cursor-pointer' (click)="showPopup($event,i,row,col)" />
                                </div>

                                <span
                                    [ngClass]="{'non-touchable':(col.touchable&&!col.touchable())||(columns[0].globalDisable&&columns[0].globalDisable(row))}"
                                    *ngIf="col.editable||(col.hybrid&&!(cellData(row, col)==='No'))">
                                    <input [disabled]="col.disableRule&&col.disableRule(row)"
                                        [placeholder]='col.placeholder'
                                        class='border border-solid rounded box-border outline-none px-[14px] py-[2px] leading-6 text-[11px] font-Inter font-semibold'
                                        [ngClass]="{'text-[#FF3A3A] border-[#FF3A3A]':(col.attachableValidator&&(col.attachableValidator(row)==='Critical'))||isValidate(row,j),'text-[#D6A850]':col.attachableValidator&&(col.attachableValidator(row)==='Warning'),'text-[#475467] border-[#D0D5DD]':!col.attachableValidator||col.attachableValidator&&(col.attachableValidator(row)==='Standard')}"
                                        [mask]="col.inputmask||''" [leadZeroDateTime]="true" [style]="col.inputstyle"
                                        [suffix]="col.suffix||''" [(ngModel)]="row[col.slug]"
                                        (blur)="handleUpdateInput(i,j,col)"
                                        (keydown.enter)="handleUpdateInput(i,j,col,$event)" />
                                </span>
                                <span *ngIf="col.htmlData" [innerHTML]="cellData(row, col)" [ngClass]="col.conditionalClass ? col.conditionalClass(row) : ''"></span>
                                <span *ngIf="col.icon&&!col.iconUrl" (click)="col.onClick&&col.onClick(row)"
                                    [ngClass]="{'text-align-center':col.alignCenter,'hover:cursor-pointer':col.toggleAction&&col.toggleAction(row)}">
                                    <img src='/assets/icons/timesheet/clock-valid.svg' *ngIf="cellData(row, col)" />
                                    <img src='/assets/icons/timesheet/clock_invalid.svg' *ngIf="!cellData(row, col)" />
                                </span>
                                <span *ngIf="col.iconUrl" [ngClass]="{'text-align-center':col.alignCenter}">
                                    <img [src]='col.iconUrl(row)' style='width: 30px; height: 30px;' />
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
    <div class='loader' *ngIf="loading">
        <mat-spinner [diameter]="50" [strokeWidth]="10"></mat-spinner>
    </div>
</div>
<div class='pagination' *ngIf="activePagination" [class.loading]="loading" [class.blank]="!data.length">
    <div class='total'>Showing {{firstIndex}}{{data.length>1?'-'+(firstIndex+(data.length - 1)):''}} of {{total}}
    </div>
    <button *ngIf="query.page" class='previous pagination_btn_layout'
        [ngClass]="{'text-active':query.page>1,'text-disabled':query.page==1}" (click)="pageChange(query.page-1)">
        <img src='/assets/icons/timesheet/arrow-left.svg' *ngIf="query.page>1" />
        <img src='/assets/icons/timesheet/arrow-left-disabled.svg' *ngIf="query.page==1" />
        <span>Previous</span>
    </button>
    <div class='pages' *ngIf="query.page">
        <div class='page' *ngIf="query.page>3" (click)="pageChange(1)">1</div>
        <div *ngIf="query.page>4" class='text-sm font-medium leading-5 h-8 flex items-center'>...</div>
        <div class='page' *ngIf="query.page>2" (click)="pageChange(query.page-2)">{{query.page-2}}</div>
        <div class='page' *ngIf="query.page>1" (click)="pageChange(query.page-1)">{{query.page-1}}</div>
        <div class='page curr'>{{query.page}}</div>
        <div class='page' *ngIf="maxPage>query.page" (click)="pageChange(query.page+1)">{{query.page+1}}</div>
        <div class='page' *ngIf="maxPage>query.page+1" (click)="pageChange(query.page+2)">{{query.page+2}}</div>
        <div *ngIf="maxPage>query.page+3" class='text-sm font-medium leading-5 h-8 flex items-center'>...</div>
        <div class='page' *ngIf="maxPage>query.page+2" (click)="pageChange(maxPage)">{{maxPage}}</div>
    </div>
    <button *ngIf="query.page" class='next pagination_btn_layout'
        [ngClass]="{'text-active':query.page<maxPage,'text-disabled':query.page==maxPage}"
        (click)="pageChange(query.page+1)">
        <span>Next</span>
        <img src='/assets/icons/timesheet/arrow-right.svg' *ngIf="query.page<maxPage" />
        <img src='/assets/icons/timesheet/arrow-right-disabled.svg' *ngIf="query.page==maxPage" />
    </button>
    <div class='per-page'></div>
</div>