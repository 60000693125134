<div>
    <div class="summaries_drawer" [ngClass]="{
      drawer_appear: isExpanded.drawer,
      drawer_disappear: !isExpanded.drawer
    }">
        <div class="summaries_drawer_content">
            <div class="summaries_drawer_header">
                <div>
                    <span>Filters</span>
                    <span (click)="handleVisivility('drawer', false)">
                        <img src="/assets/icons/x-close.svg" alt="search-icon" /></span>
                </div>
                <span>Select filters to refine data.</span>
            </div>
            <div class="summaries_drawer_body">
                <div class="summaries_locations">
                    <div class="summaries_location_header" (click)="handleVisivility('location')">
                        <ng-container *ngIf="isExpanded.location">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.location">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>Location</span>
                    </div>
                    <div class="summaries_location_content" *ngIf="isExpanded.location">
                        <div *ngFor="
                let location of location_department_data.location;
                let i = index
              ">
                            <mat-checkbox color="primary" [checked]="isChecked(location.isChecked)"
                                (change)="setFilters($event.checked, 'location', location)">
                                {{ location.location }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="summaries_locations">
                    <div class="summaries_location_header" (click)="handleVisivility('department')">
                        <ng-container *ngIf="isExpanded.department">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.department">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>Department</span>
                    </div>
                    <div class="department_search" *ngIf="isExpanded.department">
                        <div class="rounded_input_wrap">
                            <img src="/assets/icons/search.svg" alt="search-icon" />
                            <input class="none_border_outline" [(ngModel)]="departmentSearch"
                                (ngModelChange)="searchDepartment($event)" style="border: none; outline: none"
                                placeholder="Quick search" />
                        </div>
                    </div>
                    <div class="summaries_location_content summaries_departments" *ngIf="isExpanded.department">
                        <div *ngFor="
                let department of filterDepartments(
                  location_department_data.department
                );
                let i = index
              " class="department_loop">
                            <mat-checkbox color="primary" [checked]="isChecked(department.isChecked)"
                                (change)="setFilters($event.checked, 'department', department)">
                                {{ department.client }} - {{ department.depot }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="summaries_drawer_footer">
                <span (click)="handleClearAllChecked()">Clear Filters</span>
                <div>
                    <button class="filter_cancel" (click)="handleVisivility('drawer', false)">
                        Cancel
                    </button>
                    <button class="filter_apply" (click)="handleFilterApply()">
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="header_wrap">
        <div class="end_aligned_children">
            <button class="rounded_blue" [ngClass]="{enabled_color:data.length>0,disabled_color:!(data.length>0)}"
                (click)="exportToExcel()" [disabled]="!(data.length>0)"><span>Export</span></button>
        </div>
        <div class="search_filter_wrap">
            <div class="align_center">
                <div class="rounded_input_wrap">
                    <img src="/assets/icons/search.svg" alt="search-icon" />
                    <input class="none_border_outline" [ngModel]="query.filters!.search"
                        (ngModelChange)="searcherInput($event)" style="border: none; outline: none"
                        placeholder="Search Driver" />
                </div>
                <div class='summaries_filters' (click)="handleVisivility('drawer')">
                    <img src="/assets/icons/filter-lines.svg" alt="filter-lines-icon" />
                    <span>Filters</span>
                </div>
            </div>
            <div class="search_option_wrap">
                <div class="date_range_selection">
                    <div class="date_from_selection">
                        <div class="hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="fromDate"
                                    (ngModelChange)="handleFromDateChange($event)" />
                                <mat-datepicker #picker [ngClass]="'my-theme-primary'"><mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>
                                            Apply
                                        </button>
                                    </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <span class="date_selection_text whitespace_nowrap">From:&nbsp;
                            <span class="date_selection_date">{{
                                getFormattedTime(fromDate)
                                }}</span>
                        </span>
                        <button (click)="picker.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                    <div class="date_from_selection">
                        <div class="hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker1" [(ngModel)]="toDate"
                                    (ngModelChange)="handleToDateChange($event)" />
                                <mat-datepicker #picker1><mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>
                                            Apply
                                        </button>
                                    </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <span class="date_selection_text whitespace_nowrap">To:&nbsp;
                            <span class="date_selection_date">{{
                                getFormattedTime(toDate)
                                }}</span>
                        </span>
                        <button (click)="picker1.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                </div>

                <div class="filtered_option_remove" *ngIf="
            filteredData.location.length > 0 ||
            filteredData.department.length > 0||
            query.filters!.search
          ">
                    <span (click)="handleClearAllFilters()">Clear Filters</span>
                </div>
            </div>
        </div>
    </div>
    <div class="no_summaries" *ngIf="!(data.length > 0)">
        <img src="/assets/icons/Illustration.svg" alt="Illustration-icon" />
        <span>No Data Found</span>
        <div class="no_summaries_button">
            <button class="no_summaries_try_again" (click)="fetchData()">Try again</button>
            <button class="no_summaries_clear_search" (click)="clearSearch()">
                Clear Search
            </button>
        </div>
    </div>

    <div class='table_wraper' *ngIf="data.length > 0">
        <c-table [columns]="columnDef" [data]="data" [loading]="loading" [total]="filtered" [query]="query"
            [sortedColumn]="sortedColumn" (changed)="fetchData()" (sorted)="handleSort()"></c-table>
    </div>

</div>
<div class='summaries_background' *ngIf="loading">
    <div class='summaries_wrap'>
        <div class='summaries_loader'>
            <img src="/assets/icons/loading.png" alt="laoding-icon" />
        </div>
        <span>Loading</span>
    </div>
</div>