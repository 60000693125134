export interface MappingDataType {
  id: number;
  name: string;
  email?: string;
  inventory_code?: string;
  departments?: number[];
  vehicles?: number[];
  loading_rates?: LoadingRatesType[];
}

export interface CheckedStatusType {
  checked: boolean;
}

export interface LDRType {
  id: number;
  location_id: number;
  department_id: number;
  vehicle_id: number;
}

export interface PayratesType {
  name?: string;
  vehicle_id: number;
  dedi_rate: number;
  fuel_levy: number;
  flexi_delivery: number;
  flexi_pickup: number;
}

export interface FunctionalityItemType {
  functionality: string;
  depth: number;
  permission: string;
  parent: string;
  children: FunctionalityItemType[];
}

export type USER_ROLES =
  | 'Admin'
  | 'Accounting'
  | 'Operations'
  | 'Compliance'
  | 'Command';

export interface PermissionObjType {
  [key: string]: { checked: boolean; parents: string[] };
}

export interface RolesPermissionsType {
  id: number;
  name: string;
  permissions: { id: number; name: string }[];
}

export enum UserRoles {
  Admin = 'Admin',
  Accounting = 'Accounting',
  Operations = 'Operations',
  Compliance = 'Compliance',
  Command = 'Command',
  Guest = 'Guest',
}

export interface LoadingRatesType {
  loading_charge: string;
  percentage: number;
  fixed_rate: number;
}

export const loading_charges = [
  'Regional',
  'Saturday',
  'Sunday',
  'Public Holiday',
  'Tailgate',
  'After Hours',
] as const;
