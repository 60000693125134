import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from 'src/app/core/table/table.component';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { MailEvents } from 'src/app/model/MailEvents';
import { LoaderPartial } from 'src/app/partials/loader/loader.component';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { LoginService } from 'src/app/services/login.service';
import { MailEventsService } from 'src/app/services/mail-events.service';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
})
export class EmailsComponent implements OnInit {
  public emails: MailEvents[] = [];
  public loading: boolean = false;
  public searchCooldown: any;

  public columnDef: TableColumn[] = [
    { label: 'ID', slug: 'id', sortable: false, filterType: 'number' },
    { label: 'Slug', slug: 'event_slug', sortable: false },
    { label: 'Subject', slug: 'subject', sortable: false },
    {
      label: 'Values',
      slug: 'main_values',
      sortable: false,
      accessor: (i: MailEvents) => i.main_values.join(', '),
    },
    {
      label: 'Actions',
      slug: 'actions',
      dataType: 'actions',
      actions: [
        {
          label: 'Edit',
          type: 'route',
          route: (i: MailEvents) => `/emails/${i.id}`,
          condition: () =>
            this.authService.hasPermissions(['full:emails', 'edit:emails']),
        },
        {
          label: 'Delete',
          action: (i: MailEvents) => this.del(i),
          condition: () =>
            this.authService.hasPermissions(['full:emails', 'delete:emails']),
        },
      ],
    },
  ];

  public query: IndexQuery = {
    page: 1,
    perPage: 15,
    filters: {
      search: '',
    },
  };

  public total: number = 0;
  public filtered: number = 0;

  constructor(
    private mailApi: MailEventsService,
    private authService: LoginService,
    private dialog: MatDialog
  ) {
    this.fetchData();
  }

  ngOnInit(): void {}

  public fetchData() {
    this.loading = true;
    this.mailApi.getAll(this.query).subscribe((r) => {
      let res = CodedResponseModel.decode(r);

      this.emails = res.data.map((e: any) => MailEvents.create(e));

      this.filtered = res.filtered;
      this.total = res.total;

      this.loading = false;
    });
  }

  public del(e: MailEvents) {
    let d = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Are you sure you want to delete the mail ${e.event_slug}?`,
      },
    });
    d.afterClosed().subscribe((c) => {
      if (c) {
        this.mailApi.delete(e.id).subscribe(
          (r) => {
            NotificatorPartial.push({
              type: 'info',
              message: `${e.event_slug} has been deleted`,
              timeout: 3000,
            });
            this.fetchData();
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: 'An error has occurred',
              details: err.error.message,
            });
          }
        );
      }
    });
  }

  public searcherInput() {
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.fetchData();
    }, 500);
  }
}
