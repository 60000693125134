import * as moment from 'moment';
import { User, user_interface } from '../User';
import { Shift, shift_interface } from './Shift';

interface ShiftSummaryRow {
  completed: number;
  todo: number;
}
interface ShiftSummaryCategory {
  stops: ShiftSummaryRow;
  connotes: ShiftSummaryRow;
  items: ShiftSummaryRow;
}
interface ShiftSummaryData {
  stops_loaded: number;
  cards_left: number;
  check_address: number;
  rejections: number;
  skipped: number;
  pick_up: number;
}

export interface SortedSummaryObj {
  date: boolean;
  driver: boolean;
  location: boolean;
  department: boolean;
  role: boolean;
  clock_in_time: boolean;
  clock_out_time: boolean;
  stops_loaded: boolean;
  pick_ups: boolean;
  cards_left: boolean;
  check_address: boolean;
  rejections: boolean;
  skipped: boolean;
  attempted_deliveries: boolean;
  regional: boolean;
  road_tolls: boolean;
}

export interface ShiftSummaryResponse {
  id: number;
  user_id: number;
  shift_id: number;

  final: boolean;
  data: ShiftSummaryData;
  regional: string;
  road_tolls: string;
  created_at: string;
  updated_at: string;

  user: user_interface;
  shift: shift_interface;
  shift_photo?: any;
}

export class ShiftSummary {
  public id: number = 0;
  public userId: number = 0;
  public shiftId: number = 0;
  public final: boolean = false;
  public data?: ShiftSummaryData;
  public createdAt: string = '';
  public updatedAt: string = '';
  public department: string = '';
  public location: string = '';
  public role: string = '';
  public formattedTime: string = '';
  public clockIn: string = '';
  public clockOut: string = '';
  public user?: User;
  public shift?: Shift;
  public stopLoaded: number = 0;
  public pickUp: number = 0;
  public cardLeft: number = 0;
  public checkAddress: number = 0;
  public rejections: number = 0;
  public skipped: number = 0;
  public attemptedDeliveries: number = 0;
  public shiftPhoto: string[] = [];
  public regional: string = '';
  public road_tolls: string = '';

  public map(data: ShiftSummaryResponse) {
    this.id = data.id;
    this.userId = data.user_id;
    this.shiftId = data.shift_id;
    this.department = `${data?.shift?.client ?? ''} - ${
      data?.shift?.depot ?? ''
    }`;
    this.location = data?.shift?.location ?? '';
    this.role = data?.shift?.vehicle ?? '';
    this.final = data.final;
    this.data = data.data;
    this.clockIn = moment(new Date(data?.shift?.start_time ?? '')).format(
      'DD/MM/YYYY HH:mm'
    );
    this.clockOut = data?.shift?.end_time
      ? moment(new Date(data?.shift?.end_time ?? '')).format('DD/MM/YYYY HH:mm')
      : '';
    this.createdAt = moment(new Date(data.created_at)).format(
      'DD/MM/YYYY HH:mm'
    );
    this.updatedAt = moment(new Date(data.updated_at)).format(
      'DD/MM/YYYY HH:mm'
    );
    this.stopLoaded = this.data?.stops_loaded ?? 0;
    this.pickUp = this.data?.pick_up ?? 0;
    this.cardLeft = this.data?.cards_left ?? 0;
    this.checkAddress = this.data?.check_address ?? 0;
    this.rejections = this.data?.rejections ?? 0;
    this.skipped = this.data?.skipped ?? 0;
    this.attemptedDeliveries = this.stopLoaded - this.skipped;
    this.regional = data.regional === 'ZZZ' ? 'No' : data.regional;
    this.road_tolls = data.road_tolls === 'ZZZ' ? 'No' : data.road_tolls;
    this.formattedTime = moment
      .utc(new Date(data.created_at))
      .local()
      .format('MM/DD/YYYY hh:mm');
    this.shiftPhoto =
      (data?.shift_photo ?? []).length > 0
        ? JSON.parse(data?.shift_photo).map((item: string) =>
            item.replace('\\', '')
          )
        : [];
    this.user = data.user ? User.mapUser(data.user) : undefined;
    this.shift = data.shift ? Shift.create(data.shift) : undefined;

    return this;
  }
  public static create(data: ShiftSummaryResponse) {
    return new this().map(data);
  }
}
