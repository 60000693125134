import { EpEncryptionService } from '../services/ep-encryption.service';
import { decryptPayload } from '../settings/settings';
export class CodedResponseModel {
  private epEncryptionService: EpEncryptionService = new EpEncryptionService();

  public success: boolean = false;
  public data: any;
  public message: string = '';

  public map(_data: any) {
    this.success = _data.success;
    this.data = _data.data;
    this.message = _data.message;
  }

  public decodeData() {
    if (decryptPayload) {
      return this.epEncryptionService.decrypt(this.data);
    } else {
      return this.data;
    }
  }

  constructor(data?: any) {
    if (data) this.map(data);
  }
  public static decode(data: any) {
    let obj = new CodedResponseModel();
    obj.map(data);
    return obj.decodeData();
  }
}
