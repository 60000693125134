import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CodedResponseModel } from "src/app/model/CodedResponseModel";
import { MessagePost } from "src/app/model/custm/MessagePost";
import { buildParams, IndexQuery } from "src/app/model/IndexQuery";
import { apiUrls } from "src/app/settings/settings";
import { EpEncryptionService } from "../ep-encryption.service";

@Injectable({
    providedIn:'root',
})
export class PostsAPI {
    constructor(
        private http:HttpClient,
        private crypto:EpEncryptionService
    ){}

    public index(query:IndexQuery){
        return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}ap-posts${buildParams(query)}`);
    }

    public get(id:number){
        return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}posts/${id}`);
    }

    public create(post:MessagePost){
        return this.http.post<CodedResponseModel>(`${apiUrls.apiUrl}posts`,
            this.crypto.sendPayload(post.responsify()));
    }

    public update(post:MessagePost){
        return this.http.put<CodedResponseModel>(`${apiUrls.apiUrl}posts/${post.id}`,
            this.crypto.sendPayload(post.responsify()));
    }

    public delete(id:number){
        return this.http.delete<CodedResponseModel>(`${apiUrls.apiUrl}posts/${id}`);
    }
}