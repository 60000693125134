import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { IndexQuery } from 'src/app/model/IndexQuery';
import {
  DepartmentType,
  InterestedDriver,
  JobShiftOffer,
  OfferFormat,
  SortObj,
  Timzone,
} from 'src/app/model/custm/JobShiftOffer';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { FileApi } from 'src/app/services/custm/file.service';
import { JobShiftOfferAPI } from 'src/app/services/custm/job-shift-offer.service';

@Component({
  selector: 'app-job-shift-offer',
  templateUrl: './job-shift-offer.component.html',
  styleUrls: ['./job-shift-offer.component.scss'],
})
export class JobShiftOfferComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown', { static: false }) dropdownRef?: ElementRef;
  @ViewChild('overlay', { static: false }) overlayRef?: ElementRef;
  @ViewChild('editableP') editableP!: ElementRef;
  @ViewChild('fInput') fInput!: ElementRef;

  public offerCreationForm!: FormGroup;
  public modalStatus:
    | 'ASSIGN_CONFIRM'
    | 'ASSIGN_PAST_CONFIRM'
    | 'DELETE_CONFIRM'
    | 'ASSIGN_FAIL_NOTIF'
    | 'DELETED_NOTIF'
    | 'OPEN_CREATION'
    | 'NONE' = 'NONE';

  public offerDate: Date = new Date();
  public minDate: Date = new Date();

  public assignmentStatus: 'ASSIGNED' | 'UNASSIGNED' = 'ASSIGNED';

  public selected: number = -1;
  public selectedOffer: JobShiftOffer = {} as JobShiftOffer;

  public imagePreviewUrl: string = '';
  public attachment: {
    name: string;
    progress: number;
    error: boolean;
    uploadFilePath?: string;
  } = {
    name: '',
    progress: 0,
    error: false,
    uploadFilePath: undefined,
  };

  public hyperLinkPopup: boolean = false;
  public hyperLink: { url: string; label: string } = { url: '', label: '' };
  public job_comments_HTML: any = '';
  public job_comments_TEXT: string = '';
  public _job_comments_HTML: any = '';
  public selection!: Selection;

  public assigneeDetail!: OfferFormat & { assignee: InterestedDriver };
  public isKeepOriginal = false;
  public isUpdateStart = false;
  public container: HTMLElement | null = null;

  public multiSelectValid: boolean = true;

  public sortObj: SortObj = {
    date: true,
    start_time: true,
    state: true,
    department: true,
    vehicles: true,
    assignedTo: true,
    assignTo: true,
  };

  public timezones: Timzone[] = [
    { state: 'Head Office', timezone: 'Australia/Melbourne' },
    { state: 'NSW', timezone: 'Australia/NSW' },
    { state: 'QLD', timezone: 'Australia/Queensland' },
    { state: 'SA', timezone: 'Australia/Adelaide' },
    { state: 'TAS', timezone: 'Australia/Tasmania' },
    { state: 'VIC', timezone: 'Australia/Victoria' },
    { state: 'WA', timezone: 'Australia/West' },
  ];
  public selTimezone: string = 'Australia/Melbourne';

  public sortOrder: (keyof SortObj)[] = [
    'assignedTo',
    'vehicles',
    'department',
    'state',
    'start_time',
    'date',
  ];

  public sortStringObj = (assignmentStatus: string) => {
    return {
      assignTo: 'LENGTH(interested_drivers)',
      assignedTo:
        '(SELECT CONCAT(COALESCE(name,""), " ", COALESCE(lname,"")) FROM users WHERE users.id = job_shift_offers.assignee_id LIMIT 1)',
      vehicles:
        assignmentStatus === 'ASSIGNED'
          ? 'allocated_vehicle'
          : 'SUBSTRING_INDEX(vehicles, ",", 1)',
      department: 'department',
      state: 'state',
      start_time: 'start_time',
      date: 'date',
    };
  };

  public failedNotifMsg: string = 'This driver already has a job at this time.';

  public originData: DepartmentType[] = [];

  public isRefreshWholeData = false;

  private autoRefresher: any;

  public loading: boolean = false;
  public data: JobShiftOffer[] = [];
  public searchModel: string = '';
  public isExpanded = {
    state: false,
    department: false,
    vehicle: false,
    drawer: false,
  };

  public selectedTime: string;

  public toExport: boolean = false;

  public range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  public searchCooldown: any;

  public query: IndexQuery = {
    page: 1,
    perPage: 20,
    sortBy: 'created_at',
    sortDir: 'desc',
    filters: {
      search: '',
      tz: String(new Date().getTimezoneOffset()),
    },
  };

  public hasMore: boolean = true;
  // Add a flag to indicate if data is currently being loaded
  private loadingData: boolean = false;
  public total: number = 0;
  public filtered: number = 0;
  public departmentSearch: string = '';
  public vehicleSearch: string = '';

  public state_department_data: {
    state: DepartmentType[];
    department: DepartmentType[];
    vehicle: DepartmentType[];
  } = {
    state: [],
    department: [],
    vehicle: [],
  };

  filteredData: {
    state: { label: string; id: string }[];
    department: { label: string; id: string }[];
    vehicle: { label: string; id: string }[];
  } = {
    state: [],
    department: [],
    vehicle: [],
  };

  public previousDates: {
    fromDate: Date;
    toDate: Date;
  } = {
    fromDate: new Date(),
    toDate: new Date(),
  };

  public fromDate: Date = new Date();
  public toDate: Date = new Date();

  public states$: Observable<string[]>;
  public departments$!: Observable<string[]>;
  public vehicles$!: Observable<string[]>;
  selectedVehicles: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private jobShiftOfferApi: JobShiftOfferAPI,
    private fileApi: FileApi,
    private cdref: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    this.states$ = jobShiftOfferApi
      .getStates()
      .pipe(map((res) => (res?.data || []) as string[]));

    this.minDate.setHours(0);
    this.minDate.setMinutes(0);
    this.minDate.setSeconds(0);

    const currentTime = new Date();
    this.selectedTime = this.getFormattedClocktime(currentTime);
  }

  ngOnInit(): void {
    this.initializeForm();
    this.offerCreationForm.get('state')?.valueChanges.subscribe((value) => {
      this.offerCreationForm.get('department')?.setValue('');
      // this.offerCreationForm.get('vehicle')?.setValue('');
      this.selectedVehicles = [];
      this.departments$ = this.jobShiftOfferApi
        .getDepartments(value)
        .pipe(map((res) => (res?.data || []) as string[]));
    });

    this.offerCreationForm
      .get('department')
      ?.valueChanges.subscribe((value) => {
        // this.offerCreationForm.get('vehicle')?.setValue('');

        this.selectedVehicles = [];

        // this.jobShiftOfferApi.getVehicles(this.offerCreationForm.get('state')?.value, value).subscribe((res) => {
        //   this.vehicles = (res?.data || []) as string[];
        // });
        this.vehicles$ = this.jobShiftOfferApi
          .getVehicles(this.offerCreationForm.get('state')?.value, value)
          .pipe(map((res) => (res?.data || []) as string[]));
      });
    // Get states and departments data.
    this.jobShiftOfferApi.getUsedDepartments().subscribe((res: any) => {
      const { data } = res;
      // this.originData = data?.statesWithVehicles ?? [];
      const state = _.groupBy(data?.statesWithDepartments, 'state');
      // const vehicles = _.groupBy(data?.statesWithVehicles, 'vehicle');

      const flattenedData = _.flatMap(data?.statesWithVehicles, (entry) => {
        return entry.vehicles.map((vehicle: string) => ({
          state: entry.state,
          department: entry.department,
          vehicle,
        }));
      });

      const filteredData = _.uniqWith(flattenedData, _.isEqual);

      this.originData = filteredData;
      const vehicles = _.groupBy(filteredData, 'vehicle');

      const department = data?.statesWithDepartments as DepartmentType[];
      // Stores states and departments to state_department_data variable.
      this.state_department_data.state = Object.keys(state).map(
        (item) =>
          ({
            state: item,
            isChecked: false,
            isVisible: true,
          } as DepartmentType)
      );
      this.state_department_data.vehicle = Object.keys(vehicles).map(
        (item) =>
          ({
            vehicle: item,
            isChecked: false,
            isVisible: true,
          } as DepartmentType)
      );
      this.state_department_data.department = department;
      // Update screen after store data to state_department_data.
      this.cdref.detectChanges();
    });

    this.fetchData();

    this.autoRefresher = setInterval(() => {
      const startDate = new Date(this.fromDate);
      const endDate = new Date(this.toDate);
      const currentTime = new Date();

      startDate.setHours(0);
      startDate.setMinutes(0);
      endDate.setHours(23);
      endDate.setMinutes(59);

      const dayDiff = this.calcDayDiffs(startDate, endDate);
      if (dayDiff < 2 && endDate.getTime() > currentTime.getTime()) {
        this.isRefreshWholeData = true;
        this.fetchData(true);
      }
    }, 10000);
  }

  ngOnDestroy(): void {
    clearInterval(this.autoRefresher);
  }

  calcDayDiffs(date1: Date, date2: Date) {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  }

  initializeForm() {
    this.offerCreationForm = this.formBuilder.group(
      {
        date: [this.getFormattedTime(this.offerDate), [Validators.required]],
        start_time: [this.selectedTime, [Validators.required]],
        state: ['', [Validators.required]],
        department: ['', [Validators.required]],
        charging_comments: '',
      },
      { validator: this.validateCreationForm.bind(this) }
    );
  }

  public validateCreationForm(
    formGroup: AbstractControl
  ): ValidationErrors | null {
    const date = formGroup.get('date')?.value ?? '';
    const startTime = formGroup.get('start_time')?.value ?? '';
    const state = formGroup.get('state')?.value ?? '';
    if (state) {
      const curTime = moment().tz(this.selTimezone).unix();
      const offerTime = moment
        .tz(`${date} ${startTime}`, 'DD/MM/YYYY h:mm A', this.selTimezone)
        .unix();
      if (offerTime >= curTime - 3 * 60) return null;
      else return { pastTime: true };
    } else {
      const currentTime = new Date().getTime();
      const offerTimeString = moment(
        `${date} ${startTime}`,
        'DD/MM/YYYY h:mm A'
      ).format('YYYY-MM-DD HH:mm:ss');

      const offerTime = new Date(offerTimeString).getTime();

      if (offerTime >= currentTime - 60 * 1000 * 3) return null;
      else
        return {
          pastTime: true,
        };
    }
  }

  public handleSelectedState(event: MatAutocompleteSelectedEvent) {
    const state = event.option.value;
    const { start_time, date } = this.offerCreationForm.value;
    this.selTimezone =
      this.timezones.find((item) => item.state === state)?.timezone ??
      'Australia/Melbourne';
    const stateCurTime = moment().tz(this.selTimezone);
    const selStateTimestamp = moment
      .tz(`${date} ${start_time}`, 'DD/MM/YYYY h:mm A', this.selTimezone)
      .unix();
    if (selStateTimestamp < stateCurTime.unix()) {
      const new_start_time = stateCurTime.format('h:mm A');
      this.offerCreationForm
        .get('start_time')
        ?.setValue(stateCurTime.format('h:mm A'));
      this.offerCreationForm
        .get('date')
        ?.setValue(stateCurTime.format('DD/MM/YYYY'));
      this.selectedTime = new_start_time;
      // Manually trigger change detection
      this.cdref.detectChanges();
    }
  }

  public getFormattedClocktime(time: Date) {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const isAfternoon = hours >= 12;
    return `${
      isAfternoon ? (hours === 12 ? 12 : hours - 12) : hours
    }:${minutes} ${isAfternoon ? 'PM' : 'AM'}`;
  }

  public fetchData(isRefresh = false, infinite = false) {
    // Make it loading status
    if (!isRefresh) this.loading = true;

    // Set the start date buy 0 o'clock and end date to 23:59 of the dates.
    this.fromDate.setHours(0);
    this.fromDate.setMinutes(0);
    this.toDate.setHours(23);
    this.toDate.setMinutes(59);

    // Convert dates to utc format
    const utcFromDate = moment(this.fromDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const utcToDate = moment(this.toDate).utc().format('YYYY-MM-DD HH:mm:ss');

    // Get POD data by passing search keyword and filter options

    const orderArr = this.sortOrder.map(
      (item) =>
        `${this.sortStringObj(this.assignmentStatus)[item]} ${
          this.sortObj[item] ? 'ASC' : 'DESC'
        }`
    );

    this.jobShiftOfferApi
      .getAdminOfferdata(
        this.query,
        String(utcFromDate),
        String(utcToDate),
        this.filteredData.state.map((item) => item.label),
        this.filteredData.department.map((item) => item.label),
        this.filteredData.vehicle.map((item) => item.label),
        this.assignmentStatus === 'ASSIGNED',
        orderArr,
        this.isRefreshWholeData
      )
      .subscribe((r) => {
        let res = CodedResponseModel.decode(r);

        // Initialize table data
        if (this.isRefreshWholeData) this.data = [];
        this.data = [
          ...this.data,
          ...res.data.map((s: any) => JobShiftOffer.create(s)),
        ];
        this.filtered = res.filtered;
        this.total = res.total;
        //check has more data
        if (infinite) {
          this.loadingData = false;
          if (
            this.query.perPage &&
            this.query.page &&
            (this.data.length === 0 ||
              this.data.length < this.query.perPage * this.query.page)
          )
            this.hasMore = false;
        }
        // Stop data loading
        this.loading = false;
      });
  }

  public handleStartTimeChange(e: any) {
    this.offerCreationForm.get('start_time')?.setValue(e);
  }

  // Remove inputs in search driver field
  public clearSearch() {
    this.query.filters!.search = '';
    this.reset();
    this.fetchData();
  }

  // Define action for fromdate change
  public handleOfferDateChange(e: string) {
    const offerDate = moment(e).format('DD/MM/YYYY');
    this.offerCreationForm.get('date')?.setValue(offerDate);
  }

  // Define action for fromdate change
  public handleFromDateChange(e: string) {
    const fromDate = new Date(e);
    const toDate = new Date(this.toDate);

    // If the user select past date of todate for fromdate, it will automatically set the same date for todate with fromdate
    if (fromDate.getTime() > toDate.getTime()) this.toDate = fromDate;
    this.reset();
    this.fetchData();
  }

  // Define action for fromdate change
  public handleToDateChange(e: string) {
    const fromDate = new Date(this.fromDate);
    const toDate = new Date(e);

    // If the user select previous date of fromdate for todate, it will automatically set the same date for fromdate with todate
    if (fromDate.getTime() > toDate.getTime()) this.fromDate = toDate;
    this.reset();
    this.fetchData();
  }

  // Returns formated time with DD/MM/YY format
  getFormattedTime(value: Date) {
    return moment(value).format('DD/MM/YY');
  }

  public handleVisivility(
    key: 'state' | 'department' | 'vehicle' | 'drawer',
    value: boolean | null = null
  ) {
    // If the user close filter window without apply, it will ignore what the user newly checked.
    if (key === 'drawer' && value === false) {
      this.state_department_data.state.forEach((item, itemIndex) => {
        this.state_department_data.state[itemIndex].isChecked =
          this.filteredData.state.map((item) => item.id).includes(item.state);
      });
      this.state_department_data.vehicle.forEach((item, itemIndex) => {
        this.state_department_data.vehicle[itemIndex].isChecked =
          this.filteredData.vehicle
            .map((item) => item.id)
            .includes(item.vehicle);
      });
      this.state_department_data.department.forEach((item, itemIndex) => {
        this.state_department_data.department[itemIndex].isChecked =
          this.filteredData.department
            .map((item) => item.id)
            .includes(`${item.state}-${item.department}`);
      });
    }

    // It does expand action for state, department in filter and filter modal.
    if (value !== null) {
      this.isExpanded[key] = value;
      return;
    }
    this.isExpanded[key] = !this.isExpanded[key];
  }

  // This function define actions when user click apply button in filter modal.
  public handleFilterApply() {
    this.isExpanded.drawer = false;
    this.filteredData = { state: [], department: [], vehicle: [] };
    const filteredstates: string[] = [];

    // Stored filtered states data.
    this.state_department_data.state.forEach((item) => {
      if (item.isChecked) {
        filteredstates.push(item.state);
        this.filteredData.state.push({
          label: item.state,
          id: item.state,
        });
      }
    });

    // If the filtered states data is not empty, it will also fiter departments data which are related to filtered states.
    if (filteredstates.length)
      this.state_department_data.department =
        this.state_department_data.department.map((item) => ({
          ...item,
          isChecked: filteredstates.includes(item.state)
            ? item.isChecked
            : false,
        }));

    // Store checked departments data.

    const filteredDepartmentVehicles: string[] = [];

    this.state_department_data.department.forEach((item) => {
      if (item.isChecked) {
        filteredDepartmentVehicles.push(item.vehicle);
        this.filteredData.department.push({
          label: `${item.department}`,
          id: `${item.state}-${item.department}`,
        });
      }
    });

    const checkedDepartmentsLength =
      this.state_department_data.department.filter(
        (item) => item.isChecked
      ).length;

    const checkedDepartments = this.state_department_data.department
      .filter((item) =>
        filteredstates.length > 0
          ? checkedDepartmentsLength > 0
            ? item.isChecked && filteredstates.includes(item.state)
            : filteredstates.includes(item.state)
          : item.isChecked
      )
      .map((item) => `${item.state}-${item.department}`);

    const relatedVehicles = this.originData
      .filter((item) =>
        checkedDepartments.includes(`${item.state}-${item.department}`)
      )
      .map((item) => item.vehicle);

    if (relatedVehicles.length)
      this.state_department_data.vehicle =
        this.state_department_data.vehicle.map((item) => ({
          ...item,
          isChecked: relatedVehicles.includes(item.vehicle)
            ? item.isChecked
            : false,
        }));

    this.state_department_data.vehicle.forEach((item) => {
      if (item.isChecked) {
        this.filteredData.vehicle.push({
          label: item.vehicle,
          id: item.vehicle,
        });
      }
    });

    this.reset();
    this.fetchData();
  }

  // Check data and returns its boolean value
  public isChecked(value: boolean | undefined | null) {
    return Boolean(value);
  }

  // Difine actions for user inputs in Quick serach in filter modal.
  public searchFilterOption(
    value: string,
    keyValue: 'state' | 'department' | 'vehicle'
  ) {
    this.state_department_data[keyValue].forEach((item, itemIndex) => {
      if (`${item[keyValue]}`.toLowerCase().includes(value.toLowerCase())) {
        this.state_department_data[keyValue][itemIndex].isVisible = true;
      } else {
        this.state_department_data[keyValue][itemIndex].isVisible = false;
      }
    });
  }

  // Returns departments data by filtering based on selected states and search keyword.
  public filterDepartments(arr: DepartmentType[]) {
    const checkedstates = this.state_department_data.state.filter(
      (item) => item.isChecked
    );

    if (checkedstates.length)
      return arr.filter(
        (item) =>
          (item?.isVisible === undefined || item?.isVisible === true) &&
          checkedstates.map((subItem) => subItem.state).includes(item.state)
      );
    else
      return arr.filter(
        (item) => item?.isVisible === undefined || item?.isVisible === true
      );
  }

  public filterVehicles(arr: DepartmentType[]) {
    const checkedstates = this.state_department_data.state
      .filter((item) => item.isChecked)
      .map((item) => item.state);

    const checkedDepartmentsLength =
      this.state_department_data.department.filter(
        (item) => item.isChecked
      ).length;

    const checkedDepartments = this.state_department_data.department
      .filter((item) =>
        checkedstates.length > 0
          ? checkedDepartmentsLength > 0
            ? item.isChecked && checkedstates.includes(item.state)
            : checkedstates.includes(item.state)
          : item.isChecked
      )
      .map((item) => `${item.state}-${item.department}`);

    const relatedVehicles = this.originData
      .filter((item) =>
        checkedDepartments.includes(`${item.state}-${item.department}`)
      )
      .map((item) => item.vehicle);

    if (relatedVehicles.length)
      return arr.filter(
        (item) =>
          (item?.isVisible === undefined || item?.isVisible === true) &&
          relatedVehicles.includes(item.vehicle)
      );
    else
      return arr.filter(
        (item) => item?.isVisible === undefined || item?.isVisible === true
      );
  }

  // Define action for user inputs in Search Driver field.
  public searcherInput(value: string) {
    this.query.filters!.search = value;

    // It fetech data after 0.5 seconds when user input stops
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.reset();
      this.fetchData();
    }, 500);
  }

  // Remove all filters including search keyword in Search Driver field.
  public handleClearAllFilters() {
    this.filteredData = { state: [], department: [], vehicle: [] };
    this.query.filters!.search = '';
    this.state_department_data.state = this.state_department_data.state.map(
      (item) => ({
        ...item,
        isChecked: false,
      })
    );
    this.state_department_data.department =
      this.state_department_data.department.map((item) => ({
        ...item,
        isChecked: false,
      }));
    this.state_department_data.vehicle = this.state_department_data.vehicle.map(
      (item) => ({
        ...item,
        isChecked: false,
      })
    );
    this.fromDate = new Date();
    this.toDate = new Date();
    this.reset();
    this.fetchData();
  }

  // Clear all checks from filter modal
  public handleClearAllChecked() {
    this.state_department_data.state = this.state_department_data.state.map(
      (item) => ({
        ...item,
        isChecked: false,
      })
    );
    this.state_department_data.department =
      this.state_department_data.department.map((item) => ({
        ...item,
        isChecked: false,
      }));
    this.state_department_data.vehicle = this.state_department_data.vehicle.map(
      (item) => ({
        ...item,
        isChecked: false,
      })
    );
  }

  // Set isChecked value based on user control in filter modal for state and department checkboxes.
  public setFilters(
    checked: boolean,
    key: 'state' | 'department' | 'vehicle',
    value: DepartmentType
  ) {
    const selectedIndex =
      key === 'state' || key === 'vehicle'
        ? this.state_department_data[key].findIndex(
            (item) => item[key] === value[key]
          )
        : this.state_department_data.department.findIndex(
            (item) =>
              item.state === value.state && item.department === value.department
          );
    this.state_department_data[key][selectedIndex].isChecked = checked;
  }

  // TODO: for creating job shift offer from here
  public store() {
    if (this.job_comments_TEXT.length > 600)
      return NotificatorPartial.push({
        type: 'error',
        message: 'Job Comments cannot be over 600 characters.',
        timeout: 3000,
      });

    if (this.selectedVehicles.length < 1) {
      this.multiSelectValid = false;
      this.cdref.detectChanges();
      return;
    }

    if (this.offerCreationForm.valid) {
      const creationData = this.offerCreationForm.value;
      const offerDate = moment
        .tz(
          `${creationData.date} 12:00 AM`,
          'DD/MM/YYYY h:mm A',
          this.selTimezone
        )
        .format('YYYY-MM-DD HH:mm:ss');
      const offerStartTime = moment
        .tz(
          `${creationData.date} ${creationData.start_time}`,
          'DD/MM/YYYY h:mm A',
          this.selTimezone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');

      this.loading = true;
      this.jobShiftOfferApi
        .store({
          ...creationData,
          vehicles: this.selectedVehicles,
          ...(this.attachment.uploadFilePath
            ? { attachment: this.attachment.uploadFilePath }
            : {}),
          ...(this.job_comments_TEXT
            ? { job_comments: this._job_comments_HTML }
            : {}),
          date: offerDate,
          start_time: offerStartTime,
          start_time_anywhere: moment(
            `${creationData.date} ${creationData.start_time}`,
            'DD/MM/YYYY h:mm A'
          ).format('YYYY-MM-DD HH:mm:ss'),
        })
        .subscribe(
          (response) => {
            this.loading = false;
            NotificatorPartial.push({
              type: 'success',
              message: 'Shift offer has been created successfully.',
              timeout: 3000,
            });
            this.closeModal();
            this.isRefreshWholeData = true;
            this.fetchData();
          },
          (err) => {
            this.loading = false;
            console.log(err);
            NotificatorPartial.push({
              type: 'error',
              message:
                err?.error?.message ??
                'Shift offer creation failed. Please try again later.',
              timeout: 3000,
            });
          }
        );
    }
  }

  public handleCreate() {
    const currentTime = new Date();
    this.selectedTime = this.getFormattedClocktime(currentTime);
    this.offerCreationForm
      .get('date')
      ?.setValue(this.getFormattedTime(currentTime));
    this.offerCreationForm.get('start_time')?.setValue(this.selectedTime);
    this.offerCreationForm.get('state')?.setValue('');
    this.offerCreationForm.get('department')?.setValue('');
    this.offerCreationForm.get('vehicle')?.setValue('');
    this.job_comments_HTML = '';
    this._job_comments_HTML = '';
    this.job_comments_TEXT = '';
    this.offerCreationForm.get('charging_comments')?.setValue('');
    this.modalStatus = 'OPEN_CREATION';
  }

  public closeModal() {
    this.handleClearAttachment();
    this.modalStatus = 'NONE';
  }

  public handleAssignmentStatus(value: 'ASSIGNED' | 'UNASSIGNED') {
    if (this.assignmentStatus === value) return;
    const index = this.sortOrder.findIndex(
      (item) => item === 'assignTo' || item === 'assignedTo'
    );
    this.sortOrder[index] = value === 'ASSIGNED' ? 'assignedTo' : 'assignTo';

    const currentFromDateTime = new Date(this.fromDate).getTime();
    const currentToDateTime = new Date(this.toDate).getTime();
    const currentTime = new Date().getTime();

    if (value === 'UNASSIGNED') {
      this.previousDates.fromDate = new Date(this.fromDate);
      this.previousDates.toDate = new Date(this.toDate);
      if (currentFromDateTime < currentTime) {
        this.fromDate = new Date();
      }
      if (currentToDateTime < currentTime) {
        this.toDate = new Date();
      }
    } else {
      this.fromDate = new Date(this.previousDates.fromDate);
      this.toDate = new Date(this.previousDates.toDate);
    }

    this.assignmentStatus = value;
    this.reset();
    this.fetchData();
  }

  public handleClickDropdown(
    e: MouseEvent,
    value: JobShiftOffer | 'none',
    count: number
  ) {
    if (this.dropdownRef && this.overlayRef) {
      const dropdownElement = this.dropdownRef.nativeElement;
      const overlayElement = this.overlayRef.nativeElement;

      if (value === 'none') {
        this.selectedOffer = {} as JobShiftOffer;
        dropdownElement.style.display = 'none';
        overlayElement.style.display = 'none';
        return;
      }

      this.selectedOffer = value;
      dropdownElement.style.display = count < 1 ? 'none' : 'block';
      overlayElement.style.display = count < 1 ? 'none' : 'block';

      const clickedElement = e.currentTarget as HTMLElement;
      const clickedElementRect = clickedElement.getBoundingClientRect();
      const wrapperElement = document.querySelector('#scroll-container');
      const wrapperElementBottom =
        wrapperElement?.getBoundingClientRect().bottom ?? 0;

      dropdownElement.style.left = `${clickedElementRect.left}px`;

      const availableSpaceBelow =
        wrapperElementBottom - clickedElementRect.bottom;

      const dropdownHeight = (count ?? 0) * 44 > 250 ? 250 : (count ?? 0) * 44;

      if (availableSpaceBelow >= dropdownHeight) {
        dropdownElement.style.top = `${clickedElementRect.top + 18}px`;
      } else {
        dropdownElement.style.top = `${
          clickedElementRect.top - dropdownHeight
        }px`;
      }
    }
  }
  public handleSelectAssignee(assignee: InterestedDriver) {
    if (this.dropdownRef && this.overlayRef) {
      const dropdownElement = this.dropdownRef.nativeElement;
      const overlayElement = this.overlayRef.nativeElement;

      dropdownElement.style.display = 'none';
      overlayElement.style.display = 'none';
    }

    this.assigneeDetail = {
      ...this.selectedOffer,
      assignee,
    };

    this.selected = -1;

    this.modalStatus = 'ASSIGN_CONFIRM';
  }

  public keepOriginal() {
    this.isKeepOriginal = true;
    this.handleAssignConfirm();
  }

  public updateStart() {
    this.isUpdateStart = true;
    this.handleAssignConfirm();
  }

  public handleDeleteOffer(offer: OfferFormat) {
    this.selected = -1;

    this.assigneeDetail = {
      ...this.assigneeDetail,
      ...offer,
    };
    this.modalStatus = 'DELETE_CONFIRM';
  }

  public handleDeleteConfirm() {
    this.loading = true;
    this.jobShiftOfferApi.deleteOffer(this.assigneeDetail.id).subscribe(
      (response) => {
        this.loading = false;
        this.modalStatus = 'DELETED_NOTIF';
        this.isRefreshWholeData = true;
        this.fetchData();
      },
      (err) => {
        this.loading = false;
        console.log(err);
        NotificatorPartial.push({
          type: 'error',
          message:
            err?.error?.message ??
            "Shift offer couldn't be deleted. Please try again later.",
          timeout: 3000,
        });
      }
    );
  }

  public handleAssignConfirm() {
    if (!this.isKeepOriginal && !this.isUpdateStart) {
      const stateTimezone =
        this.timezones.find((item) => item.state === this.assigneeDetail.state)
          ?.timezone ?? 'Australia/Melbourne';
      const offerStart = moment
        .tz(
          this.assigneeDetail.start_time_anywhere,
          'YYYY-MM-DD HH:mm:ss',
          stateTimezone
        )
        .unix();
      const currentTime = moment().tz(stateTimezone).unix();

      if (offerStart < currentTime - 60 * 3) {
        this.modalStatus = 'ASSIGN_PAST_CONFIRM';
        return;
      }
    }
    let start_time_anywhere = null;
    let start_time = null;
    if (this.isUpdateStart) {
      const stateTimezone =
        this.timezones.find((item) => item.state === this.assigneeDetail.state)
          ?.timezone ?? 'Australia/Melbourne';
      start_time_anywhere = moment()
        .tz(stateTimezone)
        .format('YYYY-MM-DD HH:mm');
      start_time = moment
        .tz(start_time_anywhere, 'YYYY-MM-DD HH:mm', stateTimezone)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
    }
    this.loading = true;
    this.jobShiftOfferApi
      .assignOffer({
        offer_id: this.assigneeDetail.id,
        assignee: this.assigneeDetail.assignee,
        isUpdateStart: this.isUpdateStart,
        start_time_anywhere,
        start_time,
      })
      .subscribe(
        (response) => {
          this.loading = false;
          NotificatorPartial.push({
            type: 'success',
            message: 'Shift offer has been assigned successfully.',
            timeout: 3000,
          });

          this.isRefreshWholeData = true;
          this.fetchData();
          this.isKeepOriginal = false;
          this.isUpdateStart = false;
          this.closeModal();
        },
        (err) => {
          this.loading = false;
          this.isKeepOriginal = false;
          this.isUpdateStart = false;
          console.log(err);
          this.failedNotifMsg =
            err?.error?.message ??
            'This driver already has a job at this time.';
          this.modalStatus = 'ASSIGN_FAIL_NOTIF';
        }
      );
  }

  public handleSort(value: keyof SortObj) {
    this.sortObj[value] = !this.sortObj[value];
    const isForAssign = value === 'assignTo' || value === 'assignedTo';

    const index = this.sortOrder.findIndex((item) =>
      isForAssign
        ? item === 'assignTo' || item === 'assignedTo'
        : item === value
    );
    this.sortOrder = [
      ...this.sortOrder.slice(0, index),
      ...this.sortOrder.slice(index + 1),
    ];
    this.sortOrder.push(value);
    this.reset();
    this.fetchData();
  }

  public onScroll(event: Event): void {
    this.container = event.target as HTMLElement;
    const tolerance = 1; // Set a tolerance value based on your requirements
    const isScrolledToBottom =
      this.container.scrollHeight - this.container.scrollTop <=
      this.container.clientHeight + tolerance;
    if (isScrolledToBottom && this.hasMore && !this.loadingData) {
      this.loadingData = true; // Set the loading flag to prevent multiple calls
      this.loadData(); // Load more data
    }
  }

  public loadData() {
    this.isRefreshWholeData = false;
    this.query.page = (this.query?.page ?? 0) + 1;
    this.fetchData(false, true);
  }

  public reset() {
    this.query.page = 1;
    this.isRefreshWholeData = true;
    if (this.container) {
      this.container.scrollTop = 0;
    }
    this.hasMore = true;
  }

  public replaceEmplyee(text: string, replaceText: string) {
    return text.replace(/employee/gi, replaceText);
  }

  public onFileSelected(e: any) {
    const file: File = e.target.files[0];
    this.attachment.name = file.name;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imagePreviewUrl = e.target.result;
      this.fileApi.upload(this.imagePreviewUrl, 'photo').subscribe(
        (r) => {
          switch (r.type) {
            case HttpEventType.UploadProgress:
              this.attachment.progress = Math.floor(
                (r.loaded / (r.total as number)) * 99
              );
              break;
            case HttpEventType.Response:
              let res = CodedResponseModel.decode(r.body);
              this.attachment.uploadFilePath = res.path;
              this.attachment.progress = 100;
              break;
          }
        },
        (err) => {
          this.attachment.error = true;
        }
      );
    };

    reader.readAsDataURL(file);
  }
  public handleClearAttachment() {
    if (this?.fInput?.nativeElement) this.fInput.nativeElement.value = null;
    this.imagePreviewUrl = '';
    this.attachment = {
      name: '',
      progress: 0,
      error: false,
    };
  }

  public openHyperLinkPopup() {
    this.hyperLinkPopup = true;
  }

  public insertHyperLink() {
    if (!this.isValidUrl(this.hyperLink.url) || !this.hyperLink.label)
      return NotificatorPartial.push({
        type: 'warning',
        message: 'Please enter a valid url and Text',
        timeout: 3000,
      });

    const updated_content = `${this._job_comments_HTML}<a href="${this.hyperLink.url}" rel="noopener noreferrer" target="_blank" contenteditable="false">${this.hyperLink.label}</a>`;

    this.job_comments_HTML =
      this.sanitizer.bypassSecurityTrustHtml(updated_content);

    this.editableP.nativeElement.innerHTML = this.job_comments_HTML;
    this._job_comments_HTML = updated_content;

    const element: HTMLElement = document.createElement('div');
    element.innerHTML = updated_content;
    this.job_comments_TEXT = element.innerText;
    this.cancelPopup();
  }

  public cancelPopup() {
    this.hyperLink = { url: '', label: '' };
    this.hyperLinkPopup = false;
  }
  public isValidUrl(url: string) {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return pattern.test(url);
  }
  public handlePInput(event: any) {
    const element = event.target as HTMLParagraphElement;
    this._job_comments_HTML = element.innerHTML;
    this.job_comments_TEXT = element.innerText;
  }
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const text = clipboardData.getData('text');

    // Insert plain text at the cursor position
    document.execCommand('insertText', false, text);
  }
  public handleSelectedEvent(value: string) {
    this.multiSelectValid = true;
    if (this.selectedVehicles.includes(value)) {
      this.selectedVehicles = [
        ...this.selectedVehicles.filter((fruit) => fruit !== value),
      ];
    } else {
      this.selectedVehicles.push(value);
    }
  }
}
