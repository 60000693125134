import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from 'src/app/core/table/table.component';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { User, UserSortObj, user_interface } from 'src/app/model/User';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { LoginService } from 'src/app/services/login.service';
import { UsersAPIService } from 'src/app/services/users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  public loading: boolean = false;

  public users: User[] = [];
  public sortedColumn: { columnName: string } = {
    columnName: 'id',
  };

  public searchCooldown: any;

  public sortObj: UserSortObj;
  public sortOrder: (keyof UserSortObj)[];
  public sortStringObj = {
    role_name: 'role_name',
    referral_code: 'COALESCE(referral_code,"")',
    route4me_id: 'COALESCE(route4me_id,"")',
    donesafe_id: 'COALESCE(donesafe_id,"")',
    humanforce_id: 'COALESCE(humanforce_id,"")',
    email: 'email',
    phone: 'phone',
    name: 'CONCAT(COALESCE(name,""), " ", COALESCE(lname,""))',
    id: 'id',
  };

  public columnDef: TableColumn[] = [
    {
      label: 'ID',
      slug: 'id',
      keyValue: 'id',
      sortable: true,
      filterType: 'number',
    },
    {
      label: 'Name',
      slug: 'name',
      keyValue: 'name',
      sortable: true,
      accessor: (i: User) => i.fullName,
    },
    { label: 'Phone', slug: 'phone', keyValue: 'phone', sortable: true },
    { label: 'Email', slug: 'email', keyValue: 'email', sortable: true },
    {
      label: 'Humanforce ID',
      slug: 'humanforceId',
      keyValue: 'humanforce_id',
      sortable: true,
    },
    {
      label: 'Donesafe ID',
      slug: 'donesafe_id',
      keyValue: 'donesafe_id',
      sortable: true,
    },
    {
      label: 'Route4me ID',
      slug: 'route4meId',
      keyValue: 'route4me_id',
      sortable: true,
    },
    {
      label: 'Referral Code',
      slug: 'referralCode',
      keyValue: 'referral_code',
      sortable: true,
    },
    {
      label: 'Admin',
      slug: 'isAdmin',
      keyValue: 'role_name',
      sortable: true,
      accessor: (i: User) => i.user_role,
    },
    {
      label: 'Actions',
      slug: 'actions',
      dataType: 'actions',
      actions: [
        {
          label: 'Edit',
          type: 'route',
          route: (i: User) => `/users/${i.id}`,
          condition: () =>
            this.authService.hasPermissions([
              'full:users',
              'edit:users',
              'edit-mapping:users',
              'edit-rates:users',
            ]),
        },
        {
          label: 'Delete',
          action: (i: User) => this.del(i),
          condition: () => this.authService.hasPermissions(['delete:users']),
        },
        {
          label: 'Send Shift Notifs',
          action: (i: User) => this.sendNotifs(i),
          condition: (i: User) =>
            i.hasFCM && this.authService.hasPermissions(['send-notifs:users']),
        },
        {
          label: "Reset Password",
          action: (i: User) => this.resetPassword(i.id),
          condition: (i: User) => this.authService.hasPermissions(['reset-password:users'])
        }
      ],
    },
  ];

  public query: IndexQuery = {
    page: 1,
    perPage: 15,
    filters: {
      search: '',
    },
  };

  public total: number = 0;
  public filtered: number = 0;

  constructor(
    private userApi: UsersAPIService,
    // private echoService: EchoService,
    private dialog: MatDialog,
    private authService: LoginService
  ) {
    let temp = {} as UserSortObj;
    Object.keys(this.sortStringObj).forEach(
      (item) => (temp = { ...temp, [item]: true })
    );
    this.sortObj = temp;
    this.sortOrder = Object.keys(this.sortStringObj) as (keyof UserSortObj)[];
    this.fetchData();
  }

  ngOnInit(): void {
    // this.echoService.echo
    //   .channel('my-channel')
    //   .listen('.tracking-data-event', (data: any) => {
    //   });
  }

  public fetchData() {
    this.loading = true;
    const orderArr = this.sortOrder.map(
      (item) =>
        `${this.sortStringObj[item]} ${this.sortObj[item] ? 'ASC' : 'DESC'}`
    );
    this.userApi.index(this.query, orderArr).subscribe((r) => {
      let res = CodedResponseModel.decode(r);

      this.users = res.data.map((u: user_interface) => User.mapUser(u));

      this.filtered = res.filtered;
      this.total = res.total;

      this.loading = false;
    });
  }

  public del(u: User) {
    let d = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Are you sure you want to delete the user ${u.email}?`,
      },
    });
    d.afterClosed().subscribe((c) => {
      if (c) {
        this.userApi.delete(u.id).subscribe(
          (r) => {
            NotificatorPartial.push({
              type: 'info',
              message: `${u.email} has been deleted`,
              timeout: 3000,
            });
            this.fetchData();
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: 'An error has occurred',
              details: err.error.message,
            });
          }
        );
      }
    });
  }

  public sendNotifs(user: User) {
    this.loading = true;
    this.userApi.sendNotifs(user.id).subscribe(
      (r) => {
        let res = CodedResponseModel.decode(r);

        if (!res.shifts)
          NotificatorPartial.push({
            type: 'info',
            message: 'This user has no shifts scheduled for today',
            timeout: 3000,
          });
        else
          NotificatorPartial.push({
            type: 'success',
            message: `Successfully sent ${res.sent} notifications for ${res.shifts} shifts`,
            timeout: 3000,
          });

        this.loading = false;
      },
      (err) => {
        NotificatorPartial.push({
          type: 'error',
          message:
            'An error has occurred when trying to send shift notifications',
          details: err.error.message,
        });
        this.loading = false;
      }
    );
  }

  public handleSort() {
    this.sortObj[this.sortedColumn.columnName as keyof UserSortObj] =
      !this.sortObj[this.sortedColumn.columnName as keyof UserSortObj];

    const index = this.sortOrder.findIndex(
      (item) => item === this.sortedColumn.columnName
    );
    this.sortOrder = [
      ...this.sortOrder.slice(0, index),
      ...this.sortOrder.slice(index + 1),
    ];
    this.sortOrder.push(this.sortedColumn.columnName as keyof UserSortObj);
    this.query.page = 1;
    this.fetchData();
  }

  public searcherInput() {
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.query.page = 1;
      this.fetchData();
    }, 500);
  }

  public resetPassword(userId: number): void {
    this.userApi.resetMyPassword(userId).subscribe({
      next: (success) => {
        NotificatorPartial.push({
          type: 'success',
          message: 'Password has been successfully changed.',
          timeout: 3000,
        });
      }, 
      error: (error) => {
        NotificatorPartial.push({
          type: 'error',
          message: 'Unable to proceed the request for this user.',
          timeout: 3000,
        });
      }
    });
  }
}
