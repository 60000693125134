import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { MenuComponent } from "../partials/menu/menu.component";

@Injectable({
    providedIn:'root',
})
export class PartialStateService implements CanActivate{
    constructor(){}

    public canActivate(route: ActivatedRouteSnapshot){
        let currRoute = route;
        while(currRoute.firstChild)
            currRoute = currRoute.firstChild;
        
        if(currRoute.data.routeSlug)
            MenuComponent.setActive(currRoute.data.routeSlug);

        return true;
    }
}