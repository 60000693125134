import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnAction } from '../table/table.component';

export interface TableColumnV2 {
  label: string;
  slug: string;

  editable?: boolean;
  inputstyle?: { [key: string]: string };
  inputmask?: string;
  suffix?: string;
  placeholder?: string;
  invoker?: CallableFunction;
  disableRule?: CallableFunction;
  options?: any;
  customClass?: string;

  touchable?: CallableFunction;
  
  accent?: boolean;
  alignCenter?: boolean;
  checked?: boolean;
  icon?: boolean;
  colType?: 'data' | 'actions' | 'checkbox' | 'input' | '_input';
  filterType?: 'text' | 'number' | 'date' | 'list' | 'bool';
  filterList?: any[];
  sortable?: boolean;
  filterable?: boolean;
  onClick?: CallableFunction;
  actions?: ColumnAction[];
  accessor?: CallableFunction;
}
@Component({
  selector: 'c-table-v2',
  templateUrl: './table-v2.component.html',
  styleUrls: ['./table-v2.component.scss'],
})
export class TableV2Component implements OnInit {
  @Input() public data: any[] = [];
  @Input() public columns: TableColumnV2[] = [];
  @Input() public sortedColumn: { columnName: string } = { columnName: '' };

  @Output() public filtered = new EventEmitter<string>();
  @Output() public sorted = new EventEmitter<string>();
  @Output() public changed = new EventEmitter<void>();

  public globalCheck: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public handleSortClick(keyValue: string | undefined) {
    this.sortedColumn.columnName = keyValue ?? '';
    this.sorted.emit();
  }

  public cellData(row: any, column: TableColumnV2) {
    if (column.accessor) return column.accessor(row);
    return row[column.slug];
  }
  public functionableValue(row: any, value: any) {
    if (typeof value === 'function') return value(row);
    else return value;
  }
  public handleGlobalCheck() {
    this.data.forEach((row, rowIndex) => {
      this.data[rowIndex].checked = this.globalCheck;
      return true;
    });
  }
}
