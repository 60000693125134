<div class='w-full h-fit p-1 gap-x-2 border border-solid rounded-lg text-base'
  [ngClass]="{'border-[#D0D5DD]':valid,'border-[#ee4d4d]':!valid}">
  <mat-form-field class='w-full'>
    <mat-chip-list #chipList aria-label="Fruit selection">
      <mat-chip *ngFor="let selected of selectedValues" [selectable]="selectable" [removable]="removable"
        (removed)="remove(selected)">
        {{selected}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input placeholder="" #fruitInput #autocompleteTrigger="matAutocompleteTrigger" [formControl]="fruitCtrl"
        [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" autoActiveFirstOption>
      <mat-option *ngFor="let option of filteredValues | async | searchFilter:fruitCtrl.value" [value]="option">
        <mat-checkbox color="primary" style="padding: 0 12px;"
          [checked]="selectedValues.indexOf(option) >= 0"></mat-checkbox>
        {{option}}
      </mat-option>
    </mat-autocomplete>
    <button type='button' mat-icon-button matSuffix style="width: 34px; height: 34px;"
      (click)="openAuto(autocompleteTrigger)"> <mat-icon style="width: 34px;">expand_more</mat-icon></button>
  </mat-form-field>
</div>